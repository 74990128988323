/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: financial/smartInstrumentReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_smartInstrument_pb = require('../financial/smartInstrument_pb.js')

var ledger_token_pb = require('../ledger/token_pb.js')

var financial_assetClass_pb = require('../financial/assetClass_pb.js')

var financial_smartInstrumentType_pb = require('../financial/smartInstrumentType_pb.js')

var financial_unitCategory_pb = require('../financial/unitCategory_pb.js')

var ledger_amount_pb = require('../ledger/amount_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var i8n_timezone_pb = require('../i8n/timezone_pb.js')

var financial_smartInstrumentLeg_pb = require('../financial/smartInstrumentLeg_pb.js')

var financial_callabilityConfiguration_pb = require('../financial/callabilityConfiguration_pb.js')

var document_document_pb = require('../document/document_pb.js')
const proto = {};
proto.financial = require('./smartInstrumentReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.SmartInstrumentReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.SmartInstrumentReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneSmartInstrumentRequest,
 *   !proto.financial.ReadOneSmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentReader_ReadOneSmartInstrument = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentReader/ReadOneSmartInstrument',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadOneSmartInstrumentRequest,
  proto.financial.ReadOneSmartInstrumentResponse,
  /**
   * @param {!proto.financial.ReadOneSmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadOneSmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneSmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneSmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentReaderClient.prototype.readOneSmartInstrument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentReader/ReadOneSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReader_ReadOneSmartInstrument,
      callback);
};


/**
 * @param {!proto.financial.ReadOneSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneSmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentReaderPromiseClient.prototype.readOneSmartInstrument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentReader/ReadOneSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReader_ReadOneSmartInstrument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManySmartInstrumentRequest,
 *   !proto.financial.ReadManySmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentReader_ReadManySmartInstrument = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentReader/ReadManySmartInstrument',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadManySmartInstrumentRequest,
  proto.financial.ReadManySmartInstrumentResponse,
  /**
   * @param {!proto.financial.ReadManySmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadManySmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManySmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManySmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManySmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentReaderClient.prototype.readManySmartInstrument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentReader/ReadManySmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReader_ReadManySmartInstrument,
      callback);
};


/**
 * @param {!proto.financial.ReadManySmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManySmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentReaderPromiseClient.prototype.readManySmartInstrument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentReader/ReadManySmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReader_ReadManySmartInstrument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllSmartInstrumentRequest,
 *   !proto.financial.ReadAllSmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentReader_ReadAllSmartInstrument = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentReader/ReadAllSmartInstrument',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadAllSmartInstrumentRequest,
  proto.financial.ReadAllSmartInstrumentResponse,
  /**
   * @param {!proto.financial.ReadAllSmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadAllSmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllSmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllSmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentReaderClient.prototype.readAllSmartInstrument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentReader/ReadAllSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReader_ReadAllSmartInstrument,
      callback);
};


/**
 * @param {!proto.financial.ReadAllSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllSmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentReaderPromiseClient.prototype.readAllSmartInstrument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentReader/ReadAllSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentReader_ReadAllSmartInstrument);
};


module.exports = proto.financial;

