import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { Amount } from "james/ledger/Amount";
import { Identifier } from "james/search/identifier/Identifier";
import {
  Client,
  clientRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequestWithMiddleware";

export type SetLimitRequest = {
  context: Context;
  accountID: string;
  limit: Amount;
};

export type SetLimitResponse = {
  transactionID: string;
};

export type AddSignatoriesRequest = {
  context: Context;
  accountID: string;
  userIDs: string[];
};

export type AddSignatoriesResponse = {
  transactionID: string;
};

export type RemoveSignatoriesRequest = {
  context: Context;
  accountID: string;
  userIDs: string[];
};

export type RemoveSignatoriesResponse = {
  transactionID: string;
};

export type TransferRequest = {
  context: Context;
  fromAccountID: string;
  toAccountIdentifier: Identifier;
  amount: Amount;
  reference: string;
};

// tslint:disable-next-line:no-empty-interface

export type TransferResponse = {
  transactionID: string;
};

export type TransferOffPlatformRequest = {
  context: Context;
  fromAccountID: string;
  recipientID: string;
  recipientLabel: string; // used for generating the transaction description for the mua request
  amount: Amount;
};

export type TransferOffPlatformResponse = {
  transactionID: string;
};

export type ClaimClaimableBalanceRequest = {
  context: Context;
  claimableBalanceID: string;
  claimantAccountID: string;
};

export type ClaimClaimableBalanceResponse = {
  transactionID: string;
};

export const AccountOperatorServiceProviderName = "stellar-AccountOperator";

export const AccountOperator = {
  async SetLimit(
    request: SetLimitRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<SetLimitResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AccountOperatorServiceProviderName}.SetLimit`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
  async AddSignatories(
    request: AddSignatoriesRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<AddSignatoriesResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AccountOperatorServiceProviderName}.AddSignatories`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
  async RemoveSignatories(
    request: RemoveSignatoriesRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<RemoveSignatoriesResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AccountOperatorServiceProviderName}.RemoveSignatories`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
  async Transfer(
    request: TransferRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<TransferResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AccountOperatorServiceProviderName}.Transfer`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
  async TransferOffPlatform(
    request: TransferOffPlatformRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<TransferOffPlatformResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AccountOperatorServiceProviderName}.TransferOffPlatform`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
  async ClaimClaimableBalance(
    request: ClaimClaimableBalanceRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<ClaimClaimableBalanceResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AccountOperatorServiceProviderName}.ClaimClaimableBalance`,
        request,
      },
      jsonRPCRequestOpts,
    );
  },
};

export class AccountOperatorWithMiddleware {
  client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  async TransferOffPlatform(
    request: TransferOffPlatformRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
    clientRequestOpts?: clientRequestOpts,
  ): Promise<TransferOffPlatformResponse> {
    return await this.client.jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AccountOperatorServiceProviderName}.TransferOffPlatform`,
        request,
      },
      jsonRPCRequestOpts,
      clientRequestOpts,
    );
  }
}
