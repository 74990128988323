/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: financial/assetflowReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_assetflow_pb = require('../financial/assetflow_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var num_decimal_pb = require('../num/decimal_pb.js')

var financial_assetflowCategory_pb = require('../financial/assetflowCategory_pb.js')

var ledger_amount_pb = require('../ledger/amount_pb.js')

var financial_assetflowData_pb = require('../financial/assetflowData_pb.js')

var financial_assetflowReader_meshproto_pb = require('../financial/assetflowReader_meshproto_pb.js')
const proto = {};
proto.financial = require('./assetflowReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.AssetflowReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.AssetflowReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneAssetflowRequest,
 *   !proto.financial.ReadOneAssetflowResponse>}
 */
const methodDescriptor_AssetflowReaderUNSCOPED_ReadOneAssetflowUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.AssetflowReaderUNSCOPED/ReadOneAssetflowUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_assetflowReader_meshproto_pb.ReadOneAssetflowRequest,
  financial_assetflowReader_meshproto_pb.ReadOneAssetflowResponse,
  /**
   * @param {!proto.financial.ReadOneAssetflowRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_assetflowReader_meshproto_pb.ReadOneAssetflowResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneAssetflowRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneAssetflowResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneAssetflowResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.AssetflowReaderUNSCOPEDClient.prototype.readOneAssetflowUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.AssetflowReaderUNSCOPED/ReadOneAssetflowUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowReaderUNSCOPED_ReadOneAssetflowUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadOneAssetflowRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneAssetflowResponse>}
 *     Promise that resolves to the response
 */
proto.financial.AssetflowReaderUNSCOPEDPromiseClient.prototype.readOneAssetflowUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.AssetflowReaderUNSCOPED/ReadOneAssetflowUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowReaderUNSCOPED_ReadOneAssetflowUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyAssetflowRequest,
 *   !proto.financial.ReadManyAssetflowResponse>}
 */
const methodDescriptor_AssetflowReaderUNSCOPED_ReadManyAssetflowUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.AssetflowReaderUNSCOPED/ReadManyAssetflowUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_assetflowReader_meshproto_pb.ReadManyAssetflowRequest,
  financial_assetflowReader_meshproto_pb.ReadManyAssetflowResponse,
  /**
   * @param {!proto.financial.ReadManyAssetflowRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_assetflowReader_meshproto_pb.ReadManyAssetflowResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyAssetflowRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyAssetflowResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyAssetflowResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.AssetflowReaderUNSCOPEDClient.prototype.readManyAssetflowUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.AssetflowReaderUNSCOPED/ReadManyAssetflowUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowReaderUNSCOPED_ReadManyAssetflowUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadManyAssetflowRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyAssetflowResponse>}
 *     Promise that resolves to the response
 */
proto.financial.AssetflowReaderUNSCOPEDPromiseClient.prototype.readManyAssetflowUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.AssetflowReaderUNSCOPED/ReadManyAssetflowUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowReaderUNSCOPED_ReadManyAssetflowUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllAssetflowRequest,
 *   !proto.financial.ReadAllAssetflowResponse>}
 */
const methodDescriptor_AssetflowReaderUNSCOPED_ReadAllAssetflowUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.AssetflowReaderUNSCOPED/ReadAllAssetflowUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_assetflowReader_meshproto_pb.ReadAllAssetflowRequest,
  financial_assetflowReader_meshproto_pb.ReadAllAssetflowResponse,
  /**
   * @param {!proto.financial.ReadAllAssetflowRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_assetflowReader_meshproto_pb.ReadAllAssetflowResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllAssetflowRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllAssetflowResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllAssetflowResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.AssetflowReaderUNSCOPEDClient.prototype.readAllAssetflowUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.AssetflowReaderUNSCOPED/ReadAllAssetflowUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowReaderUNSCOPED_ReadAllAssetflowUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadAllAssetflowRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllAssetflowResponse>}
 *     Promise that resolves to the response
 */
proto.financial.AssetflowReaderUNSCOPEDPromiseClient.prototype.readAllAssetflowUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.AssetflowReaderUNSCOPED/ReadAllAssetflowUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowReaderUNSCOPED_ReadAllAssetflowUNSCOPED);
};


module.exports = proto.financial;

