import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  Fade,
  FadeProps,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  SlideProps,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { TextNumField } from "components/FormFields";
import { AccountOperator, MaxSetLimitAmount } from "james/stellar";
import { useSnackbar } from "notistack";
import { ScopeDeterminer } from "james/search/scope";
import { Permission } from "james/security/Permission";
import {
  AccountOperatorServiceProviderName,
  SetLimitResponse,
} from "james/stellar/AccountOperator";
import { useIsMounted } from "hooks";
import { formatTextNum } from "utilities/number";
import { Amount, Token } from "james/ledger";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionSucceededNotificationTypeName,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionFailedNotification,
  TransactionSubmissionResolutionFailedNotification,
} from "james/ledger/TransactionNotifications";
import { useNotificationContext } from "context/Notification/Notification";
import { Notification } from "james/notification/Notification";

interface SetLimitDialogProps {
  open: boolean;
  onClose: () => void;
  token: Token;
  accountID: string;
}

type ValidationStateType = {
  [key: string]: string | undefined;
};

// Todo: This dialog should stop depending on props and rather open based on url routes
export function SetLimitDialog(props: SetLimitDialogProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [limit, setLimit] = useState(props.token.newAmountOf("0"));
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [canSetAssetLimit, setCanSetAssetLimit] = useState(false);
  const [isSignatoryOnAccount, setIsSignatoryOnAccount] = useState(false);
  const isMounted = useIsMounted();
  const { stellarAccountContext } = useAccountContext();
  const [validationState, setValidationState] = useState<ValidationStateType>(
    {},
  );
  const [ledgerTokenBalance, setLedgerTokenBalance] = useState<Amount>(
    props.token.newAmountOf("0"),
  );
  const { registerNotificationCallback } = useNotificationContext();

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  const performValidations: () => boolean = () => {
    const newValidationState: ValidationStateType = {};

    if (ledgerTokenBalance && limit.value.lt(ledgerTokenBalance.value)) {
      newValidationState.assetLimit =
        "Your Limit cannot be less than what you hold in your account";
    }

    if (limit.value.isZero()) {
      newValidationState.assetLimit = "Your Limit cannot be 0";
    }

    if (limit.value.isGreaterThan(MaxSetLimitAmount)) {
      newValidationState.assetLimit = `Cannot be greater than ${MaxSetLimitAmount}`;
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  useEffect(() => {
    (async () => {
      if (!isMounted()) {
        return;
      }

      // if account context is still loading return
      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialisation error::${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialisation Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        // close the dialog
        props.onClose();
      }

      try {
        const viewModel = stellarAccountContext.accounts.find(
          (v) => v.id === props.accountID,
        );

        if (!viewModel) {
          console.error(`account not found`);
          enqueueSnackbar(`Account Not Found`, {
            variant: "error",
          });

          // close the dialog
          props.onClose();
          return;
        }

        const tokenBalance = viewModel.getTokenBalance(props.token);
        if (isMounted() && tokenBalance) {
          setLedgerTokenBalance(tokenBalance.amount);
          setLimit(tokenBalance.limit);
        }

        // check if the user is a signatory on the account
        try {
          if (isMounted()) {
            setIsSignatoryOnAccount(
              await stellarAccountContext.checkUserSignatoryOnAccount(
                LedgerIDIdentifier(viewModel.ledgerID),
              ),
            );
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining if user is signatory on account: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Determining Signatory Status", {
            variant: "error",
          });
        }

        try {
          const determineScopeAuthorisationByRolesResponse =
            await ScopeDeterminer.DetermineScopeAuthorisationByRoles({
              context: authContext,
              groupID: viewModel ? viewModel.ownerID : "",
              buildScopeTree: false,
              service: new Permission({
                serviceName: "SetLimit",
                serviceProvider: AccountOperatorServiceProviderName,
                description: "?",
              }),
            });

          if (isMounted()) {
            setCanSetAssetLimit(
              determineScopeAuthorisationByRolesResponse.authorized,
            );
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining scope authorisation by roles: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Determining Scope Authorisation", {
            variant: "error",
          });
          return;
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `could not read account view model:  ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error retrieving account view model", {
          variant: "error",
        });
      }
    })().finally(() => {
      if (isMounted()) {
        setLoading(false);
      }
    });
  }, [
    authContext,
    enqueueSnackbar,
    stellarAccountContext.loading,
    stellarAccountContext.error,
    isMounted,
    props.accountID,
    props.token,
  ]);

  const handleSetLimit = async () => {
    if (!performValidations()) {
      return;
    }

    setSubmitting(true);
    let setLimitResponse: SetLimitResponse;
    try {
      setLimitResponse = await AccountOperator.SetLimit({
        context: authContext,
        accountID: props.accountID,
        limit,
      });
      enqueueSnackbar("Limit Being Set", { variant: "info" });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `unable to set limit on account: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Unable to set limit on account: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      return;
    } finally {
      setSubmitting(false);
    }

    try {
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: setLimitResponse.transactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          // check transaction id
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === setLimitResponse.transactionID
          ) {
            enqueueSnackbar(`Setting Limit Successful`, {
              variant: "success",
            });
          } else if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === setLimitResponse.transactionID
          ) {
            enqueueSnackbar("Error! Setting Limit Failed", {
              variant: "error",
            });
          } else if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === setLimitResponse.transactionID
          ) {
            enqueueSnackbar(
              "Warning! Something has gone wrong while setting limit",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      console.error("error registering");
      enqueueSnackbar(
        "Warning! Unable to Register for Set Limit Ledger Notifications - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }

    // close dialog
    props.onClose();
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": isMobile
            ? {
                width: "100%",
                padding: "0px",
                margin: "0px",
                position: "absolute",
                bottom: 0,
              }
            : {
                maxWidth: "392px",
                width: "100%",
              },
        },
      }}
      open={props.open}
      TransitionComponent={isMobile ? slideTransition : fadeTransition}
    >
      <Card>
        <CardHeader
          sx={(theme) => ({
            padding: theme.spacing(1, 2, 1, 3),
            paddingRight: "16px",
          })}
          disableTypography
          title={
            <Grid
              sx={{
                alignItems: "center",
              }}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Box
                  sx={(theme) => ({
                    display: "grid",
                    gridTemplateColumns: "repeat(2,auto)",
                    gridColumnGap: theme.spacing(1),
                  })}
                >
                  <Typography variant="h5">Set Limit</Typography>
                </Box>
              </Grid>
              <Grid item>
                <IconButton
                  id="setLimit-close-button"
                  onClick={props.onClose}
                  size="small"
                  disabled={submitting}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          }
        />
        <CardContent
          sx={[{ padding: "40px" }, isMobile && { padding: theme.spacing(3) }]}
        >
          <Typography variant="h6">
            Set a limit for the specified asset
          </Typography>
          <Grid
            sx={(theme) => ({
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(3),
            })}
            container
          >
            <Grid xs={12} item>
              {!loading ? (
                <TextNumField
                  id="setLimit-limit-formfield"
                  fullWidth
                  disabled={submitting}
                  noDecimalPlaces={7}
                  helperText={
                    validationState.assetLimit
                      ? validationState.assetLimit
                      : `Available Balance: ${props.token.code} ${formatTextNum(
                          ledgerTokenBalance.value,
                          { addDecimalPadding: true },
                        )}`
                  }
                  error={!!validationState.assetLimit}
                  disallowNegative
                  InputProps={{
                    startAdornment: (
                      <Typography
                        style={{ paddingRight: "8px" }}
                        color="textSecondary"
                      >
                        {props.token.code}
                      </Typography>
                    ),
                    endAdornment: (
                      <Box
                        sx={{
                          padding: 0,
                          display: "flex",
                        }}
                      >
                        {submitting && (
                          <CircularProgress
                            size={20}
                            sx={{
                              marginRight: "4px",
                              marginLeft: "4px",
                            }}
                          />
                        )}
                        <Typography
                          id="fundAccount-max-typography"
                          onClick={() =>
                            setLimit(limit.setValue(MaxSetLimitAmount))
                          }
                          color={submitting ? "textSecondary" : "secondary"}
                        >
                          MAX
                        </Typography>
                      </Box>
                    ),
                  }}
                  sx={(theme) => ({
                    paddingRight: { sm: theme.spacing(1) },
                  })}
                  label="Limit"
                  onChange={(e) => {
                    handleClearValidation("assetLimit");
                    setLimit(limit.setValue(e.target.value));
                  }}
                  value={limit.value}
                />
              ) : (
                <Skeleton width={"100%"} height={isMobile ? 42 : 36} />
              )}
            </Grid>
            <Grid xs={12} item>
              {!loading ? (
                <Box
                  sx={(theme) => ({
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginTop: theme.spacing(2),
                  })}
                >
                  <Tooltip
                    placement="top"
                    title={
                      !isSignatoryOnAccount
                        ? "user is not a signatory on account"
                        : !canSetAssetLimit
                          ? "not authorized to set asset limit on account"
                          : ""
                    }
                  >
                    <Box component={"span"} sx={{ width: "100%" }}>
                      <Button
                        id="setLimit-update-button"
                        disabled={
                          !isSignatoryOnAccount ||
                          !canSetAssetLimit ||
                          loading ||
                          submitting
                        }
                        sx={{
                          height: {
                            xs: "48px",
                            sm: "36px",
                          },
                        }}
                        fullWidth
                        color="primary"
                        variant="contained"
                        size={isMobile ? "large" : "small"}
                        onClick={handleSetLimit}
                      >
                        Update
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>
              ) : (
                <Skeleton width={"100%"} height={isMobile ? 48 : 36} />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
}

const slideTransition = (tprops: SlideProps) => {
  return <Slide direction="up" {...tprops} />;
};

const fadeTransition = (tprops: FadeProps) => {
  return <Fade in {...tprops} />;
};
