import { useEffect, useRef } from "react";
import { Horizon } from "stellar-sdk";
import { v4 as uuidV4 } from "uuid";
import { useStellarContext } from "../context/Stellar";
import EffectRecord = Horizon.ServerApi.EffectRecord;
import { useApplicationContext } from "../context/Application/Application";
import { useFirebaseContext } from "../context/Firebase";

// Type definition for a record that tracks Stellar account effect subscriptions
// `LedgerID` represents the ledger identifier, and `Unsubscribe` is a function to unsubscribe from the subscription.
type StellarAccountEffectsSubscriptionsRecord = {
  LedgerID: string;
  Unsubscribe: () => void;
};

// Type definition for a record that tracks callbacks for Stellar account effects
// `LedgerID` represents the ledger identifier, and `Callbacks` is a collection of callback functions identified by unique keys.
type StellarAccountEffectsCallbackRecord = {
  LedgerID: string;
  Callbacks: { [key: string]: (effect: EffectRecord) => void };
};

// Custom hook to manage Stellar account effects subscriptions and callbacks
export const useStellarAccountEffectsNotifier = () => {
  // Ref to store the registry of subscriptions
  const { current: stellarAccountEffectsSubscriptionsRegistry } = useRef<
    StellarAccountEffectsSubscriptionsRecord[]
  >([]);

  // Ref to store the registry of callbacks for each ledger
  const { current: stellarAccountEffectsCallbackRegistry } = useRef<
    StellarAccountEffectsCallbackRecord[]
  >([]);

  // Get the Stellar context, including the account effects subscriber
  const { stellarContextAccountEffectsSubscriber } = useStellarContext();

  // Get user authentication status from application context
  const { userAuthenticated } = useApplicationContext();

  // Get Firebase authentication status from Firebase context
  const { firebaseAuthenticated } = useFirebaseContext();

  // Function to handle effects received from Stellar and trigger the registered callbacks
  const subscriptionHandleEffect = (
    effect: EffectRecord,
    ledgerID: string,
    stellarAccountEffectsCallbackRegistry: StellarAccountEffectsCallbackRecord[],
  ) => {
    // Find the callbacks associated with the given ledgerID
    const subscriptionSubscribers = stellarAccountEffectsCallbackRegistry.find(
      (v) => v.LedgerID === ledgerID,
    );

    if (!subscriptionSubscribers) {
      console.debug("subscription callbacks not found");
      return;
    }

    // Call each registered callback function with the received effect
    for (const key in subscriptionSubscribers.Callbacks) {
      try {
        subscriptionSubscribers.Callbacks[key](effect);
      } catch (e) {
        console.error("error calling subscription callback", e);
      }
    }
  };

  // Function to register a new callback for a specific ledgerID
  const registerAccountEffectsCallback = (
    ledgerID: string,
    callback: (effect: EffectRecord) => void,
  ) => {
    // Generate a unique ID for the callback
    const callbackID = uuidV4();

    // Check if callbacks already exist for the given ledgerID
    const callbackFuncsForLedgerID = stellarAccountEffectsCallbackRegistry.find(
      (value) => value.LedgerID === ledgerID,
    );

    // If callbacks exist, add the new callback to the existing collection, otherwise, create a new entry
    callbackFuncsForLedgerID
      ? stellarAccountEffectsCallbackRegistry.push({
          LedgerID: ledgerID,
          Callbacks: {
            ...callbackFuncsForLedgerID.Callbacks,
            [callbackID]: callback,
          },
        })
      : stellarAccountEffectsCallbackRegistry.push({
          LedgerID: ledgerID,
          Callbacks: { [callbackID]: callback },
        });

    // Check if a subscription already exists for the given ledgerID
    let SubscriptionFound = false;
    if (
      stellarAccountEffectsSubscriptionsRegistry.find(
        (value) => value.LedgerID === ledgerID,
      )
    ) {
      SubscriptionFound = true;
    }

    // If no subscription exists, subscribe to the Stellar Horizon SDK
    if (!SubscriptionFound) {
      const subscribeResponse =
        stellarContextAccountEffectsSubscriber.Subscribe({
          ledgerID: ledgerID,
          callback: (effect: EffectRecord) => {
            return subscriptionHandleEffect(
              effect,
              ledgerID,
              stellarAccountEffectsCallbackRegistry,
            );
          },
          cursor: "now", // Start from the latest ledger
        });

      // Store the unsubscribe function in the registry for future use
      stellarAccountEffectsSubscriptionsRegistry.push({
        LedgerID: ledgerID,
        Unsubscribe: subscribeResponse.unsubscribe,
      });
    }

    // Return a function that allows the client to remove the registered callback
    return () => {
      for (const i in stellarAccountEffectsCallbackRegistry) {
        if (stellarAccountEffectsCallbackRegistry[i].LedgerID === ledgerID) {
          const callbacksCopy =
            stellarAccountEffectsCallbackRegistry[i].Callbacks;
          delete callbacksCopy[callbackID]; // Remove the callback by its ID
          stellarAccountEffectsCallbackRegistry[i].Callbacks = callbacksCopy;
          break;
        }
      }
    };
  };

  // Clean up subscriptions and callbacks when the user logs out
  useEffect(() => {
    if (!(userAuthenticated && firebaseAuthenticated)) {
      // Unsubscribe from all active subscriptions
      stellarAccountEffectsSubscriptionsRegistry.forEach((v) =>
        v.Unsubscribe(),
      );
      // Clear the subscription registry
      stellarAccountEffectsSubscriptionsRegistry.forEach(() =>
        stellarAccountEffectsSubscriptionsRegistry.pop(),
      );
      // Clear the callback registry
      stellarAccountEffectsCallbackRegistry.forEach(() =>
        stellarAccountEffectsCallbackRegistry.pop(),
      );
    }
  }, [userAuthenticated, firebaseAuthenticated]);

  return {
    registerAccountEffectsCallback,
  };
};
