// source: views/financialPaymentView/modelUpdater.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var financial_payment_pb = require('../../financial/payment_pb.js');
goog.object.extend(proto, financial_payment_pb);
goog.exportSymbol('proto.financialPaymentView.FullUpdateRequest', null, global);
goog.exportSymbol('proto.financialPaymentView.FullUpdateResponse', null, global);
goog.exportSymbol('proto.financialPaymentView.UpdateForPaymentIDRequest', null, global);
goog.exportSymbol('proto.financialPaymentView.UpdateForPaymentIDResponse', null, global);
goog.exportSymbol('proto.financialPaymentView.UpdateForPaymentRequest', null, global);
goog.exportSymbol('proto.financialPaymentView.UpdateForPaymentResponse', null, global);
goog.exportSymbol('proto.financialPaymentView.UpdateForSmartInstrumentIDRequest', null, global);
goog.exportSymbol('proto.financialPaymentView.UpdateForSmartInstrumentIDResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financialPaymentView.UpdateForPaymentIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financialPaymentView.UpdateForPaymentIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financialPaymentView.UpdateForPaymentIDRequest.displayName = 'proto.financialPaymentView.UpdateForPaymentIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financialPaymentView.UpdateForPaymentIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financialPaymentView.UpdateForPaymentIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financialPaymentView.UpdateForPaymentIDResponse.displayName = 'proto.financialPaymentView.UpdateForPaymentIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financialPaymentView.UpdateForSmartInstrumentIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.displayName = 'proto.financialPaymentView.UpdateForSmartInstrumentIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financialPaymentView.UpdateForSmartInstrumentIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.displayName = 'proto.financialPaymentView.UpdateForSmartInstrumentIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financialPaymentView.UpdateForPaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financialPaymentView.UpdateForPaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financialPaymentView.UpdateForPaymentRequest.displayName = 'proto.financialPaymentView.UpdateForPaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financialPaymentView.UpdateForPaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financialPaymentView.UpdateForPaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financialPaymentView.UpdateForPaymentResponse.displayName = 'proto.financialPaymentView.UpdateForPaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financialPaymentView.FullUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financialPaymentView.FullUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financialPaymentView.FullUpdateRequest.displayName = 'proto.financialPaymentView.FullUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financialPaymentView.FullUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financialPaymentView.FullUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financialPaymentView.FullUpdateResponse.displayName = 'proto.financialPaymentView.FullUpdateResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financialPaymentView.UpdateForPaymentIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financialPaymentView.UpdateForPaymentIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
paymentid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financialPaymentView.UpdateForPaymentIDRequest}
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financialPaymentView.UpdateForPaymentIDRequest;
  return proto.financialPaymentView.UpdateForPaymentIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financialPaymentView.UpdateForPaymentIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financialPaymentView.UpdateForPaymentIDRequest}
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financialPaymentView.UpdateForPaymentIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financialPaymentView.UpdateForPaymentIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPaymentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financialPaymentView.UpdateForPaymentIDRequest} returns this
*/
proto.financialPaymentView.UpdateForPaymentIDRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financialPaymentView.UpdateForPaymentIDRequest} returns this
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string paymentID = 2;
 * @return {string}
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.prototype.getPaymentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financialPaymentView.UpdateForPaymentIDRequest} returns this
 */
proto.financialPaymentView.UpdateForPaymentIDRequest.prototype.setPaymentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financialPaymentView.UpdateForPaymentIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financialPaymentView.UpdateForPaymentIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financialPaymentView.UpdateForPaymentIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForPaymentIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financialPaymentView.UpdateForPaymentIDResponse}
 */
proto.financialPaymentView.UpdateForPaymentIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financialPaymentView.UpdateForPaymentIDResponse;
  return proto.financialPaymentView.UpdateForPaymentIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financialPaymentView.UpdateForPaymentIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financialPaymentView.UpdateForPaymentIDResponse}
 */
proto.financialPaymentView.UpdateForPaymentIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financialPaymentView.UpdateForPaymentIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financialPaymentView.UpdateForPaymentIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financialPaymentView.UpdateForPaymentIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForPaymentIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrumentid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financialPaymentView.UpdateForSmartInstrumentIDRequest;
  return proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmartinstrumentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrumentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest} returns this
*/
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest} returns this
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string smartInstrumentID = 2;
 * @return {string}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.prototype.getSmartinstrumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest} returns this
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDRequest.prototype.setSmartinstrumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financialPaymentView.UpdateForSmartInstrumentIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financialPaymentView.UpdateForSmartInstrumentIDResponse}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financialPaymentView.UpdateForSmartInstrumentIDResponse;
  return proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financialPaymentView.UpdateForSmartInstrumentIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financialPaymentView.UpdateForSmartInstrumentIDResponse}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financialPaymentView.UpdateForSmartInstrumentIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financialPaymentView.UpdateForPaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financialPaymentView.UpdateForPaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financialPaymentView.UpdateForPaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForPaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financialPaymentView.UpdateForPaymentRequest}
 */
proto.financialPaymentView.UpdateForPaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financialPaymentView.UpdateForPaymentRequest;
  return proto.financialPaymentView.UpdateForPaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financialPaymentView.UpdateForPaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financialPaymentView.UpdateForPaymentRequest}
 */
proto.financialPaymentView.UpdateForPaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financialPaymentView.UpdateForPaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financialPaymentView.UpdateForPaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financialPaymentView.UpdateForPaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForPaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financialPaymentView.UpdateForPaymentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financialPaymentView.UpdateForPaymentRequest} returns this
*/
proto.financialPaymentView.UpdateForPaymentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financialPaymentView.UpdateForPaymentRequest} returns this
 */
proto.financialPaymentView.UpdateForPaymentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financialPaymentView.UpdateForPaymentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional financial.Payment payment = 2;
 * @return {?proto.financial.Payment}
 */
proto.financialPaymentView.UpdateForPaymentRequest.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 2));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financialPaymentView.UpdateForPaymentRequest} returns this
*/
proto.financialPaymentView.UpdateForPaymentRequest.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financialPaymentView.UpdateForPaymentRequest} returns this
 */
proto.financialPaymentView.UpdateForPaymentRequest.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financialPaymentView.UpdateForPaymentRequest.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financialPaymentView.UpdateForPaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financialPaymentView.UpdateForPaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financialPaymentView.UpdateForPaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForPaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financialPaymentView.UpdateForPaymentResponse}
 */
proto.financialPaymentView.UpdateForPaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financialPaymentView.UpdateForPaymentResponse;
  return proto.financialPaymentView.UpdateForPaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financialPaymentView.UpdateForPaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financialPaymentView.UpdateForPaymentResponse}
 */
proto.financialPaymentView.UpdateForPaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financialPaymentView.UpdateForPaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financialPaymentView.UpdateForPaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financialPaymentView.UpdateForPaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.UpdateForPaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financialPaymentView.FullUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financialPaymentView.FullUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financialPaymentView.FullUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.FullUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financialPaymentView.FullUpdateRequest}
 */
proto.financialPaymentView.FullUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financialPaymentView.FullUpdateRequest;
  return proto.financialPaymentView.FullUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financialPaymentView.FullUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financialPaymentView.FullUpdateRequest}
 */
proto.financialPaymentView.FullUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financialPaymentView.FullUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financialPaymentView.FullUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financialPaymentView.FullUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.FullUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financialPaymentView.FullUpdateRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financialPaymentView.FullUpdateRequest} returns this
*/
proto.financialPaymentView.FullUpdateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financialPaymentView.FullUpdateRequest} returns this
 */
proto.financialPaymentView.FullUpdateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financialPaymentView.FullUpdateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financialPaymentView.FullUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financialPaymentView.FullUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financialPaymentView.FullUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.FullUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financialPaymentView.FullUpdateResponse}
 */
proto.financialPaymentView.FullUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financialPaymentView.FullUpdateResponse;
  return proto.financialPaymentView.FullUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financialPaymentView.FullUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financialPaymentView.FullUpdateResponse}
 */
proto.financialPaymentView.FullUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financialPaymentView.FullUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financialPaymentView.FullUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financialPaymentView.FullUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financialPaymentView.FullUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.financialPaymentView);
