import React from "react";
import { styled } from "@mui/material/styles";
import { Link, TextareaAutosize, Typography } from "@mui/material";
import { ClientKYCStatus, Client } from "james/client";

const PREFIX = "KYCCaption";

const classes = {
  kycStatusReasonWrapper: `${PREFIX}-kycStatusReasonWrapper`,
  kycTextArea: `${PREFIX}-kycTextArea`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.kycStatusReasonWrapper}`]: {
    padding: 3,
  },

  [`& .${classes.kycTextArea}`]: {
    color: theme.palette.text.tertiary,
    fontFamily: "Poppins",
    fontSize: 14,
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    border: "unset",
    resize: "none",
    padding: theme.spacing(2, 0, 0, 0),
  },
}));

interface KYCCaptionProps {
  client: Client;
}

export function KYCCaption(props: KYCCaptionProps) {
  switch (props.client.kycStatus) {
    case ClientKYCStatus.Outstanding:
      // outstanding without a reason
      if (props.client.kycStatusReason === "") {
        return (
          <Root
            sx={(theme) => ({
              display: "grid",
              gridRowGap: theme.spacing(2),
              gridTemplateRows: "repeat(3, auto)",
            })}
          >
            <Typography variant="h3">Let's get you verified</Typography>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              variant="body1"
            >
              We need the following information and documentation so that we can
              verify you in line with the Financial Intelligence Centre Act. 38
              of 2001.
            </Typography>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              variant="body1"
            >
              If you have any questions, please email us at
              <Link
                sx={(theme) => ({
                  paddingLeft: theme.spacing(0.5),
                })}
                href="mailto:compliance@meshtrade.co"
                component="a"
                underline="hover"
                color="secondary"
              >
                compliance@meshtrade.co
              </Link>
            </Typography>
          </Root>
        );
      }
      return (
        <Root>
          <Typography variant="h3">
            Here&apos;s the info we still need from you
          </Typography>
          <Typography
            sx={(theme) => ({
              paddingTop: theme.spacing(2),
              color: theme.palette.text.secondary,
            })}
            variant="body1"
          >
            Please submit the following information and we&apos;ll verify you as
            soon as we can.
          </Typography>
          <TextareaAutosize
            disabled
            className={classes.kycTextArea}
            maxRows={7}
            style={{
              whiteSpace: "pre-wrap",
            }}
            aria-label="maximum height"
            placeholder="Outstanding KYC status reason..."
            value={props.client.kycStatusReason}
          />
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              paddingTop: theme.spacing(2),
            })}
            variant="body1"
          >
            If you have any questions, please email us at
            <Link
              sx={(theme) => ({
                paddingLeft: theme.spacing(0.5),
              })}
              href="mailto:compliance@meshtrade.co"
              component="a"
              underline="hover"
              color="secondary"
            >
              compliance@meshtrade.co
            </Link>
          </Typography>
        </Root>
      );

    case ClientKYCStatus.VerificationInProgressStatus:
      return (
        <Root
          sx={(theme) => ({
            display: "grid",
            gridRowGap: theme.spacing(2),
            gridTemplateRows: "repeat(3, auto)",
          })}
        >
          <Typography variant="h3">Verification in progress</Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
            variant="body1"
          >
            Your KYC information is being verified. For now, you can explore
            Mesh, but you&apos;ll have limited functionality.
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
            variant="body1"
          >
            If you have any questions, please email us at
            <Link
              sx={(theme) => ({
                paddingLeft: theme.spacing(0.5),
              })}
              href="mailto:compliance@meshtrade.co"
              component="a"
              underline="hover"
              color="secondary"
            >
              compliance@meshtrade.co
            </Link>
          </Typography>
        </Root>
      );
    case ClientKYCStatus.VerifiedStatus:
      return (
        <Root
          sx={(theme) => ({
            display: "grid",
            gridRowGap: theme.spacing(2),
            gridTemplateRows: "repeat(3, auto)",
          })}
        >
          <Typography variant="h3">You are verified</Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
            variant="body1"
          >
            You now have access to the complete Mesh experience.
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
            variant="body1"
          >
            If you have any questions, please email us at
            <Link
              sx={(theme) => ({
                paddingLeft: theme.spacing(0.5),
              })}
              href="mailto:compliance@meshtrade.co"
              component="a"
              underline="hover"
              color="secondary"
            >
              compliance@meshtrade.co
            </Link>
          </Typography>
        </Root>
      );
    default:
      return <Root></Root>;
  }
}
