import React, { useCallback, useContext, useRef } from "react";
import { sleep } from "utilities/general";
import { Token } from "james/ledger";
import { StellarToml } from "stellar-sdk";
import {
  getNetworkServer,
  NativeAssetTokenCode,
  StellarNetwork,
} from "james/stellar";
import { LedgerNetwork } from "james/ledger/Network";
import config from "react-global-configuration";
import {
  ServerClient,
  StellarPriceHistorian,
  StellarSpotPricer,
} from "pkgTemp/stellar";
import { StellarExpertClient } from "./StellarExpertClient";
import dayjs from "dayjs";
import stellarLogoBlack from "./stellarLogoSmallBlack.png";
import stellarLogoWithWordsBlack from "./stellarLogoWithWordsBlack.png";
import { StellarAccountLedgerDetailsPopulator } from "pkgTemp/stellar/AccountLedgerDetailsPopulator";
import { Model as StellarAccount } from "james/views/stellarAccountView/Model";
import { Account } from "james/stellar/Account";
import { Balance as StellarAccountViewBalance } from "james/views/stellarAccountView/Balance";
import { useLedgerTokenViewContext } from "../LedgerTokenView";
import { StellarAccountSignatoriesFetcher } from "pkgTemp/stellar/AccountSignatoriesFetcher";
import { useErrorContext } from "../Error";
import { ErrLedgerTokenViewModelNotFound } from "james/views/ledgerTokenView/ledgerTokenViewReaderErrors";
import { Environment } from "const";
import { StellarAccountEffectsSubscriber } from "../../pkgTemp/stellar/AccountEffectsSubscriber";

type TomlFile = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type IssuerInformation = {
  name: string;
  description: string;
  logoURL: string;
  email: string;
  website: string;
  twitter: string;
};

interface ContextType {
  stellarExpertClient: StellarExpertClient;
  stellarContextSpotPricer: StellarSpotPricer;
  stellarContextAccountEffectsSubscriber: StellarAccountEffectsSubscriber;
  stellarContextPriceHistorian: StellarPriceHistorian;
  stellarAccountLedgerDetailsPopulator: StellarAccountLedgerDetailsPopulator;
  stellarAccountSignatoriesFetcher: StellarAccountSignatoriesFetcher;
  stellarContextGetTokenIconURL: (token: Token) => Promise<string>;
  stellarContextGetTokenDescription: (token: Token) => Promise<string>;
  stellarAccountContextPopulateModelWithLedgerDetails: (
    model: StellarAccount,
  ) => Promise<StellarAccount>;
  stellarContextGetTokenIssuerInformation: (
    token: Token,
  ) => Promise<IssuerInformation>;
  stellarContextGetTokenInformation: (
    token: Token,
  ) => Promise<TokenInformation>;
}

export type TokenInformation = {
  description: string;
  rating: string;
  firstTransaction: dayjs.Dayjs;
  totalTrades: string;
  redemptionInstructions: string;
  attestationOfReserve: string;
  conditions: string;
};

const Context = React.createContext({} as ContextType);

export function StellarContext({ children }: { children?: React.ReactNode }) {
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { errorContextErrorTranslator } = useErrorContext();

  // construct stellar service providers
  const { current: stellarExpertClient } = useRef(
    (() => {
      const env = config.get("environment");
      if (env === Environment.Testing) {
        return new StellarExpertClient(
          "https://testing-stellar-expert-proxy.mesh.trade",
        );
      } else if (env === Environment.Staging) {
        return new StellarExpertClient(
          "https://staging-stellar-expert-proxy.mesh.trade",
        );
      } else if (env === Environment.Production) {
        return new StellarExpertClient(
          "https://production-stellar-expert-proxy.mesh.trade",
        );
      } else {
        return new StellarExpertClient(
          "https://development-stellar-expert-proxy.mesh.trade",
        );
      }
    })(),
  );
  const { current: network } = useRef(
    (() => {
      if (config.get("environment") === Environment.Production) {
        return StellarNetwork.PublicNetwork;
      } else {
        return StellarNetwork.TestSDFNetwork;
      }
    })(),
  );
  const { current: server } = useRef(
    (() => {
      if (config.get("environment") === Environment.Production) {
        return getNetworkServer(StellarNetwork.PublicNetwork);
      } else {
        return getNetworkServer(StellarNetwork.TestSDFNetwork);
      }
    })(),
  );
  const { current: stellarContextSpotPricer } = useRef(
    new StellarSpotPricer({
      client: new ServerClient({
        server: server,
        network: network,
      }),
    }),
  );

  const { current: stellarContextAccountEffectsSubscriber } = useRef(
    new StellarAccountEffectsSubscriber({
      client: new ServerClient({
        server: server,
        network: network,
      }),
    }),
  );

  const { current: stellarContextPriceHistorian } = useRef(
    new StellarPriceHistorian({
      client: new ServerClient({
        server: server,
        network: network,
      }),
    }),
  );
  const { current: stellarAccountLedgerDetailsPopulator } = useRef(
    new StellarAccountLedgerDetailsPopulator({
      client: new ServerClient({
        server: server,
        network: network,
      }),
    }),
  );

  const { current: stellarAccountSignatoriesFetcher } = useRef(
    new StellarAccountSignatoriesFetcher({
      client: new ServerClient({
        server: server,
        network: network,
      }),
    }),
  );

  // cached account home domain retrieval function
  const { current: accountHomeDomainFetchInProgressFor } = useRef<{
    [key: string]: boolean;
  }>({});
  const { current: accountHomeDomainCache } = useRef<{ [key: string]: string }>(
    {},
  );
  const getAccountHomeDomain: (
    network: LedgerNetwork | "-",
    accountID: string,
  ) => Promise<string> = useCallback(
    async (network: LedgerNetwork | "-", accountID: string) => {
      // if the homeDomain is already stored for this account return it
      if (accountHomeDomainCache[accountID]) {
        return accountHomeDomainCache[accountID];
      }

      // check if a fetch for the homeDomain has been recorded
      if (accountHomeDomainFetchInProgressFor[accountID]) {
        // if so then homeDomain fetch is in progress,
        // wait for that fetch to finish

        // keep track of how many wait sleep cycles have taken place
        let waitCount = 0;

        // for as long as a homeDomain is not in the cache...
        while (accountHomeDomainCache[accountID] === undefined) {
          // wait for 500ms
          await sleep(500);

          // check if wait count exceeded
          if (waitCount > 4) {
            // exceeded, return blank homeDomain
            console.error("timeout waiting for homeDomain to be fetched");
            return "";
          }

          // wait count not exceeded so increment wait count and go again
          waitCount++;
        }

        // homeDomain is now set, return it
        return accountHomeDomainCache[accountID];
      }

      // mark fetch in progress
      accountHomeDomainFetchInProgressFor[accountID] = true;

      // load account to get home domain
      try {
        const account = await server.loadAccount(accountID);
        if (account.home_domain) {
          accountHomeDomainCache[accountID] = account.home_domain;
        } else {
          accountHomeDomainCache[accountID] = "";
        }
      } catch (e) {
        console.error(`error getting account homeDomain: ${e}`, accountID);
        accountHomeDomainCache[accountID] = "";
      }

      // mark fetch complete
      delete accountHomeDomainFetchInProgressFor[accountID];

      // and return it
      return accountHomeDomainCache[accountID];
    },
    [accountHomeDomainCache, accountHomeDomainFetchInProgressFor],
  );

  // cached domain toml file retrieval
  const { current: domainTomlFileFetchInProgressFor } = useRef<{
    [key: string]: boolean;
  }>({});
  const { current: domainTomlFileCache } = useRef<{ [key: string]: TomlFile }>(
    {},
  );
  const getDomainTomlFile: (homeDomain: string) => Promise<TomlFile> =
    useCallback(
      async (homeDomain: string) => {
        // if home domain is blank return blank toml file
        if (!homeDomain) {
          return {};
        }

        // if the tomlFile is already stored return it
        if (domainTomlFileCache[homeDomain]) {
          return domainTomlFileCache[homeDomain];
        }

        // check if a fetch for the tomlFile has been recorded for this home domain
        if (domainTomlFileFetchInProgressFor[homeDomain]) {
          // if so then tomlFile fetch is in progress, wait for that fetch to finish

          // keep track of how many wait sleep cycles have taken place
          let waitCount = 0;

          // for as long as a tomlFile is not in the cache...
          while (!domainTomlFileCache[homeDomain]) {
            // wait for 500ms
            await sleep(500);

            // check if wait count exceeded
            if (waitCount > 4) {
              // exceeded, return blank tomlFile
              console.error("timeout waiting for tomlFile to be fetched");
              return {};
            }

            // wait count not exceeded - increment wait count and go again
            waitCount++;
          }

          // tomlFile is now set, return it
          return domainTomlFileCache[homeDomain];
        }

        // mark fetch in progress
        domainTomlFileFetchInProgressFor[homeDomain] = true;

        // get toml file for home domain
        try {
          if (homeDomain) {
            domainTomlFileCache[homeDomain] =
              StellarToml.Resolver.resolve(homeDomain);
          } else {
            domainTomlFileCache[homeDomain] = {};
          }
        } catch (e) {
          console.error(`error getting account toml file: ${e}`);
          domainTomlFileCache[homeDomain] = {};
        }

        // mark fetch complete
        delete domainTomlFileFetchInProgressFor[homeDomain];

        // and return it
        return domainTomlFileCache[homeDomain];
      },
      [domainTomlFileCache, domainTomlFileFetchInProgressFor],
    );

  const stellarAccountContextPopulateModelWithLedgerDetails: (
    model: StellarAccount,
  ) => Promise<StellarAccount> = useCallback(async (model: StellarAccount) => {
    const accountWithLedgerDetails =
      await stellarAccountLedgerDetailsPopulator.PopulateAccountWithLedgerDetails(
        { account: new Account(model) },
      );

    // populate each balance with it associated balance view model
    const accountViewModelBalance: StellarAccountViewBalance[] = [];
    for (const b of accountWithLedgerDetails.account.balances) {
      try {
        const tokenViewModel = await getLedgerTokenViewModel(b.amount.token);

        // retrieve the token from the amount and update the balance with view mode
        accountViewModelBalance.push(
          new StellarAccountViewBalance({
            ...b,
            tokenViewModel,
          } as StellarAccountViewBalance),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === ErrLedgerTokenViewModelNotFound) {
          // if the error is ErrLedgerTokenViewModelNotFound skip the balance construction
          continue;
        }
        throw e;
      }
    }

    return new StellarAccount({
      ...accountWithLedgerDetails.account,
      accountOwnerGroupName: model.accountOwnerGroupName,
      accountOwnerClientName: model.accountOwnerClientName,
      balances: accountViewModelBalance,
    } as StellarAccount);
  }, []);

  const stellarContextGetTokenIconURL: (token: Token) => Promise<string> =
    useCallback(
      async (token: Token) => {
        if (token.network !== network) {
          throw new TypeError(
            `unexpected stellar network on token, wanted ${network} got ${token.network}`,
          );
        }

        // do not attempt to get token icon url for native token
        if (token.issuer === token.network) {
          return stellarLogoBlack;
        }

        try {
          // get toml file at the given token's issuance account's home domain
          const tomlFile = await getDomainTomlFile(
            await getAccountHomeDomain(token.network, token.issuer),
          );

          // look for given token among the currencies in the toml file
          if (tomlFile.CURRENCIES) {
            const currency = tomlFile.CURRENCIES.find(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (c: any) => c.code === token.code && c.issuer === token.issuer,
            );

            // if token is found then return the image prop
            if (currency) {
              return currency.image ?? "";
            }
          }
        } catch (e) {
          console.error(`error getting token icon url: ${e}`);
        }

        // if currency associated with the token is not found then return a blank icon url
        return "";
      },
      [getDomainTomlFile, getAccountHomeDomain],
    );

  const stellarContextGetTokenDescription: (token: Token) => Promise<string> =
    useCallback(
      async (token: Token) => {
        if (token.network !== network) {
          throw new TypeError(
            `unexpected stellar network on token, wanted ${network} got ${token.network}`,
          );
        }

        try {
          // get toml file at the given token's issuance account's home domain
          const tomlFile = await getDomainTomlFile(
            await getAccountHomeDomain(token.network, token.issuer),
          );

          // look for given token among the currencies in the toml file
          if (tomlFile.CURRENCIES) {
            const currency = tomlFile.CURRENCIES.find(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (c: any) => c.code === token.code && c.issuer === token.issuer,
            );

            // if token is found then return the image prop
            if (currency) {
              return currency.desc ?? "Unknown";
            }
          }
        } catch (e) {
          console.error(`error getting token description: ${e}`);
        }

        // if currency associated with the token is not found then return an unknown description
        return "Unknown";
      },
      [getDomainTomlFile, getAccountHomeDomain],
    );

  const stellarContextGetTokenIssuerInformation: (
    token: Token,
  ) => Promise<IssuerInformation> = useCallback(
    async (token: Token) => {
      if (token.network !== network) {
        throw new TypeError(
          `unexpected stellar network on token, wanted ${network} got ${token.network}`,
        );
      }

      if (token.code === NativeAssetTokenCode) {
        return {
          name: `${token.network} Network`,
          description:
            "Stellar is an open network for storing and moving money.",
          logoURL: stellarLogoWithWordsBlack,
          email: "",
          website: "https://stellar.org",
          twitter: "stellarorg",
        };
      }

      // prepare issuer information
      const issuerInformation: IssuerInformation = {
        name: "",
        description: "",
        logoURL: "",
        email: "",
        website: "",
        twitter: "",
      };

      try {
        // get toml file at the given token's issuance account's home domain
        const tomlFile = await getDomainTomlFile(
          await getAccountHomeDomain(token.network, token.issuer),
        );

        // populate issuer information from tomlFile
        issuerInformation.name = tomlFile?.DOCUMENTATION?.ORG_NAME ?? "";
        issuerInformation.description =
          tomlFile?.DOCUMENTATION?.ORG_DESCRIPTION ?? "";
        issuerInformation.logoURL = tomlFile?.DOCUMENTATION?.ORG_LOGO ?? "";
        issuerInformation.email =
          tomlFile?.DOCUMENTATION?.ORG_OFFICIAL_EMAIL ?? "";
        issuerInformation.website = tomlFile?.DOCUMENTATION?.ORG_URL ?? "";
        issuerInformation.twitter = tomlFile?.DOCUMENTATION?.ORG_TWITTER ?? "";
      } catch (e) {
        console.error(`error getting token description: ${e}`);
      }

      // if currency associated with the token is not found then return an unknown description
      return issuerInformation;
    },
    [getDomainTomlFile, getAccountHomeDomain],
  );

  const stellarContextGetTokenInformation: (
    token: Token,
  ) => Promise<TokenInformation> = useCallback(
    async (token: Token) => {
      if (token.network !== network) {
        throw new TypeError(
          `unexpected stellar network on token, wanted ${network} got ${token.network}`,
        );
      }

      if (token.code === NativeAssetTokenCode) {
        return {
          description: `Native Coin on the ${token.network} Network`,
          rating: "",
          firstTransaction: dayjs("2014-07-31T00:00:00.000+00:00"),
          totalTrades: "",
          redemptionInstructions: "",
          attestationOfReserve: "",
          conditions: "",
        };
      }

      // prepare issuer information
      const tokenInformation: TokenInformation = {
        description: "",
        rating: "",
        firstTransaction: dayjs(),
        totalTrades: "",
        redemptionInstructions: "",
        attestationOfReserve: "",
        conditions: "",
      };

      try {
        await Promise.all([
          (async () => {
            const getAssetOverviewResponse =
              await stellarExpertClient.getAssetOverview({ token });
            tokenInformation.rating = getAssetOverviewResponse?.rating?.average
              ? `${getAssetOverviewResponse?.rating?.average}`
              : "";
            tokenInformation.totalTrades = getAssetOverviewResponse.trades
              ? `${getAssetOverviewResponse.trades}`
              : "";
            tokenInformation.firstTransaction = dayjs.unix(
              getAssetOverviewResponse.created,
            );
          })(),
          (async () => {
            // get toml file at the given token's issuance account's home domain
            const tomlFile = await getDomainTomlFile(
              await getAccountHomeDomain(token.network, token.issuer),
            );

            // populate token information from tomlFile
            if (tomlFile.CURRENCIES) {
              const currency = tomlFile.CURRENCIES.find(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (c: any) => c.code === token.code && c.issuer === token.issuer,
              );

              // populate details from currency if it is found
              if (currency) {
                tokenInformation.description = currency?.desc ?? "";
                tokenInformation.redemptionInstructions =
                  currency?.redemption_instructions ?? "";
                tokenInformation.attestationOfReserve =
                  currency?.attestation_of_reserve ?? "";
                tokenInformation.conditions = currency?.conditions ?? "";
              }
            }
          })(),
        ]);
      } catch (e) {
        console.error(`error getting token information: ${e}`);
      }

      // if currency associated with the token is not found then return an unknown description
      return tokenInformation;
    },
    [getDomainTomlFile, getAccountHomeDomain],
  );

  return (
    <Context.Provider
      value={{
        stellarExpertClient,
        stellarContextSpotPricer,
        stellarContextAccountEffectsSubscriber,
        stellarContextPriceHistorian,
        stellarAccountLedgerDetailsPopulator,
        stellarAccountSignatoriesFetcher,
        stellarContextGetTokenIconURL,
        stellarContextGetTokenDescription,
        stellarAccountContextPopulateModelWithLedgerDetails,
        stellarContextGetTokenIssuerInformation,
        stellarContextGetTokenInformation,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useStellarContext = () => useContext(Context);
export { useStellarContext };
