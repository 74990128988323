/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: financial/rateResetReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_rateReset_pb = require('../financial/rateReset_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var num_decimal_pb = require('../num/decimal_pb.js')

var financial_rateResetReader_meshproto_pb = require('../financial/rateResetReader_meshproto_pb.js')
const proto = {};
proto.financial = require('./rateResetReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateResetReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateResetReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneRateResetRequest,
 *   !proto.financial.ReadOneRateResetResponse>}
 */
const methodDescriptor_RateResetReaderUNSCOPED_ReadOneRateResetUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.RateResetReaderUNSCOPED/ReadOneRateResetUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_rateResetReader_meshproto_pb.ReadOneRateResetRequest,
  financial_rateResetReader_meshproto_pb.ReadOneRateResetResponse,
  /**
   * @param {!proto.financial.ReadOneRateResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_rateResetReader_meshproto_pb.ReadOneRateResetResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneRateResetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneRateResetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateResetReaderUNSCOPEDClient.prototype.readOneRateResetUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateResetReaderUNSCOPED/ReadOneRateResetUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetReaderUNSCOPED_ReadOneRateResetUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadOneRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneRateResetResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateResetReaderUNSCOPEDPromiseClient.prototype.readOneRateResetUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateResetReaderUNSCOPED/ReadOneRateResetUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetReaderUNSCOPED_ReadOneRateResetUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyRateResetRequest,
 *   !proto.financial.ReadManyRateResetResponse>}
 */
const methodDescriptor_RateResetReaderUNSCOPED_ReadManyRateResetUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.RateResetReaderUNSCOPED/ReadManyRateResetUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_rateResetReader_meshproto_pb.ReadManyRateResetRequest,
  financial_rateResetReader_meshproto_pb.ReadManyRateResetResponse,
  /**
   * @param {!proto.financial.ReadManyRateResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_rateResetReader_meshproto_pb.ReadManyRateResetResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyRateResetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyRateResetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateResetReaderUNSCOPEDClient.prototype.readManyRateResetUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateResetReaderUNSCOPED/ReadManyRateResetUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetReaderUNSCOPED_ReadManyRateResetUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadManyRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyRateResetResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateResetReaderUNSCOPEDPromiseClient.prototype.readManyRateResetUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateResetReaderUNSCOPED/ReadManyRateResetUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetReaderUNSCOPED_ReadManyRateResetUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllRateResetRequest,
 *   !proto.financial.ReadAllRateResetResponse>}
 */
const methodDescriptor_RateResetReaderUNSCOPED_ReadAllRateResetUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.RateResetReaderUNSCOPED/ReadAllRateResetUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_rateResetReader_meshproto_pb.ReadAllRateResetRequest,
  financial_rateResetReader_meshproto_pb.ReadAllRateResetResponse,
  /**
   * @param {!proto.financial.ReadAllRateResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_rateResetReader_meshproto_pb.ReadAllRateResetResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllRateResetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllRateResetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateResetReaderUNSCOPEDClient.prototype.readAllRateResetUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateResetReaderUNSCOPED/ReadAllRateResetUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetReaderUNSCOPED_ReadAllRateResetUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadAllRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllRateResetResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateResetReaderUNSCOPEDPromiseClient.prototype.readAllRateResetUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateResetReaderUNSCOPED/ReadAllRateResetUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateResetReaderUNSCOPED_ReadAllRateResetUNSCOPED);
};


module.exports = proto.financial;

