import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { NewSorting, Query } from "james/search/query";
import { BPTable } from "components/Table";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DateTimeFormat } from "const/dateformats";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  DeleteForever as ClearFiltersIcon,
  Error as ErrorIcon,
  FileCopy as CopyPasteIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
  RemoveRedEye as ViewTransactionIcon,
} from "@mui/icons-material";
import { TransactionStateChip } from "./Chips";
import {
  DateRangeCriterion,
  DateRangeValue,
} from "james/search/criterion/date/Range";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { useIsMounted } from "hooks";
import { dateIsValid } from "utilities/date/dateIsValid";
import { DateField } from "components/FormFields";
import { useSnackbar } from "notistack";
import {
  AllTransactionStates,
  TransactionState,
  TransactionSubmissionResolver,
  useSearchTransactions,
} from "james/ledger";
import { Transaction } from "james/ledger/Transaction";
import { StellarNetwork, StellarTransaction } from "james/stellar";
import { Popover } from "components/PopOver/Popover";
import ReactJson from "react-json-view";
import { TransactionFailureReason } from "james/ledger/TransactionInspector";
import { useApplicationContext } from "context/Application/Application";
import { TransactionFailureAnalyser } from "james/ledger/TransactionFailureAnalyser";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export type TransactionTableProps = {
  title?: string | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constantCriteria?: any;
  height?: number;
  collapsible?: boolean;
  initialCollapsedState?: boolean;
  divTable?: boolean;
  initialDenseTable?: boolean;
};

export const TransactionTable = (props: TransactionTableProps) => {
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { viewConfiguration, authContext } = useApplicationContext();
  const [apiLoading, setAPILoading] = useState(false);
  const constantCriteria = useMemo(
    () => (props.constantCriteria ? props.constantCriteria : {}),
    [props.constantCriteria],
  );
  const {
    setSearchTransactionsRequest,
    searchTransactionsRequest,
    searchTransactionsResponse,
    loading,
  } = useSearchTransactions({
    context: authContext,
    criteria: constantCriteria,
    query: initialQuery,
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  const { errorContextErrorTranslator } = useErrorContext();

  // table criteria
  const [textSearchCriterion, setTextSearchCriterion] = useState("");
  const [transactionStatusesForCriterion, setTransactionStatusesForCriterion] =
    useState<TransactionState[]>([]);
  const [
    transactionDateTimeCriterionFrom,
    setTransactionDateTimeCriterionFrom,
  ] = useState<DateRangeValue | undefined>(undefined);
  const [transactionDateTimeCriterionTo, setTransactionDateTimeCriterionTo] =
    useState<DateRangeValue | undefined>(undefined);
  useEffect(() => {
    // prepare new initial criteria
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = { ...constantCriteria };

    if (transactionStatusesForCriterion.length) {
      criteria.state = TextListCriterion(transactionStatusesForCriterion);
    }

    if (transactionDateTimeCriterionFrom || transactionDateTimeCriterionTo) {
      criteria["auditEntry.time"] = DateRangeCriterion(
        transactionDateTimeCriterionFrom,
        transactionDateTimeCriterionTo,
      );
    }

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        $or: [
          { id: TextSubstringCriterion(textSearchCriterion) },
          {
            "metaData.description": TextSubstringCriterion(textSearchCriterion),
          },
          { "metaData.type": TextSubstringCriterion(textSearchCriterion) },
        ],
      };
    }

    if (isMounted()) {
      setSearchTransactionsRequest({
        context: searchTransactionsRequest.context,
        query: searchTransactionsRequest.query,
        criteria,
      });
    }
  }, [
    textSearchCriterion,
    transactionStatusesForCriterion,
    transactionDateTimeCriterionFrom,
    transactionDateTimeCriterionTo,
  ]);

  const [selectedTransaction, setSelectedTransaction] = useState<
    Transaction | undefined
  >(undefined);
  const handleResolveState = async () => {
    if (!selectedTransaction) {
      console.error("selected ledger transaction view model not set");
      return;
    }

    setAPILoading(true);
    try {
      await TransactionSubmissionResolver.ResolveTransactionIDSubmission({
        context: authContext,
        transactionID: selectedTransaction.transactionID(),
        ignoreConfirmationCount: true,
      });
      setSelectedTransaction(undefined);
      setSearchTransactionsRequest({ ...searchTransactionsRequest });
      enqueueSnackbar("Transaction State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving transaction state`, e);
      enqueueSnackbar(`error resolving transaction state: ${err.message}`, {
        variant: "error",
      });
    }
    setAPILoading(false);
  };

  return (
    <BPTable
      collapsible={props.collapsible}
      initialDenseTable={props.initialDenseTable}
      initialCollapsedState={props.initialCollapsedState}
      divTable={props.divTable}
      loading={loading || apiLoading}
      height={
        props.height
          ? props.height - noticeBannerHeight
          : 100 - noticeBannerHeight
      }
      singleSelect
      onSingleSelectChange={(data) =>
        setSelectedTransaction(data as Transaction)
      }
      title={props.title ? props.title : "Transactions"}
      query={searchTransactionsRequest.query}
      onQueryChange={(query) =>
        setSearchTransactionsRequest({
          ...searchTransactionsRequest,
          query,
        })
      }
      data={searchTransactionsResponse.records}
      totalNoRecords={searchTransactionsResponse.total}
      filters={(() => {
        const filters: React.ReactNode[] = [
          <TextField
            id={"ledgerTransactionTable-textFieldSearch-textField"}
            variant="outlined"
            margin="dense"
            sx={{ width: 400 }}
            label="Search Text Fields"
            placeholder="Start Typing..."
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterion ? (
                <InputAdornment
                  position="end"
                  children={
                    <IconButton
                      size="small"
                      onClick={() => setTextSearchCriterion("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterion}
            onChange={(e) => setTextSearchCriterion(e.target.value)}
          />,
          <DateField
            label={"From"}
            disabled={loading}
            id={
              "ledgerTransactionTable-transactionDateTimeFromFilter-dateField"
            }
            value={
              transactionDateTimeCriterionFrom
                ? transactionDateTimeCriterionFrom.date
                : null
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setTransactionDateTimeCriterionFrom(undefined);
              } else {
                setTransactionDateTimeCriterionFrom(
                  newValue
                    ? {
                        date: newValue.startOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                id={
                  "ledgerTransactionTable-transactionDateTimeFromFilter-dateFieldTextField"
                }
                sx={{ width: 160 }}
                variant={"outlined"}
                margin={"dense"}
              />
            )}
          />,
          <DateField
            label={"To"}
            disabled={loading}
            id={"ledgerTransactionTable-transactionDateTimeToFilter-dateField"}
            value={
              transactionDateTimeCriterionTo
                ? transactionDateTimeCriterionTo.date
                : null
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setTransactionDateTimeCriterionTo(undefined);
              } else {
                setTransactionDateTimeCriterionTo(
                  newValue
                    ? {
                        date: newValue.endOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                id={
                  "ledgerTransactionTable-transactionDateTimeToFilter-dateFieldTextField"
                }
                sx={{ width: 160 }}
                variant={"outlined"}
                margin={"dense"}
              />
            )}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"ledgerTransactionTable-stateFilter-autocomplete"}
            disabled={loading}
            multiple
            options={AllTransactionStates}
            filterSelectedOptions
            onChange={(_, value: TransactionState[]) =>
              setTransactionStatusesForCriterion(value)
            }
            value={transactionStatusesForCriterion}
            renderTags={(transactionStates: TransactionState[]) =>
              transactionStates.map((s, idx) => (
                <Box sx={{ padding: "4px" }}>
                  <TransactionStateChip
                    key={idx}
                    chipProps={{
                      onDelete: () =>
                        setTransactionStatusesForCriterion((prev) =>
                          prev.filter((prevState) => prevState !== s),
                        ),
                      deleteIcon: (
                        <CancelIcon
                          sx={(theme) => ({
                            color: `${theme.palette.text.secondary} !important`,
                            "&:hover": {
                              color: `${theme.palette.secondary.contrastText} !important`,
                            },
                          })}
                        />
                      ),
                    }}
                    state={s}
                  />
                </Box>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id={"ledgerTransactionTable-stateFilter-autocompleteTextField"}
                sx={{ width: 317 }}
                label={"State"}
                variant={"outlined"}
                margin={"dense"}
                InputLabelProps={{ shrink: true }}
                placeholder={
                  transactionStatusesForCriterion.length
                    ? undefined
                    : "Select..."
                }
              />
            )}
          />,
        ];

        // if any criteria is set then show a clear all filters button
        if (
          transactionStatusesForCriterion.length ||
          transactionDateTimeCriterionFrom ||
          transactionDateTimeCriterionTo ||
          textSearchCriterion
        ) {
          filters.push(
            <Button
              sx={{ marginTop: "10px" }}
              id={"ledgerTransactionTable-clearAllFilters-button"}
              variant={"contained"}
              color={"secondary"}
              children={"clear all"}
              onClick={() => {
                setTransactionStatusesForCriterion([]);
                setTransactionDateTimeCriterionFrom(undefined);
                setTransactionDateTimeCriterionTo(undefined);
                setTextSearchCriterion("");
              }}
              startIcon={<ClearFiltersIcon />}
            />,
          );
        }

        return filters;
      })()}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        if (
          viewConfiguration?.Ledger?.Transaction?.Resolve &&
          selectedTransaction
        ) {
          controls.push(
            <Button
              variant={"outlined"}
              id={"ledgerTransactionTable-resolveState-button"}
              children={"Resolve State"}
              onClick={handleResolveState}
            />,
          );
        }

        controls.push(
          <IconButton
            id={"ledgerTransactionTable-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={() => {
              setSearchTransactionsRequest({ ...searchTransactionsRequest });
            }}
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      columns={[
        {
          label: "ID",
          field: "id",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const txn = data as Transaction;
            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                <Typography
                  sx={{
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  variant="body1"
                  children={txn.transactionID()}
                />
                <CopyPasteIcon
                  sx={(theme) => ({
                    fontSize: 20,
                    color: theme.palette.action.disabled,
                    "&:hover": {
                      color: theme.palette.action.active,
                    },
                    cursor: "pointer",
                  })}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(txn.transactionID())
                      .then(() => enqueueSnackbar("Source Account ID copied"))
                  }
                />
              </Box>
            );
          },
        },
        {
          label: "Description",
          field: "metaData.description",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            return (data as Transaction).transactionDescription();
          },
        },
        {
          label: "Last Modified",
          field: "auditEntry.time",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) =>
            dayjs((data as Transaction).transactionAuditEntry().time).format(
              DateTimeFormat,
            ),
        },
        {
          label: "State",
          field: "state",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const txn = data as Transaction;
            if (txn.transactionState() === TransactionState.Failed) {
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(0.5),
                  })}
                >
                  <TransactionStateChip
                    state={(data as Transaction).transactionState()}
                  />
                  <Popover
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    popOverComponent={
                      <TransactionFailureReasons transaction={txn} />
                    }
                  >
                    <Tooltip
                      title="Select to view failure reasons"
                      placement="top"
                    >
                      <ErrorIcon
                        sx={{
                          color: "secondary.light",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Popover>
                </Box>
              );
            } else {
              return (
                <TransactionStateChip
                  state={(data as Transaction).transactionState()}
                />
              );
            }
          },
        },
        {
          label: "Resolutions",
          field: "stateResolutionCount",
        },
        {
          label: "Network",
          field: "@type",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            return (data as Transaction).transactionNetwork();
          },
        },
        {
          field: "",
          label: "",
          minWidth: 40,
          sortable: false,
          accessor: (data) => {
            const controls: React.ReactNode[] = [];
            const txn = data as Transaction;

            // add stellar specific controls
            if (txn instanceof StellarTransaction) {
              // if ledger ID is set then transaction add button for transaction to be viewed in stellar expert
              if (txn.ledgerID) {
                controls.push(
                  <Tooltip
                    placement="top"
                    title="View Transaction in Stellar Expert"
                  >
                    <IconButton
                      size="small"
                      id={`ledgerTransactionTable-${(
                        data as Transaction
                      ).transactionID()}-openTransactionInStellarExpert-iconButton`}
                      onClick={() =>
                        window.open(
                          `https://stellar.expert/explorer/${
                            txn.network === StellarNetwork.TestSDFNetwork
                              ? "testnet"
                              : "public"
                          }/tx/${txn.ledgerID}`,
                          "_blank",
                        )
                      }
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>,
                );
              }

              // add button to copy a URL for transaction data to be viewed in stellar lab
              const labURL = `https://laboratory.stellar.org/#xdr-viewer?input=${encodeURIComponent(
                txn.data,
              )}&type=TransactionEnvelope&network=${
                txn.network === StellarNetwork.TestSDFNetwork
                  ? "test"
                  : "public"
              }`;
              controls.push(
                <Tooltip
                  placement="top"
                  title="Copy URL to view txn data in stellar laboratory (2nd paste-n-go usually works)"
                >
                  <CopyPasteIcon
                    sx={(theme) => ({
                      color: theme.palette.action.disabled,
                      "&:hover": {
                        color: theme.palette.action.active,
                      },
                      cursor: "pointer",
                    })}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(labURL)
                        .then(() => enqueueSnackbar("URL copied"))
                    }
                  />
                </Tooltip>,
              );
            }

            controls.push(
              <Popover
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                popOverComponent={
                  <Card style={{ backgroundColor: "#1d1f21" }}>
                    <CardContent>
                      <ReactJson
                        name={false}
                        enableClipboard={(e) =>
                          navigator.clipboard
                            .writeText(JSON.stringify(e))
                            .then(() => enqueueSnackbar("copied"))
                        }
                        src={txn}
                        theme="google"
                      />
                    </CardContent>
                  </Card>
                }
              >
                <Tooltip
                  title="Select to view transaction in pop-up"
                  placement="top"
                >
                  <ViewTransactionIcon
                    sx={(theme) => ({
                      color: theme.palette.action.disabled,
                      "&:hover": {
                        color: theme.palette.action.active,
                      },
                      cursor: "pointer",
                    })}
                  />
                </Tooltip>
              </Popover>,
            );

            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                {controls.map((c, idx) => (
                  <React.Fragment key={idx}>{c}</React.Fragment>
                ))}
              </Box>
            );
          },
        },
      ]}
    />
  );
};

type TransactionFailureReasonsProps = {
  transaction: Transaction;
};

const TransactionFailureReasons = (props: TransactionFailureReasonsProps) => {
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [failureReasons, setFailureReasons] = useState<
    TransactionFailureReason[]
  >([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const failureReasons = (
          await TransactionFailureAnalyser.AnalyseFailedTransaction({
            context: authContext,
            transaction: props.transaction,
          })
        ).failureReasons;
        if (isMounted()) {
          setFailureReasons(failureReasons);
        }
      } catch (e) {
        if (isMounted()) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error getting transaction failure reasons: ${
              err.message ? err.message : err.toString()
            }`,
          );
          setError(err.message ? err.message : err.toString());
        }
      }
      if (isMounted()) {
        setLoading(false);
      }
    })();
  }, [props.transaction]);

  if (loading) {
    return (
      <Card style={{ backgroundColor: "#1d1f21" }}>
        <CardContent>
          <CircularProgress size={50} />
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card style={{ backgroundColor: "#1d1f21" }}>
        <CardContent>
          <Typography color={"error"} children={error} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card style={{ backgroundColor: "#1d1f21" }}>
      <CardContent>
        <ul>
          {failureReasons.map((fr, idx) => (
            <li key={idx}>{`${fr.code} - ${fr.description}`}</li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};
