import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Repository,
  useClientRepositorySearchClients,
} from "james/client/Repository";
import { Query } from "james/search/query";
import { BPTable } from "components/Table";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { Client, ClientKYCStatus } from "james/client";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion/text";
import { ClientKind } from "james/client/Client";
import { useApplicationContext } from "context/Application/Application";
import dayjs from "dayjs";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { Dashboard } from "../Dashboard/Dashboard";
import { DateField } from "components/FormFields";
import { DateRangeCriterion } from "james/search/criterion";
import { useSearchParams } from "react-router-dom";
import { useErrorContext } from "../../../../context/Error";
import { IDIdentifier } from "../../../../james/search/identifier";
import Tooltip from "@mui/material/Tooltip";

const PREFIX = "ClientTable";

const classes = {
  filterField: `${PREFIX}-filterField`,
};

const Root = styled("div")(() => ({
  [`& .${classes.filterField}`]: {
    minWidth: 180,
  },
}));

const initialQuery = new Query({
  limit: 10,
  offset: 0,
  sorting: [],
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let retrieveClientTimeout: any;

export function Table() {
  const { authContext } = useApplicationContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedClient, setSelectedClient] = useState<Client>();
  const { errorContextErrorTranslator } = useErrorContext();
  const [registrationDateTo, setRegistrationDateTo] = useState<
    string | undefined
  >(undefined);
  const [registrationDateFrom, setRegistrationDateFrom] = useState<
    string | undefined
  >(undefined);
  const [kycDateTo, setKYCDateTo] = useState<string | undefined>(undefined);
  const [kycDateFrom, setKYCDateFrom] = useState<string | undefined>(undefined);
  const {
    searchClientsResponse,
    searchClientsRequest,
    setSearchClientsRequest,
    loading: tableLoading,
  } = useClientRepositorySearchClients({
    context: authContext,
    query: new Query(initialQuery),
    criteria: {
      kycStatus: TextListCriterion([
        ClientKYCStatus.VerificationInProgressStatus,
      ]),
      clientKind: TextListCriterion([
        ClientKind.IndividualType,
        ClientKind.CompanyType,
      ]),
    },
  });

  useEffect(() => {
    if (searchParams.has("clientID")) {
      clearTimeout(retrieveClientTimeout);
      retrieveClientTimeout = setTimeout(async () => {
        try {
          setSelectedClient(
            (
              await Repository.RetrieveClient({
                context: authContext,
                identifier: IDIdentifier(
                  searchParams.get("clientID") as string,
                ),
              })
            ).client,
          );
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error retrieving client ${searchParams.get("clientID")}: ${
              err.message ? err.message : err.toString()
            }`,
          );
        }
      }, 400);
    }
    return setSelectedClient(undefined);
  }, [searchParams]);

  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  return (
    <Root>
      <BPTable
        loading={tableLoading}
        height={window.innerHeight - 138 - noticeBannerHeight}
        title="Clients"
        singleSelect
        onSingleSelectChange={(data) => {
          if (data) {
            searchParams.set("clientID", data.id);
            setSearchParams(searchParams);
          } else {
            setSelectedClient(undefined);
          }
        }}
        data={searchClientsResponse.records}
        columns={[
          {
            label: "Name",
            field: "name",
            accessor: (data) => (
              <Typography
                variant="body1"
                id={`clientNameColumnCell-${(data as Client).id}`}
                children={(data as Client).name}
              />
            ),
          },
          {
            label: "Kind",
            field: "clientKind",
          },
          {
            label: "KYC Status",
            field: "kycStatus",
          },
          {
            label: "Registration Date",
            field: "registrationDate",
            accessor: (data) => (
              <Typography
                variant="body1"
                id={`registrationDateColumnCell-${(data as Client).id}`}
                children={dayjs((data as Client).registrationDate).format(
                  "YYYY-MM-DD, HH:mm A",
                )}
              />
            ),
          },
          {
            label: "KYC Date",
            field: "kycVerificationDate",
            accessor: (data) => (
              <Typography
                variant="body1"
                id={`kycVerificationDateColumnCell-${(data as Client).id}`}
                children={
                  (data as Client).kycStatus ===
                    ClientKYCStatus.VerifiedStatus &&
                  dayjs((data as Client).kycVerificationDate).format(
                    "YYYY-MM-DD, HH:mm A",
                  )
                }
              />
            ),
          },
        ]}
        query={searchClientsRequest.query}
        onQueryChange={(query: Query) =>
          setSearchClientsRequest({
            ...searchClientsRequest,
            query,
          })
        }
        totalNoRecords={searchClientsResponse.total}
        toolBarControls={(() => {
          if (selectedClient) {
            return [];
          }
          return [
            <Tooltip title="Reload">
              <span>
                <IconButton
                  id="clientTable-reload-button"
                  disabled={tableLoading}
                  size="small"
                  onClick={() =>
                    setSearchClientsRequest({
                      ...searchClientsRequest,
                      query: new Query(initialQuery),
                    })
                  }
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          ];
        })()}
        filters={[
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id="clientTable-kycStatus-autocomplete"
            disabled={tableLoading}
            multiple
            options={[
              ClientKYCStatus.VerifiedStatus,
              ClientKYCStatus.VerificationInProgressStatus,
              ClientKYCStatus.Outstanding,
            ]}
            defaultValue={[ClientKYCStatus.VerificationInProgressStatus]}
            filterSelectedOptions
            onChange={(
              _: React.SyntheticEvent<Element, Event>,
              value: ClientKYCStatus[],
            ) => {
              if (value.length) {
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    kycStatus: TextListCriterion(value),
                  },
                  query: new Query(initialQuery),
                });
              } else {
                delete searchClientsRequest.criteria.kycStatus;
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  query: new Query(initialQuery),
                });
              }
            }}
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.filterField}
                label="KYC Status"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder="Start Typing..."
              />
            )}
          />,
          <TextField
            id="clientTable-name-textfield"
            label="Name"
            variant="outlined"
            margin="dense"
            onChange={(e) => {
              if (e.target.value !== "") {
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    name: TextSubstringCriterion(e.target.value),
                  },
                  query: new Query(initialQuery),
                });
              } else {
                delete searchClientsRequest.criteria.name;
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  query: new Query(initialQuery),
                });
              }
            }}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id="clientTable-kind-autocomplete"
            disabled={tableLoading}
            multiple
            options={[ClientKind.CompanyType, ClientKind.IndividualType]}
            defaultValue={[ClientKind.IndividualType, ClientKind.CompanyType]}
            filterSelectedOptions
            onChange={(
              _: React.SyntheticEvent<Element, Event>,
              value: ClientKind[],
            ) => {
              if (value.length) {
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    clientKind: TextListCriterion(value),
                  },
                  query: new Query(initialQuery),
                });
              } else {
                delete searchClientsRequest.criteria.clientKind;
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  query: new Query(initialQuery),
                });
              }
            }}
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.filterField}
                label="Kind"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder="Start Typing..."
              />
            )}
          />,
          <TextField
            id="clientTable-id-textfield"
            label="Client ID"
            variant="outlined"
            margin="dense"
            onChange={(e) => {
              if (e.target.value !== "") {
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    id: TextSubstringCriterion(e.target.value),
                  },
                  query: new Query(initialQuery),
                });
              } else {
                delete searchClientsRequest.criteria.id;
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  query: new Query(initialQuery),
                });
              }
            }}
          />,
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr)",
              gap: (theme) => theme.spacing(2),
              gridGap: (theme) => theme.spacing(2),
            }}
          >
            <DateField
              label="Registration Date From"
              nullable={true}
              id={"clientsTable-regDateFromFilter-formfield"}
              disabled={tableLoading}
              value={registrationDateFrom ? dayjs(registrationDateFrom) : null}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(newValue) => {
                setRegistrationDateFrom(
                  newValue ? newValue.format() : undefined,
                );
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    registrationDate: DateRangeCriterion(
                      {
                        date: newValue
                          ? newValue.startOf("day").format()
                          : dayjs(0).startOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      },
                      registrationDateTo
                        ? {
                            date: dayjs(registrationDateTo)
                              .endOf("day")
                              .format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                    ),
                  },
                });
              }}
            />
            <DateField
              label="Registration Date To"
              nullable={true}
              id={"clientsTable-regDateToFilter-formfield"}
              value={registrationDateTo ? dayjs(registrationDateTo) : null}
              disabled={tableLoading}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(newValue) => {
                setRegistrationDateTo(newValue ? newValue.format() : undefined);
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    registrationDate: DateRangeCriterion(
                      registrationDateFrom
                        ? {
                            date: dayjs(registrationDateFrom)
                              .startOf("day")
                              .format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                      newValue
                        ? {
                            date: newValue.endOf("day").format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                    ),
                  },
                });
              }}
            />
          </Box>,
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr)",
              gap: (theme) => theme.spacing(2),
              gridGap: (theme) => theme.spacing(2),
            }}
          >
            <DateField
              label="KYC'd Date From"
              id={"clientsTable-kycDateFromFilter-formfield"}
              disabled={tableLoading}
              nullable={true}
              value={kycDateFrom ? dayjs(kycDateFrom) : null}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(newValue) => {
                setKYCDateFrom(newValue ? newValue.format() : undefined);
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    kycStatus: TextListCriterion([
                      ClientKYCStatus.VerifiedStatus,
                    ]),
                    kycVerificationDate: DateRangeCriterion(
                      {
                        date: newValue
                          ? newValue.startOf("day").format()
                          : dayjs(0).startOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      },
                      kycDateTo
                        ? {
                            date: dayjs(kycDateTo).endOf("day").format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                    ),
                  },
                });
              }}
            />
            <DateField
              label="KYC'd Date To"
              id={"clientsTable-kycDateToFilter-formfield"}
              value={kycDateTo ? dayjs(kycDateTo) : null}
              nullable={true}
              disabled={tableLoading}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  margin: "dense",
                  InputLabelProps: { shrink: true },
                },
              }}
              onChange={(newValue) => {
                setKYCDateTo(newValue ? newValue.format() : undefined);
                setSearchClientsRequest({
                  ...searchClientsRequest,
                  criteria: {
                    ...searchClientsRequest.criteria,
                    kycStatus: TextListCriterion([
                      ClientKYCStatus.VerifiedStatus,
                    ]),
                    kycVerificationDate: DateRangeCriterion(
                      kycDateFrom
                        ? {
                            date: dayjs(kycDateFrom as string)
                              .startOf("day")
                              .format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                      newValue
                        ? {
                            date: newValue.endOf("day").format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                    ),
                  },
                });
              }}
            />
          </Box>,
        ]}
      />
      {selectedClient && (
        <Dashboard
          open={true}
          closeDialog={setSearchParams}
          client={selectedClient}
          refresh={() =>
            setSearchClientsRequest({
              ...searchClientsRequest,
            })
          }
        />
      )}
    </Root>
  );
}
