import { Box } from "@mui/material";
import { SecuritiesRegisterOverview } from "./components/SecuritiesRegisterOverview";
import { SecuritiesRegisterTable } from "./components/SecuritiesRegisterTable";

export const SecuritiesRegister = () => {
  return (
    <Box>
      <SecuritiesRegisterOverview />
      <SecuritiesRegisterTable />
    </Box>
  );
};
