import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { NewSorting, Query } from "james/search/query";
import { BPTable } from "components/Table";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DateTimeFormat } from "const/dateformats";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  DeleteForever as ClearFiltersIcon,
  FileCopy as CopyPasteIcon,
  Refresh as ReloadIcon,
  QueryStats as MetaDataIcon,
} from "@mui/icons-material";
import { TransactionBatchStateChip } from "./Chips";
import {
  DateRangeCriterion,
  DateRangeValue,
} from "james/search/criterion/date/Range";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { useIsMounted } from "hooks";
import { dateIsValid } from "utilities/date/dateIsValid";
import { DateField } from "components/FormFields";
import { useSnackbar } from "notistack";
import {
  AllTransactionBatchStates,
  TransactionBatchState,
  useSearchTransactionBatches,
  TransactionBatch,
} from "james/ledger";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { Popover } from "components/PopOver/Popover";
import ReactJson from "react-json-view";

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export type TransactionBatchTableProps = {
  title?: string | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constantCriteria?: any;
  height?: number;
  collapsible?: boolean;
  initialCollapsedState?: boolean;
  divTable?: boolean;
  initialDenseTable?: boolean;
};

export const TransactionBatchTable = (props: TransactionBatchTableProps) => {
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const constantCriteria = useMemo(
    () => (props.constantCriteria ? props.constantCriteria : {}),
    [props.constantCriteria],
  );
  const {
    setSearchTransactionBatchesRequest,
    searchTransactionBatchesRequest,
    searchTransactionBatchesResponse,
    loading,
  } = useSearchTransactionBatches({
    context: authContext,
    criteria: constantCriteria,
    query: initialQuery,
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  // table criteria
  const [textSearchCriterion, setTextSearchCriterion] = useState("");
  const [
    transactionbatchestatusesForCriterion,
    setTransactionBatchestatusesForCriterion,
  ] = useState<TransactionBatchState[]>([]);
  const [
    transactionbatchDateTimeCriterionFrom,
    setTransactionBatchDateTimeCriterionFrom,
  ] = useState<DateRangeValue | undefined>(undefined);
  const [
    transactionbatchDateTimeCriterionTo,
    setTransactionBatchDateTimeCriterionTo,
  ] = useState<DateRangeValue | undefined>(undefined);
  useEffect(() => {
    // prepare new initial criteria
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = { ...constantCriteria };

    if (transactionbatchestatusesForCriterion.length) {
      criteria.state = TextListCriterion(transactionbatchestatusesForCriterion);
    }

    if (
      transactionbatchDateTimeCriterionFrom ||
      transactionbatchDateTimeCriterionTo
    ) {
      criteria["auditEntry.time"] = DateRangeCriterion(
        transactionbatchDateTimeCriterionFrom,
        transactionbatchDateTimeCriterionTo,
      );
    }

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        $or: [{ id: TextSubstringCriterion(textSearchCriterion) }],
      };
    }

    if (isMounted()) {
      setSearchTransactionBatchesRequest({
        context: searchTransactionBatchesRequest.context,
        query: searchTransactionBatchesRequest.query,
        criteria,
      });
    }
  }, [
    textSearchCriterion,
    transactionbatchestatusesForCriterion,
    transactionbatchDateTimeCriterionFrom,
    transactionbatchDateTimeCriterionTo,
  ]);

  return (
    <BPTable
      collapsible={props.collapsible}
      initialDenseTable={props.initialDenseTable}
      initialCollapsedState={props.initialCollapsedState}
      divTable={props.divTable}
      loading={loading}
      height={
        props.height
          ? props.height - noticeBannerHeight
          : 100 - noticeBannerHeight
      }
      singleSelect
      title={props.title ? props.title : "Transaction Batches"}
      query={searchTransactionBatchesRequest.query}
      onQueryChange={(query) =>
        setSearchTransactionBatchesRequest({
          ...searchTransactionBatchesRequest,
          query,
        })
      }
      data={searchTransactionBatchesResponse.records}
      totalNoRecords={searchTransactionBatchesResponse.total}
      filters={(() => {
        const filters: React.ReactNode[] = [
          <TextField
            id={"ledgerTransactionBatchTable-textFieldSearch-textField"}
            variant="outlined"
            margin="dense"
            sx={{ width: 400 }}
            label="Search Text Fields"
            placeholder="Start Typing..."
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterion ? (
                <InputAdornment
                  position="end"
                  children={
                    <IconButton
                      size="small"
                      onClick={() => setTextSearchCriterion("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterion}
            onChange={(e) => setTextSearchCriterion(e.target.value)}
          />,
          <DateField
            label={"From"}
            disabled={loading}
            id={
              "ledgerTransactionBatchTable-transactionbatchDateTimeFromFilter-dateField"
            }
            value={
              transactionbatchDateTimeCriterionFrom
                ? transactionbatchDateTimeCriterionFrom.date
                : null
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setTransactionBatchDateTimeCriterionFrom(undefined);
              } else {
                setTransactionBatchDateTimeCriterionFrom(
                  newValue
                    ? {
                        date: newValue.startOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                id={
                  "ledgerTransactionBatchTable-transactionbatchDateTimeFromFilter-dateFieldTextField"
                }
                sx={{ width: 160 }}
                variant={"outlined"}
                margin={"dense"}
              />
            )}
          />,
          <DateField
            label={"To"}
            disabled={loading}
            id={
              "ledgerTransactionBatchTable-transactionbatchDateTimeToFilter-dateField"
            }
            value={
              transactionbatchDateTimeCriterionTo
                ? transactionbatchDateTimeCriterionTo.date
                : null
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setTransactionBatchDateTimeCriterionTo(undefined);
              } else {
                setTransactionBatchDateTimeCriterionTo(
                  newValue
                    ? {
                        date: newValue.endOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                id={
                  "ledgerTransactionBatchTable-transactionbatchDateTimeToFilter-dateFieldTextField"
                }
                sx={{ width: 160 }}
                variant={"outlined"}
                margin={"dense"}
              />
            )}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"ledgerTransactionBatchTable-stateFilter-autocomplete"}
            disabled={loading}
            multiple
            options={AllTransactionBatchStates}
            filterSelectedOptions
            onChange={(_, value: TransactionBatchState[]) =>
              setTransactionBatchestatusesForCriterion(value)
            }
            value={transactionbatchestatusesForCriterion}
            renderTags={(transactionbatchStates: TransactionBatchState[]) =>
              transactionbatchStates.map((s, idx) => (
                <Box sx={{ padding: "4px" }}>
                  <TransactionBatchStateChip
                    key={idx}
                    chipProps={{
                      onDelete: () =>
                        setTransactionBatchestatusesForCriterion((prev) =>
                          prev.filter((prevState) => prevState !== s),
                        ),
                      deleteIcon: (
                        <CancelIcon
                          sx={(theme) => ({
                            color: `${theme.palette.text.secondary} !important`,
                            "&:hover": {
                              color: `${theme.palette.secondary.contrastText} !important`,
                            },
                          })}
                        />
                      ),
                    }}
                    state={s}
                  />
                </Box>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id={
                  "ledgerTransactionBatchTable-stateFilter-autocompleteTextField"
                }
                sx={{ width: 317 }}
                label={"State"}
                variant={"outlined"}
                margin={"dense"}
                InputLabelProps={{ shrink: true }}
                placeholder={
                  transactionbatchestatusesForCriterion.length
                    ? undefined
                    : "Select..."
                }
              />
            )}
          />,
        ];

        // if any criteria is set then show a clear all filters button
        if (
          transactionbatchestatusesForCriterion.length ||
          transactionbatchDateTimeCriterionFrom ||
          transactionbatchDateTimeCriterionTo ||
          textSearchCriterion
        ) {
          filters.push(
            <Button
              sx={{ marginTop: "10px" }}
              id={"ledgerTransactionBatchTable-clearAllFilters-button"}
              variant={"contained"}
              color={"secondary"}
              children={"clear all"}
              onClick={() => {
                setTransactionBatchestatusesForCriterion([]);
                setTransactionBatchDateTimeCriterionFrom(undefined);
                setTransactionBatchDateTimeCriterionTo(undefined);
                setTextSearchCriterion("");
              }}
              startIcon={<ClearFiltersIcon />}
            />,
          );
        }

        return filters;
      })()}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        controls.push(
          <IconButton
            id={"ledgerTransactionBatchTable-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={() => {
              setSearchTransactionBatchesRequest({
                ...searchTransactionBatchesRequest,
              });
            }}
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      columns={[
        {
          label: "ID",
          field: "id",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const txn = data as TransactionBatch;
            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                <Typography
                  sx={{
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  variant="body1"
                  children={txn.id}
                />
                <CopyPasteIcon
                  sx={(theme) => ({
                    fontSize: 20,
                    color: theme.palette.action.disabled,
                    "&:hover": {
                      color: theme.palette.action.active,
                    },
                    cursor: "pointer",
                  })}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(txn.id)
                      .then(() => enqueueSnackbar("Source Account ID copied"))
                  }
                />
              </Box>
            );
          },
        },
        {
          label: "Last Modified",
          field: "auditEntry.time",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) =>
            dayjs((data as TransactionBatch).auditEntry.time).format(
              DateTimeFormat,
            ),
        },
        {
          label: "State",
          field: "state",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            return (
              <TransactionBatchStateChip
                state={(data as TransactionBatch).state}
              />
            );
          },
        },
        {
          label: "Resolutions",
          field: "stateResolutionCount",
        },
        {
          label: "MetaData",
          field: "metaData",
          accessor: (data) => {
            const op = data as TransactionBatch;
            try {
              return (
                <Popover
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  popOverComponent={
                    <Card style={{ backgroundColor: "#1d1f21" }}>
                      <CardContent>
                        <ReactJson
                          name={false}
                          enableClipboard={(e) =>
                            navigator.clipboard
                              .writeText(JSON.stringify(e))
                              .then(() => enqueueSnackbar("copied"))
                          }
                          src={op.metaData}
                          theme="google"
                        />
                      </CardContent>
                    </Card>
                  }
                >
                  <Tooltip
                    title="select to view operation metadata"
                    placement="top"
                  >
                    <IconButton size="small">
                      <MetaDataIcon />
                    </IconButton>
                  </Tooltip>
                </Popover>
              );
            } catch (e) {
              console.error(`error rendering json payload: ${e}`);
              return "error rendering payload";
            }
          },
        },
      ]}
    />
  );
};
