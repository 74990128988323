/**
 * @fileoverview gRPC-Web generated client stub for remuneration
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: remuneration/feeScheduleReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var remuneration_feeSchedule_pb = require('../remuneration/feeSchedule_pb.js')

var num_decimal_pb = require('../num/decimal_pb.js')
const proto = {};
proto.remuneration = require('./feeScheduleReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.remuneration.FeeScheduleReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.remuneration.FeeScheduleReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.remuneration.ReadOneFeeScheduleRequest,
 *   !proto.remuneration.ReadOneFeeScheduleResponse>}
 */
const methodDescriptor_FeeScheduleReader_ReadOneFeeSchedule = new grpc.web.MethodDescriptor(
  '/remuneration.FeeScheduleReader/ReadOneFeeSchedule',
  grpc.web.MethodType.UNARY,
  proto.remuneration.ReadOneFeeScheduleRequest,
  proto.remuneration.ReadOneFeeScheduleResponse,
  /**
   * @param {!proto.remuneration.ReadOneFeeScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.remuneration.ReadOneFeeScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.remuneration.ReadOneFeeScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.remuneration.ReadOneFeeScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.remuneration.ReadOneFeeScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.remuneration.FeeScheduleReaderClient.prototype.readOneFeeSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/remuneration.FeeScheduleReader/ReadOneFeeSchedule',
      request,
      metadata || {},
      methodDescriptor_FeeScheduleReader_ReadOneFeeSchedule,
      callback);
};


/**
 * @param {!proto.remuneration.ReadOneFeeScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.remuneration.ReadOneFeeScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.remuneration.FeeScheduleReaderPromiseClient.prototype.readOneFeeSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/remuneration.FeeScheduleReader/ReadOneFeeSchedule',
      request,
      metadata || {},
      methodDescriptor_FeeScheduleReader_ReadOneFeeSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.remuneration.ReadManyFeeScheduleRequest,
 *   !proto.remuneration.ReadManyFeeScheduleResponse>}
 */
const methodDescriptor_FeeScheduleReader_ReadManyFeeSchedule = new grpc.web.MethodDescriptor(
  '/remuneration.FeeScheduleReader/ReadManyFeeSchedule',
  grpc.web.MethodType.UNARY,
  proto.remuneration.ReadManyFeeScheduleRequest,
  proto.remuneration.ReadManyFeeScheduleResponse,
  /**
   * @param {!proto.remuneration.ReadManyFeeScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.remuneration.ReadManyFeeScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.remuneration.ReadManyFeeScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.remuneration.ReadManyFeeScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.remuneration.ReadManyFeeScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.remuneration.FeeScheduleReaderClient.prototype.readManyFeeSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/remuneration.FeeScheduleReader/ReadManyFeeSchedule',
      request,
      metadata || {},
      methodDescriptor_FeeScheduleReader_ReadManyFeeSchedule,
      callback);
};


/**
 * @param {!proto.remuneration.ReadManyFeeScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.remuneration.ReadManyFeeScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.remuneration.FeeScheduleReaderPromiseClient.prototype.readManyFeeSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/remuneration.FeeScheduleReader/ReadManyFeeSchedule',
      request,
      metadata || {},
      methodDescriptor_FeeScheduleReader_ReadManyFeeSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.remuneration.ReadAllFeeScheduleRequest,
 *   !proto.remuneration.ReadAllFeeScheduleResponse>}
 */
const methodDescriptor_FeeScheduleReader_ReadAllFeeSchedule = new grpc.web.MethodDescriptor(
  '/remuneration.FeeScheduleReader/ReadAllFeeSchedule',
  grpc.web.MethodType.UNARY,
  proto.remuneration.ReadAllFeeScheduleRequest,
  proto.remuneration.ReadAllFeeScheduleResponse,
  /**
   * @param {!proto.remuneration.ReadAllFeeScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.remuneration.ReadAllFeeScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.remuneration.ReadAllFeeScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.remuneration.ReadAllFeeScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.remuneration.ReadAllFeeScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.remuneration.FeeScheduleReaderClient.prototype.readAllFeeSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/remuneration.FeeScheduleReader/ReadAllFeeSchedule',
      request,
      metadata || {},
      methodDescriptor_FeeScheduleReader_ReadAllFeeSchedule,
      callback);
};


/**
 * @param {!proto.remuneration.ReadAllFeeScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.remuneration.ReadAllFeeScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.remuneration.FeeScheduleReaderPromiseClient.prototype.readAllFeeSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/remuneration.FeeScheduleReader/ReadAllFeeSchedule',
      request,
      metadata || {},
      methodDescriptor_FeeScheduleReader_ReadAllFeeSchedule);
};


module.exports = proto.remuneration;

