import { Asset, Horizon } from "stellar-sdk";
import { Client, PaginationOpts, TradeAggregationRecord } from "./Client";
import CollectionPage = Horizon.ServerApi.CollectionPage;
import EffectRecord = Horizon.ServerApi.EffectRecord;

export type NewServerClientProps = {
  network: string;
  server: Horizon.Server;
};

export class ServerClient implements Client {
  readonly network: string;
  private readonly server: Horizon.Server;

  constructor(props: NewServerClientProps) {
    this.network = props.network;
    this.server = props.server;
  }

  async strictReceivePaths(
    source: string | Asset[],
    destinationAsset: Asset,
    destinationAmount: string,
  ): Promise<
    Horizon.ServerApi.CollectionPage<Horizon.ServerApi.PaymentPathRecord>
  > {
    return this.server
      .strictReceivePaths(source, destinationAsset, destinationAmount)
      .call();
  }

  async strictSendPaths(
    sourceAsset: Asset,
    sourceAmount: string,
    destination: string | Asset[],
  ): Promise<
    Horizon.ServerApi.CollectionPage<Horizon.ServerApi.PaymentPathRecord>
  > {
    return this.server
      .strictSendPaths(sourceAsset, sourceAmount, destination)
      .call();
  }

  tradeAggregations(
    base: Asset,
    counter: Asset,
    start_time: number,
    end_time: number,
    resolution: number,
    offset: number,
    paginationOpts?: PaginationOpts,
  ): Promise<Horizon.ServerApi.CollectionPage<TradeAggregationRecord>> {
    let callBuilder = this.server.tradeAggregation(
      base,
      counter,
      start_time,
      end_time,
      resolution,
      offset,
    );
    if (paginationOpts) {
      if (paginationOpts.limit) {
        callBuilder = callBuilder.limit(paginationOpts.limit);
      }
      if (paginationOpts.order) {
        callBuilder = callBuilder.order(paginationOpts.order);
      }
    }
    return callBuilder.call();
  }

  accountRecord(id: string): Promise<Horizon.ServerApi.AccountRecord> {
    return this.server.accounts().accountId(id).call();
  }

  liquidityPoolRecord(
    id: string,
  ): Promise<Horizon.ServerApi.LiquidityPoolRecord> {
    return this.server.liquidityPools().liquidityPoolId(id).call();
  }

  operationsForAccount(
    id: string,
    paginationOpts?: PaginationOpts,
  ): Promise<
    Horizon.ServerApi.CollectionPage<Horizon.ServerApi.OperationRecord>
  > {
    let callBuilder = this.server.operations();

    if (paginationOpts) {
      if (paginationOpts.limit) {
        callBuilder = callBuilder.limit(paginationOpts.limit);
      }
      if (paginationOpts.order) {
        callBuilder = callBuilder.order(paginationOpts.order);
      }
    }

    return callBuilder.forAccount(id).call();
  }

  streamEffectsForAccount(
    id: string,
    onMessage: (message: CollectionPage<EffectRecord>) => void,
    onError: (event: MessageEvent) => void,
    cursor: string,
  ): () => void {
    return this.server.effects().cursor(cursor).forAccount(id).stream({
      onmessage: onMessage,
      onerror: onError,
    });
  }
}
