/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: financial/assetflowDeferralReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_assetflowDeferral_pb = require('../financial/assetflowDeferral_pb.js')

var ledger_amount_pb = require('../ledger/amount_pb.js')

var financial_assetflowDeferralReader_meshproto_pb = require('../financial/assetflowDeferralReader_meshproto_pb.js')
const proto = {};
proto.financial = require('./assetflowDeferralReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.AssetflowDeferralReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.AssetflowDeferralReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneAssetflowDeferralRequest,
 *   !proto.financial.ReadOneAssetflowDeferralResponse>}
 */
const methodDescriptor_AssetflowDeferralReaderUNSCOPED_ReadOneAssetflowDeferralUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.AssetflowDeferralReaderUNSCOPED/ReadOneAssetflowDeferralUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_assetflowDeferralReader_meshproto_pb.ReadOneAssetflowDeferralRequest,
  financial_assetflowDeferralReader_meshproto_pb.ReadOneAssetflowDeferralResponse,
  /**
   * @param {!proto.financial.ReadOneAssetflowDeferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_assetflowDeferralReader_meshproto_pb.ReadOneAssetflowDeferralResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneAssetflowDeferralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneAssetflowDeferralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.AssetflowDeferralReaderUNSCOPEDClient.prototype.readOneAssetflowDeferralUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.AssetflowDeferralReaderUNSCOPED/ReadOneAssetflowDeferralUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReaderUNSCOPED_ReadOneAssetflowDeferralUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadOneAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneAssetflowDeferralResponse>}
 *     Promise that resolves to the response
 */
proto.financial.AssetflowDeferralReaderUNSCOPEDPromiseClient.prototype.readOneAssetflowDeferralUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.AssetflowDeferralReaderUNSCOPED/ReadOneAssetflowDeferralUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReaderUNSCOPED_ReadOneAssetflowDeferralUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyAssetflowDeferralRequest,
 *   !proto.financial.ReadManyAssetflowDeferralResponse>}
 */
const methodDescriptor_AssetflowDeferralReaderUNSCOPED_ReadManyAssetflowDeferralUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.AssetflowDeferralReaderUNSCOPED/ReadManyAssetflowDeferralUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_assetflowDeferralReader_meshproto_pb.ReadManyAssetflowDeferralRequest,
  financial_assetflowDeferralReader_meshproto_pb.ReadManyAssetflowDeferralResponse,
  /**
   * @param {!proto.financial.ReadManyAssetflowDeferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_assetflowDeferralReader_meshproto_pb.ReadManyAssetflowDeferralResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyAssetflowDeferralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyAssetflowDeferralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.AssetflowDeferralReaderUNSCOPEDClient.prototype.readManyAssetflowDeferralUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.AssetflowDeferralReaderUNSCOPED/ReadManyAssetflowDeferralUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReaderUNSCOPED_ReadManyAssetflowDeferralUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadManyAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyAssetflowDeferralResponse>}
 *     Promise that resolves to the response
 */
proto.financial.AssetflowDeferralReaderUNSCOPEDPromiseClient.prototype.readManyAssetflowDeferralUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.AssetflowDeferralReaderUNSCOPED/ReadManyAssetflowDeferralUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReaderUNSCOPED_ReadManyAssetflowDeferralUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllAssetflowDeferralRequest,
 *   !proto.financial.ReadAllAssetflowDeferralResponse>}
 */
const methodDescriptor_AssetflowDeferralReaderUNSCOPED_ReadAllAssetflowDeferralUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.AssetflowDeferralReaderUNSCOPED/ReadAllAssetflowDeferralUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_assetflowDeferralReader_meshproto_pb.ReadAllAssetflowDeferralRequest,
  financial_assetflowDeferralReader_meshproto_pb.ReadAllAssetflowDeferralResponse,
  /**
   * @param {!proto.financial.ReadAllAssetflowDeferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_assetflowDeferralReader_meshproto_pb.ReadAllAssetflowDeferralResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllAssetflowDeferralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllAssetflowDeferralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.AssetflowDeferralReaderUNSCOPEDClient.prototype.readAllAssetflowDeferralUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.AssetflowDeferralReaderUNSCOPED/ReadAllAssetflowDeferralUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReaderUNSCOPED_ReadAllAssetflowDeferralUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadAllAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllAssetflowDeferralResponse>}
 *     Promise that resolves to the response
 */
proto.financial.AssetflowDeferralReaderUNSCOPEDPromiseClient.prototype.readAllAssetflowDeferralUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.AssetflowDeferralReaderUNSCOPED/ReadAllAssetflowDeferralUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReaderUNSCOPED_ReadAllAssetflowDeferralUNSCOPED);
};


module.exports = proto.financial;

