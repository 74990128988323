import { Box, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { FutureToken } from "@mesh/common-js/dist/ledger/token_pb";
import { networkFromFutureNetwork } from "james/ledger/Network";
import dayjs from "dayjs";
import {
  MarketListingViewModel,
  Reader as MarketListingViewReader,
} from "james/views/marketListingView";
import { useEffect, useState } from "react";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { TextExactCriterion } from "james/search/criterion";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { Token } from "james/ledger";

export const SecuritiesRegisterOverview = () => {
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();

  const [data, setData] = useState<MarketListingViewModel>(
    new MarketListingViewModel(),
  );
  const [issuerName, setIssuerName] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [loadCard, setLoadCard] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!loading && !loadCard) return;

    // get the token for the asset that is to be listed from the url
    const tokenCode = searchParams.get("code") ?? "";
    const tokenIssuer = searchParams.get("issuer") ?? "";
    const tokenNetwork = Number(searchParams.get("network")) ?? 0;
    const futureToken = new FutureToken().setNetwork(tokenNetwork);
    const token = new Token({
      code: tokenCode.toUpperCase(),
      issuer: tokenIssuer.toUpperCase(),
      network: networkFromFutureNetwork(futureToken.getNetwork()),
    });

    try {
      (async () => {
        const marketListingViewModel = (
          await MarketListingViewReader.ReadOne({
            context: authContext,
            criteria: {
              "token.code": TextExactCriterion(tokenCode),
              "token.network": TextExactCriterion(
                networkFromFutureNetwork(futureToken.getNetwork()),
              ),
              "token.issuer": TextExactCriterion(tokenIssuer),
            },
          })
        ).model;
        if (marketListingViewModel.id === "") {
          throw new TypeError("market listing view model is null");
        }

        setData(marketListingViewModel);
        const ledgerTokenViewModel = await getLedgerTokenViewModel(token);

        if (ledgerTokenViewModel.id === "") {
          throw new TypeError("error retrieving issuer name");
        }
        setIssuerName(ledgerTokenViewModel.issuer);
      })();
    } catch (e) {
      errorContextDefaultErrorFeedback(
        e,
        "error retrieving securities register data",
      );
    } finally {
      setLoadCard(false);
      setLoading(false);
    }
  }, []);

  return (
    <Box mb={5}>
      <Typography variant="h1" fontWeight={600} mb={2}>
        Securities Register
      </Typography>
      <Typography variant="body1" mb={3} sx={{ fontStyle: "italic" }}>
        "A list of noteholders for certificated securities whose transfer has
        been restricted"
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Issuer</Typography>
          <Typography variant="h6">{issuerName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Instrument</Typography>
          <Typography variant="h6">{data?.assetName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Tokens in Circulation</Typography>
          <Typography variant="h6">
            {data?.marketSubscriptionOrderBookViewModel?.subscribedTokens?.value.toString()}
            {" Units"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">State of Instrument</Typography>
          <Typography variant="h6">{data?.listingState}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Issue Date</Typography>
          <Typography variant="h6">
            {dayjs(data?.assetIssueDate).format("YYYY/MM/DD")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {/* <Typography variant="subtitle2">Maturity Date</Typography>
          <Typography variant="h6">
            {dayjs(
              data?.marketSubscriptionOrderBookViewModel?.closeDate,
            ).format("YYYY/MM/DD")}
          </Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">No "uncertificated" note</Typography>
          <Typography variant="h6">
            {"0 (Zero) notes are held in uncertificated form"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
