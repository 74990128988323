import { useCallback, useEffect, useRef, useState } from "react";
import { useStellarContext } from "../Stellar";
import { Reader } from "james/views/stellarAccountView/Reader";
import { NewSorting, Query } from "james/search/query";
import { Model as StellarAccount } from "james/views/stellarAccountView/Model";
import { LedgerAccountCategory } from "pkgTemp/ledger/Account";
import { useNotificationContext } from "../Notification";
import { GroupNotificationChannel, GroupRepository } from "james/group";
import { Notification } from "james/notification/Notification";
import {
  AccountChangedNotification,
  AccountOpenedNotification,
  StellarAccountNotificationChannelName,
} from "james/stellar";
import { AccountChangedNotificationTypeName } from "james/stellar/AccountChangedNotification";
import { AccountOpenedNotificationTypeName } from "james/stellar/AccountOpenedNotification";
import { useCurrentAPICall } from "hooks";
import { UserKeyFetcher } from "james/key";
import { Key } from "james/key/Key";
import { Identifier } from "james/search/identifier/Identifier";
import { IdentifierType } from "james/search/identifier";
import { LedgerIDIdentifierType } from "james/search/identifier/LedgerID";
import { CategoryOwnerIDNetworkIdentifierType } from "james/search/identifier/CategoryOwnerIDNetwork";
import { IDIdentifierType } from "james/search/identifier/ID";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { TextNINListCriterion } from "james/search/criterion";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "../Application/Application";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";
import dayjs from "dayjs";
import { useStellarAccountEffectsNotifier } from "../../hooks/useStellarAccountEffectsNotifier";

export type StellarAccountContext = {
  accounts: StellarAccount[];
  keys: Key[];
  error: undefined | string;
  loading: boolean;
  previousFetchTimeStamp: undefined | string;
  refreshAccounts: () => void;
  refreshLedgerData: () => void;
  checkUserSignatoryOnAccount: (
    AccountIdentifier: Identifier,
  ) => Promise<boolean>;
};

export function useStellarAccountContext(): StellarAccountContext {
  const { errorContextErrorTranslator } = useErrorContext();
  const [models, setModels] = useState<StellarAccount[]>([]);
  const [modelsWithoutBalances, setModelsWithoutBalances] = useState<
    StellarAccount[]
  >([]);
  const [fetchingKeys, setFetchingKeys] = useState(false);
  const [fetchingAccounts, setFetchingAccounts] = useState(false);
  const [
    populatingAccountsWithLedgerDetails,
    setPopulatingAccountsWithLedgerDetails,
  ] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const {
    stellarAccountContextPopulateModelWithLedgerDetails,
    stellarAccountSignatoriesFetcher,
  } = useStellarContext();
  const { userAuthenticated, authContext } = useApplicationContext();
  const [refreshAccountsToggle, setRefreshAccountsToggle] = useState(false);
  const [refreshLedgerDataToggle, setRefreshLedgerDataToggle] = useState(false);
  const refreshLedgerDataTimeoutRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const refreshAccountsTimeoutRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const { registerNotificationCallback } = useNotificationContext();
  const { registerAccountEffectsCallback } = useStellarAccountEffectsNotifier();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groupsRegisteredForNotifications = useRef<any>({});
  const [
    isCurrentPopulateModelWithLedgerDetailsAPICall,
    initPopulateModelWithLedgerDetailsAPICall,
  ] = useCurrentAPICall();
  const [isCurrentReadViewModelsAPICall, initReadViewModelsAPICall] =
    useCurrentAPICall();
  const [userKeys, setUserKeys] = useState<Key[]>([]);
  const [accountEffectRegistered, setAccountEffectRegistered] = useState(false);
  const { viewConfiguration } = useApplicationContext();
  const [previousFetchTimeStamp, setPreviousFetchTimeStamp] = useState<
    string | undefined
  >(undefined);

  const checkUserSignatoryOnAccount: (
    accountIdentifier: Identifier,
  ) => Promise<boolean> = useCallback(
    async (accountIdentifier) => {
      if (
        fetchingAccounts ||
        fetchingKeys ||
        populatingAccountsWithLedgerDetails
      ) {
        throw new Error("account context loading in progress");
      }

      // retrieve the account
      let account;
      switch (accountIdentifier.type) {
        case IdentifierType.LedgerIDIdentifierTypeName:
          account = models.find(
            (v) =>
              v.ledgerID ===
              (accountIdentifier as LedgerIDIdentifierType).ledgerID,
          );

          // if the account is not found inside the local state. query stellar for the signatories
          if (!account) {
            try {
              const fetchAccountSignatoriesResponse =
                await stellarAccountSignatoriesFetcher.FetchAccountSignatories({
                  ledgerID: (accountIdentifier as LedgerIDIdentifierType)
                    .ledgerID,
                });

              // determine if the executing user is a signatory this account
              for (const k of userKeys) {
                if (
                  fetchAccountSignatoriesResponse.signatories.find(
                    (s) => s.key === k.publicKey && s.weight > 0,
                  )
                ) {
                  return true;
                }
              }
            } catch (e) {
              const err = e as Error;
              throw new Error(
                `error fetching account signatories: ${
                  err.message ? err.message : err.toString()
                }`,
              );
            }
          }

          break;

        case IdentifierType.CategoryOwnerIDNetworkTypeName:
          account = models.find(
            (v) =>
              v.category ===
                (accountIdentifier as CategoryOwnerIDNetworkIdentifierType)
                  .category &&
              v.network ===
                (accountIdentifier as CategoryOwnerIDNetworkIdentifierType)
                  .network &&
              v.ownerID ===
                (accountIdentifier as CategoryOwnerIDNetworkIdentifierType)
                  .ownerID,
          );
          break;
        case IdentifierType.IDIdentifierTypeName:
          account = models.find(
            (v) => v.id === (accountIdentifier as IDIdentifierType).id,
          );
          break;
        default:
          throw new TypeError(
            `unsupported identifier type: ${accountIdentifier.type}`,
          );
      }

      if (!account) {
        throw new Error("account not found");
      }

      // determine if the executing user is a signatory this account
      for (const k of userKeys) {
        if (
          account.signatories.find((s) => s.key === k.publicKey && s.weight > 0)
        ) {
          return true;
        }
      }

      return false;
    },
    [
      userKeys,
      models,
      fetchingAccounts,
      fetchingKeys,
      populatingAccountsWithLedgerDetails,
    ],
  );

  // this useEffect retrieves the user keys
  useEffect(() => {
    if (!userAuthenticated) {
      return;
    }

    (async () => {
      // if not logged do nothing

      setFetchingKeys(true);

      try {
        setUserKeys(
          (await UserKeyFetcher.FetchMyKeys({ context: authContext })).keys,
        );
      } catch (e) {
        const err = e as Error;
        console.error(
          `error fetching user keys: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error fetching user keys: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setFetchingKeys(false);
    })();
  }, [userAuthenticated, authContext]);

  // this useEffect registers for notifications
  useEffect(() => {
    (async () => {
      // if not logged in return
      if (!userAuthenticated) {
        return;
      }

      // only register for notifications when the user has the permission to view accounts
      if (!viewConfiguration.Wallet) {
        return;
      }

      // determine all group that the user has permision to view the accounts
      const searchGroupResponse = await GroupRepository.SearchGroups({
        context: authContext,
        criteria: (
          await Determiner.DetermineScopeCriteriaByRoles({
            context: authContext,
            service: new Permission({
              serviceName: "Read",
              serviceProvider: Reader.serviceProvider,
              description: "-",
            }),
            criteria: {},
            scopeFields: [ScopeFields.IDField],
            buildScopeTree: false,
          })
        ).criteria,
      });
      // for each groupID check that if we registered for notification
      // only register for groups that are not registered yet
      for (const g of searchGroupResponse.records) {
        if (!groupsRegisteredForNotifications.current[g.id]) {
          try {
            // register group for notification
            groupsRegisteredForNotifications.current[g.id] =
              await registerNotificationCallback(
                new GroupNotificationChannel({
                  groupID: g.id,
                  name: StellarAccountNotificationChannelName,
                  private: true,
                }),
                [
                  AccountChangedNotificationTypeName,
                  AccountOpenedNotificationTypeName,
                ],
                (n: Notification) => {
                  if (n instanceof AccountChangedNotification) {
                    setRefreshLedgerDataToggle(
                      (accountLegerDetailUpdateToggle) =>
                        !accountLegerDetailUpdateToggle,
                    );
                  }

                  if (n instanceof AccountOpenedNotification) {
                    setRefreshAccountsToggle(
                      (refreshAccountsToggle) => !refreshAccountsToggle,
                    );
                  }
                },
              );
          } catch (e) {
            console.error(
              `error registering for notifications on group channel '${StellarAccountNotificationChannelName}' for group ${g.id}`,
            );
          }
        }
      }
    })();
  }, [userAuthenticated, registerNotificationCallback, viewConfiguration]);

  // clean up useEffect
  useEffect(() => {
    if (!userAuthenticated) {
      setModels([]);
      setModelsWithoutBalances([]);
      setError(undefined);
      setUserKeys([]);
      setPopulatingAccountsWithLedgerDetails(false);
      setFetchingAccounts(false);
      setFetchingKeys(false);
      setRefreshAccountsToggle(false);
      setRefreshLedgerDataToggle(false);
      setAccountEffectRegistered(false);
      groupsRegisteredForNotifications.current = {};
    }
  }, [userAuthenticated]);

  // this useEffect fetched the account view models from mesh without the balance
  useEffect(() => {
    setFetchingAccounts(true);
    // do nothing if not logged in
    if (!userAuthenticated) {
      return;
    }

    // only attempt to populate account with ledger details, when the user has the permission to view accounts
    if (!viewConfiguration.Wallet) {
      return;
    }

    const { apiCallID, abortController } = initReadViewModelsAPICall();

    clearTimeout(refreshAccountsTimeoutRef.current);
    refreshAccountsTimeoutRef.current = setTimeout(async () => {
      try {
        const readResponse = await Reader.Read(
          {
            context: authContext,
            criteria: {
              category: TextNINListCriterion([
                LedgerAccountCategory.Clearance,
                LedgerAccountCategory.System,
              ]),
            },
            query: new Query({
              limit: 0,
              offset: 0,
              sorting: [NewSorting("number", "desc")],
            }),
          },
          { signal: abortController.signal },
        );

        if (isCurrentReadViewModelsAPICall(apiCallID)) {
          setModelsWithoutBalances(readResponse.models);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === JSONRPCCallAbortedError.ErrorCode) {
          return;
        }

        console.error(
          `error refreshing accounts: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(err.message);
      }
      setFetchingAccounts(false);
    }, 400);
  }, [userAuthenticated, refreshAccountsToggle, viewConfiguration]);

  useEffect(() => {
    if (modelsWithoutBalances.length === 0) {
      return;
    }

    if (accountEffectRegistered) {
      return;
    }

    // register for account effects callback
    modelsWithoutBalances.forEach((m) => {
      registerAccountEffectsCallback(m.ledgerID, () => {
        clearTimeout(refreshLedgerDataTimeoutRef.current);
        refreshLedgerDataTimeoutRef.current = setTimeout(async () => {
          try {
            // populate each model with ledger details
            const ledgerDetailsPopulatedModelList: Promise<StellarAccount>[] =
              [];
            for (const m of modelsWithoutBalances) {
              ledgerDetailsPopulatedModelList.push(
                stellarAccountContextPopulateModelWithLedgerDetails(m),
              );
            }

            setModels(await Promise.all(ledgerDetailsPopulatedModelList));
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error populating view model with ledger details: ${
                err.message ? err.message : err.toString()
              }`,
            );
            setError(
              `error populating view model with ledger details: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
          setPopulatingAccountsWithLedgerDetails(false);
          setPreviousFetchTimeStamp(dayjs().format());
        }, 400);
      });
    });
    setAccountEffectRegistered(true);
  }, [modelsWithoutBalances.length]);

  // useEffect is meant to trigger a retrieval of the balance for each of the accounts without triggering an reload
  useEffect(() => {
    // do nothing if account retrieval is already in progress
    if (fetchingAccounts) {
      return;
    }

    // do nothing if not logged in
    if (!userAuthenticated) {
      return;
    }

    // only attempt to populate account with ledger details, when the user has the permission to view accounts
    if (!viewConfiguration.Wallet) {
      return;
    }

    setPopulatingAccountsWithLedgerDetails(true);

    const { apiCallID } = initPopulateModelWithLedgerDetailsAPICall();

    clearTimeout(refreshLedgerDataTimeoutRef.current);
    refreshLedgerDataTimeoutRef.current = setTimeout(async () => {
      try {
        // populate each model with ledger details
        const ledgerDetailsPopulatedModelList: Promise<StellarAccount>[] = [];
        for (const m of modelsWithoutBalances) {
          ledgerDetailsPopulatedModelList.push(
            stellarAccountContextPopulateModelWithLedgerDetails(m),
          );
        }

        if (isCurrentPopulateModelWithLedgerDetailsAPICall(apiCallID)) {
          setModels(await Promise.all(ledgerDetailsPopulatedModelList));
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error populating view model with ledger details: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error populating view model with ledger details: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setPopulatingAccountsWithLedgerDetails(false);
      setPreviousFetchTimeStamp(dayjs().format());
    }, 400);
  }, [
    userAuthenticated,
    refreshLedgerDataToggle,
    fetchingAccounts,
    modelsWithoutBalances.length,
    viewConfiguration,
  ]);

  return {
    accounts: models,
    error,
    previousFetchTimeStamp,
    keys: userKeys,
    loading:
      fetchingAccounts || fetchingKeys || populatingAccountsWithLedgerDetails,
    checkUserSignatoryOnAccount,
    refreshAccounts: () =>
      setRefreshAccountsToggle(
        (refreshAccountsToggle) => !refreshAccountsToggle,
      ),
    refreshLedgerData: () =>
      setRefreshLedgerDataToggle(
        (accountLegerDetailUpdateToggle) => !accountLegerDetailUpdateToggle,
      ),
  };
}
