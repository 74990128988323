import React, { useCallback, useEffect } from "react";
import { FundingOrder } from "@mesh/common-js/dist/banking/fundingOrder_pb";
import { useAPIContext } from "context/API";
import { ReadManyFundingOrderRequest } from "@mesh/common-js/dist/banking/fundingOrderReader_meshproto_pb";
import { useApplicationContext } from "context/Application/Application";
import { UpdateFundingOrderAccountNumberRequest } from "@mesh/common-js/dist/banking/fundingOrderManager_pb";
import { useErrorContext } from "context/Error";
import { useSnackbar } from "notistack";
import {
  CancelFundingOrderRequest,
  ResolveFundingOrderRequest,
  SettleFundingOrderRequest,
  SubmitFundingOrderRequest,
} from "@mesh/common-js/dist/banking/fundingOrderStateController_pb";

export type FundingContextType = {
  apiCallInProgress: boolean;

  fundingOrder: FundingOrder;
  setFundingOrder: (fundingOrder: FundingOrder) => void;

  fundingOrders: FundingOrder[];
  fundingOrdersLoaded: boolean;
  fundingOrdersLoadError: string | null;
  clearFundingOrdersLoadError: () => void;
  reloadFundingOrders: () => void;

  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;

  updateFundingOrderAccountNumber: (
    fundingOrder: FundingOrder,
  ) => Promise<void>;
  submitFundingOrder: (fundingOrder: FundingOrder) => Promise<void>;
  settleFundingOrder: (fundingOrderID: string, reason: string) => Promise<void>;
  cancelFundingOrder: () => Promise<void>;
  resolveFundingOrder: (fundingOrderID: string) => Promise<void>;
};

export const defaultContext: FundingContextType = {
  apiCallInProgress: false,

  fundingOrder: new FundingOrder(),
  setFundingOrder: () => null,
  fundingOrders: [],
  fundingOrdersLoaded: false,
  fundingOrdersLoadError: null,
  clearFundingOrdersLoadError: () => null,
  reloadFundingOrders: () => null,

  initialised: false,
  initialisationError: null,
  clearInitialisationError: () => null,

  updateFundingOrderAccountNumber: () => new Promise(() => null),
  resolveFundingOrder: () => new Promise(() => null),
  submitFundingOrder: () => new Promise(() => null),
  settleFundingOrder: () => new Promise(() => null),
  cancelFundingOrder: () => new Promise(() => null),
};

const FundingContext = React.createContext<FundingContextType>(defaultContext);

export const useFundingContext = () => React.useContext(FundingContext);

export const FundingContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultWarningFeedback, errorContextErrorTranslator } =
    useErrorContext();

  const {
    banking: {
      fundingOrderManager,
      fundingOrderReaderUNSCOPED,
      fundingOrderStateController,
    },
  } = useAPIContext();
  const { authContext } = useApplicationContext();

  const clearInitialisationError = () =>
    useCallback(() => {
      setFundingOrdersLoadError(null);
    }, [fundingOrdersLoadError]);

  const [fetchingFundingOrdersInProgress, setFetchingFundingOrderInProgress] =
    React.useState<boolean>(false);

  const [updateAccountNumberInProgress, setUpdateAccountNumberInProgress] =
    React.useState<boolean>(false);

  const [cancelFundingOrderInProgress, setCancelFundingOrderInProgress] =
    React.useState<boolean>(false);

  const [settleFundingOrderInProgress, setSettleFundingOrderInProgress] =
    React.useState<boolean>(false);

  const [submitFundingOrderInProgress, setSubmitFundingOrderInProgress] =
    React.useState<boolean>(false);

  const [fundingOrder, setFundingOrder] = React.useState<FundingOrder>(
    new FundingOrder(),
  );
  const [fundingOrders, setFundingOrders] = React.useState<FundingOrder[]>([]);
  const [fundingOrdersLoaded, setFundingOrdersLoaded] =
    React.useState<boolean>(false);
  const [resolveFundingOrderInProgress, setResolveFundingOrderInProgress] =
    React.useState<boolean>(false);
  const [fundingOrdersLoadError, setFundingOrdersLoadError] = React.useState<
    string | null
  >(null);
  const reloadFundingOrders = () => setFundingOrdersLoaded(false);
  const clearFundingOrdersLoadError = () => setFundingOrdersLoadError(null);

  useEffect(() => {
    (async () => {
      if (fundingOrdersLoaded || fundingOrdersLoadError) {
        return;
      }

      setFetchingFundingOrderInProgress(true);
      let fundingOrders: FundingOrder[] = [];
      try {
        fundingOrders = (
          await fundingOrderReaderUNSCOPED.readManyFundingOrderUNSCOPED(
            new ReadManyFundingOrderRequest()
              .setContext(authContext.toFuture())
              .setCriteriaList([]),
          )
        ).getRecordsList();
      } catch (e) {
        setFundingOrdersLoadError("Failed to load funding orders");
      }

      setFetchingFundingOrderInProgress(false);
      setFundingOrders(fundingOrders);
      setFundingOrdersLoaded(true);
    })();
  }, [fundingOrdersLoadError, fundingOrdersLoaded]);

  const updateFundingOrderAccountNumber = async (
    fundingOrder: FundingOrder,
  ) => {
    if (!fundingOrder) {
      return;
    }

    setUpdateAccountNumberInProgress(true);
    let updatedFundingOrder: FundingOrder | undefined = new FundingOrder();
    try {
      updatedFundingOrder = (
        await fundingOrderManager.updateFundingOrderAccountNumber(
          new UpdateFundingOrderAccountNumberRequest()
            .setContext(authContext.toFuture())
            .setAccountnumber(fundingOrder.getAccountnumber())
            .setFundingorderid(fundingOrder.getId()),
        )
      ).getFundingorder();
      enqueueSnackbar("Funding order account number updated", {
        variant: "success",
      });
    } catch (e) {
      errorContextDefaultWarningFeedback(
        e,
        "Failed to update funding order account number",
      );
    }
    setUpdateAccountNumberInProgress(false);
    if (updatedFundingOrder) {
      setFundingOrder(updatedFundingOrder);
    }
  };

  const cancelFundingOrder = async () => {
    if (!fundingOrder) {
      return;
    }

    setCancelFundingOrderInProgress(true);
    try {
      await fundingOrderStateController.cancelFundingOrder(
        new CancelFundingOrderRequest()
          .setContext(authContext.toFuture())
          .setFundingorderid(fundingOrder.getId())
          .setReason(fundingOrder.getReason()),
      );
      enqueueSnackbar("Funding order cancelled", { variant: "success" });
    } catch (e) {
      errorContextDefaultWarningFeedback(e, "Failed to cancel funding order");
    }
    setCancelFundingOrderInProgress(false);
  };

  const settleFundingOrder = async (fundingOrderID: string, reason: string) => {
    setSettleFundingOrderInProgress(true);
    try {
      await fundingOrderStateController.settleFundingOrder(
        new SettleFundingOrderRequest()
          .setContext(authContext.toFuture())
          .setFundingorderid(fundingOrderID)
          .setReason(reason),
      );
      enqueueSnackbar("Funding order Approved", { variant: "success" });
    } catch (e) {
      errorContextDefaultWarningFeedback(e, "Failed to approve funding order");
    }
    setSettleFundingOrderInProgress(false);
  };

  const resolveFundingOrder = async (fundingOrderID: string) => {
    setResolveFundingOrderInProgress(true);
    try {
      await fundingOrderStateController.resolveFundingOrder(
        new ResolveFundingOrderRequest()
          .setContext(authContext.toFuture())
          .setFundingorderid(fundingOrderID),
      );
      enqueueSnackbar("Funding order resolved", { variant: "success" });
    } catch (e) {
      errorContextDefaultWarningFeedback(e, "Failed to resolve funding order");
    }
    setResolveFundingOrderInProgress(false);
  };

  const submitFundingOrder = async (fundingOrder: FundingOrder) => {
    if (!fundingOrder) {
      return;
    }

    setSubmitFundingOrderInProgress(true);
    try {
      await fundingOrderStateController.submitFundingOrder(
        new SubmitFundingOrderRequest()
          .setContext(authContext.toFuture())
          .setAccountnumber(fundingOrder.getAccountnumber())
          .setAmount(fundingOrder.getAmount())
          .setBankreference(fundingOrder.getBankreference())
          .setReference(fundingOrder.getReference())
          .setValuedate(fundingOrder.getValuedate()),
      );
      enqueueSnackbar("Funding order submitted", { variant: "success" });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error submitting funding order: ${
          e.message.split(": ")[1]
            ? e.message.split(": ")[1]
            : err.message
              ? err.message
              : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setSubmitFundingOrderInProgress(false);
  };

  return (
    <FundingContext.Provider
      value={{
        apiCallInProgress:
          fetchingFundingOrdersInProgress ||
          updateAccountNumberInProgress ||
          cancelFundingOrderInProgress ||
          settleFundingOrderInProgress ||
          submitFundingOrderInProgress ||
          resolveFundingOrderInProgress,

        fundingOrder,
        setFundingOrder,

        fundingOrders,
        fundingOrdersLoaded,
        fundingOrdersLoadError,
        clearFundingOrdersLoadError,
        reloadFundingOrders,

        initialised: fundingOrdersLoaded,
        initialisationError: fundingOrdersLoadError,
        clearInitialisationError,

        updateFundingOrderAccountNumber,
        submitFundingOrder,
        settleFundingOrder,
        cancelFundingOrder,
        resolveFundingOrder,
      }}
    >
      {children}
    </FundingContext.Provider>
  );
};
