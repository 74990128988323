import React, { useEffect, useRef, useState } from "react";
import { BPTable } from "components/Table";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  EditOutlined as EditIcon,
  Error as ErrorIcon,
  Receipt,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { DateField, TextField } from "components/FormFields";
import { TextExactCriterion } from "james/search/criterion";
import { Amount as LedgerAmount } from "james/ledger/Amount";
import { Amount } from "components/Ledger/Amount";
import { dateIsValid } from "utilities/date/dateIsValid";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { FundingOrderStateChip } from "./components/FundinngOrderStateChips/FundingOrderStateChip";
import { FundingOrderDialog } from "./components/FundingOrderDiolog/FundingOrderDialog";
import { TransactionTable } from "components/Ledger/Transaction";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFundingContext } from "./Context";
import { FundingOrder } from "@mesh/common-js/dist/banking/fundingOrder_pb";
import { FundingOrderState } from "@mesh/common-js/dist/banking/fundingOrderState_pb";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Query as PastQuery } from "james/search/query";
import {
  ReadManyFundingOrderRequest,
  ReadManyFundingOrderResponse,
} from "@mesh/common-js/dist/banking/fundingOrderReader_meshproto_pb";
import { useAPIContext } from "context/API";
import { useIsMounted } from "hooks";
import { EditFundingDialog } from "./components/EditFundingDialog/EditFundingDialog";
import { Confirmation as ConfirmApproval } from "views/Banking/components/Funding/components/Confirmation/Approval/ConfirmApproval";
import {
  AllFundingOrderStates,
  FundingOrderStatusReason,
} from "james/banking/fundingOrder";
import {
  newUint32ListCriterion,
  newDateRangeCriterion,
  newTextSubstringCriterion,
} from "@mesh/common-js/dist/search";
import {
  fundingOrderStateToString,
  stringToFundingOrderState,
} from "@mesh/common-js/dist/banking/fundingOrderState";
import { RangeValue } from "@mesh/common-js/dist/search/dateRangeCriterion_pb";
import {
  Criterion,
  ORCriterion,
} from "@mesh/common-js/dist/search/criterion_pb";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";

const initialQuery = new Query()
  .setOffset(0)
  .setLimit(15)
  .setSortingList([
    new Sorting()
      .setField("valuedate")
      .setOrder(SortingOrder.DESC_SORTING_ORDER),
  ]);

const PREFIX = "Funding";

const classes = {
  assetToMintSection: `${PREFIX}-assetToMintSection`,
  detail: `${PREFIX}-detail`,
  collapse: `${PREFIX}-collapse`,
  dialogTitleContent: `${PREFIX}-dialogTitleContent`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  textSearchField: `${PREFIX}-textSearchField`,
  dateFilterField: `${PREFIX}-dateFilterField`,
  iconButton: `${PREFIX}-iconButton`,
};

export function Funding() {
  const { apiCallInProgress, settleFundingOrder, resolveFundingOrder } =
    useFundingContext();
  const {
    banking: { fundingOrderReaderUNSCOPED },
  } = useAPIContext();
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();
  const isMounted = useIsMounted();
  const [confirmApproval, setConfirmApproval] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);

  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const [selectedFundingOrder, setSelectedFundingOrder] =
    useState<FundingOrder>(new FundingOrder());
  const [showEditFundingOrderDialog, setShowEditFundingOrderDialog] =
    useState(false);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [dateTimeCriterionFrom, setDateTimeCriterionFrom] =
    useState<RangeValue>(new RangeValue());
  const [loading, setLoading] = useState(false);
  const [dateTimeCriterionTo, setDateTimeCriterionTo] = useState<RangeValue>(
    new RangeValue(),
  );
  const [readResponse, setReadResponse] =
    useState<ReadManyFundingOrderResponse>(new ReadManyFundingOrderResponse());
  const [readRequest, setReadRequest] = useState<ReadManyFundingOrderRequest>(
    new ReadManyFundingOrderRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
  );
  const [dateCriteriaSet, setDateCriteriaSet] = useState(false);
  const [
    fundingOrderStatusesForCriterion,
    setFundingOrderStatusesForCriterion,
  ] = useState<FundingOrderState[]>([]);
  const [queryChanged, setQueryChanged] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      try {
        const response =
          await fundingOrderReaderUNSCOPED.readManyFundingOrderUNSCOPED(
            readRequest,
          );

        if (isMounted()) {
          setReadResponse(response);
        }
      } catch (e) {
        console.error("error reading funding orders", e);
        errorContextDefaultWarningFeedback(e, "error reading funding orders");
      }
      setLoading(false);
      setQueryChanged(false);
    }, 600);
  }, [readRequest, isMounted, queryChanged]);

  useEffect(() => {
    if (textSearchCriterionTextField) {
      for (let i = 0; i < readRequest.getCriteriaList().length; i++) {
        if (
          readRequest
            .getCriteriaList()
            [i].getOrcriterion()
            ?.getCriteriaList()
            ?.some(
              (criterion) =>
                criterion.getTextsubstringcriterion()?.getField() ===
                "accountnumber",
            )
        ) {
          readRequest.getCriteriaList().splice(i, 1);
        }
      }
      readRequest.setCriteriaList([
        ...readRequest.getCriteriaList(),
        new Criterion().setOrcriterion(
          new ORCriterion().setCriteriaList([
            newTextSubstringCriterion(
              "accountnumber",
              textSearchCriterionTextField,
            ),
            newTextSubstringCriterion(
              "bankreference",
              textSearchCriterionTextField,
            ),
            newTextSubstringCriterion(
              "reference",
              textSearchCriterionTextField,
            ),
          ]),
        ),
      ]);
    }

    if (dateTimeCriterionFrom.getDate() || dateTimeCriterionTo.getDate()) {
      for (let i = 0; i < readRequest.getCriteriaList().length; i++) {
        if (
          readRequest
            .getCriteriaList()
            [i].getDaterangecriterion()
            ?.getField() === "valuedate"
        ) {
          readRequest.getCriteriaList().splice(i, 1);
        }
      }

      if (!dateTimeCriterionTo.getDate()) {
        dateTimeCriterionTo.setIgnore(true);
        readRequest.setCriteriaList([
          ...readRequest.getCriteriaList(),
          newDateRangeCriterion(
            "valuedate",
            dateTimeCriterionFrom,
            dateTimeCriterionTo,
          ),
        ]);
      }

      if (!dateTimeCriterionFrom.getDate()) {
        dateTimeCriterionFrom.setIgnore(true);
        readRequest.setCriteriaList([
          ...readRequest.getCriteriaList(),
          newDateRangeCriterion(
            "valuedate",
            dateTimeCriterionFrom,
            dateTimeCriterionTo,
          ),
        ]);
      }

      if (dateTimeCriterionFrom.getDate() && dateTimeCriterionTo.getDate()) {
        readRequest.setCriteriaList([
          ...readRequest.getCriteriaList(),
          newDateRangeCriterion(
            "valuedate",
            dateTimeCriterionFrom,
            dateTimeCriterionTo,
          ),
        ]);
      }

      setDateCriteriaSet(false);
    }

    if (fundingOrderStatusesForCriterion.length) {
      for (let i = 0; i < readRequest.getCriteriaList().length; i++) {
        if (
          readRequest
            .getCriteriaList()
            [i].getUint32listcriterion()
            ?.getField() === "state"
        ) {
          readRequest.getCriteriaList().splice(i, 1);
        }
      }
      readRequest.setCriteriaList([
        ...readRequest.getCriteriaList(),
        newUint32ListCriterion("state", fundingOrderStatusesForCriterion),
      ]);
    }

    if (!textSearchCriterionTextField && readRequest.getCriteriaList().length) {
      for (let i = 0; i < readRequest.getCriteriaList().length; i++) {
        if (
          readRequest
            .getCriteriaList()
            [i].getOrcriterion()
            ?.getCriteriaList()
            ?.some(
              (criterion) =>
                criterion.getTextsubstringcriterion()?.getField() ===
                "accountnumber",
            )
        ) {
          readRequest.getCriteriaList().splice(i, 1);
        }
      }
    }

    if (
      !fundingOrderStatusesForCriterion.length &&
      readRequest.getCriteriaList().length
    ) {
      for (let i = 0; i < readRequest.getCriteriaList().length; i++) {
        if (
          readRequest
            .getCriteriaList()
            [i].getUint32listcriterion()
            ?.getField() === "state"
        ) {
          readRequest.getCriteriaList().splice(i, 1);
        }
      }
    }

    if (
      !(
        dateTimeCriterionTo.getInclusive() ||
        dateTimeCriterionFrom.getInclusive()
      ) &&
      readRequest.getCriteriaList().length
    ) {
      for (let i = 0; i < readRequest.getCriteriaList().length; i++) {
        if (
          readRequest
            .getCriteriaList()
            [i].getDaterangecriterion()
            ?.getField() === "valuedate"
        ) {
          readRequest.getCriteriaList().splice(i, 1);
        }
      }
    }

    setReadRequest(
      readRequest.setContext(authContext.toFuture()).setQuery(initialQuery),
    );
    setQueryChanged(true);
  }, [
    textSearchCriterionTextField,
    readRequest.getCriteriaList(),
    readRequest.getContext(),
    authContext,
    dateTimeCriterionFrom,
    dateTimeCriterionTo,
    fundingOrderStatusesForCriterion,
    dateCriteriaSet,
  ]);

  const handleApproveFunding = (approve: boolean) => {
    setConfirmApproval(approve);
  };

  return (
    <>
      <BPTable
        loading={apiCallInProgress || loading}
        singleSelect
        height={window.innerHeight - 138 - noticeBannerHeight}
        title="Funding Orders"
        data={readResponse.getRecordsList()}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSingleSelectChange={(data: { [p: string]: any } | undefined) =>
          setSelectedFundingOrder(data as FundingOrder)
        }
        columns={[
          {
            label: "Account Number",
            field: "accountnumber",
            accessor: (data) => {
              const model = data as FundingOrder;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {model.getAccountnumber()}
                </Typography>
              );
            },
          },
          {
            label: "Bank Transaction Ref.",
            field: "bankreference",
            accessor: (data) => {
              const model = data as FundingOrder;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {model.getBankreference()}
                </Typography>
              );
            },
          },
          {
            label: "Bank Reference",
            field: "reference",
            accessor: (data) => {
              const model = data as FundingOrder;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {model.getReference()}
                </Typography>
              );
            },
          },
          {
            label: "Amount",
            field: "amount",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => (
              <Amount
                amount={LedgerAmount.fromFutureAmount(
                  (data as FundingOrder).getAmount(),
                )}
              />
            ),
          },
          {
            label: "Value Date",
            field: "valuedate",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              const model = data as FundingOrder;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {protobufTimestampToDayjs(
                    model.getValuedate() ?? new Timestamp(),
                  )
                    .local()
                    .format("YYYY/MM/DD HH:mm:ss")}
                </Typography>
              );
            },
          },
          {
            label: "Status",
            field: "state",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              const model = data as FundingOrder;
              if (
                model.getState() ===
                  FundingOrderState.FAILED_FUNDING_ORDER_STATE ||
                model.getState() ===
                  FundingOrderState.CANCELLED_FUNDING_ORDER_STATE
              ) {
                return (
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      gap: theme.spacing(1),
                      gridGap: (theme) => theme.spacing(1),
                      alignItems: "center",
                      marginRight: theme.spacing(5),
                    })}
                  >
                    <FundingOrderStateChip state={model.getState()} />
                    <Tooltip title={model.getReason()} placement={"top"}>
                      <ErrorIcon
                        sx={{
                          color: "secondary.light",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Box>
                );
              }
              return <FundingOrderStateChip state={model.getState()} />;
            },
          },
        ]}
        query={PastQuery.fromFutureQuery(readRequest.getQuery())}
        onQueryChange={(query) => {
          setQueryChanged(true);
          setReadRequest(readRequest.setQuery(query.toFutureQuery()));
        }}
        totalNoRecords={readResponse.getTotal()}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          controls.push(
            <Tooltip title="Add Funding Order">
              <span>
                <IconButton
                  onClick={() => setDisplayDialog(true)}
                  id="funding-addfundingOrder-button"
                  disabled={apiCallInProgress || loading}
                  size="small"
                >
                  <Receipt />
                </IconButton>
              </span>
            </Tooltip>,
          );

          controls.push(
            <Tooltip title="Reload">
              <span>
                <IconButton
                  size="small"
                  onClick={() => {
                    setQueryChanged(true);
                    setReadRequest(readRequest.setQuery(initialQuery));
                  }}
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );

          if (
            selectedFundingOrder &&
            selectedFundingOrder.getState() ===
              FundingOrderState.AWAITING_APPROVAL_FUNDING_ORDER_STATE
          ) {
            controls.push(
              <Tooltip title="Edit" placement="top">
                <span>
                  <IconButton
                    id="fundingOrder-edit-iconButton"
                    size="small"
                    onClick={() => {
                      setShowEditFundingOrderDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>,
            );
          }

          if (
            selectedFundingOrder &&
            selectedFundingOrder.getState() ===
              FundingOrderState.AWAITING_APPROVAL_FUNDING_ORDER_STATE &&
            selectedFundingOrder.getAccountnumber() !== ""
          ) {
            controls.push(
              <LoadingButton
                disabled={apiCallInProgress || loading}
                variant={"contained"}
                color={"primary"}
                children={"Approve"}
                loading={loading}
                onClick={() => handleApproveFunding(true)}
              />,
            );
          }

          if (
            selectedFundingOrder &&
            selectedFundingOrder.getState() ===
              FundingOrderState.FAILED_FUNDING_ORDER_STATE
          ) {
            controls.push(
              <LoadingButton
                disabled={loading}
                variant={"contained"}
                color={"primary"}
                children={"Retry"}
                loading={apiCallInProgress}
                onClick={() =>
                  settleFundingOrder(
                    selectedFundingOrder.getId(),
                    `${FundingOrderStatusReason.FundingOrderRetried} by ${authContext.userID}`,
                  )
                }
              />,
            );
          }

          if (
            selectedFundingOrder &&
            (selectedFundingOrder.getState() ===
              FundingOrderState.UNDER_INVESTIGATION_FUNDING_ORDER_STATE ||
              selectedFundingOrder.getState() ===
                FundingOrderState.SETTLEMENT_IN_PROGRESS_FUNDING_ORDER_STATE)
          ) {
            controls.push(
              <LoadingButton
                disabled={apiCallInProgress}
                variant={"contained"}
                color={"primary"}
                children={"Resolve State"}
                loading={apiCallInProgress}
                onClick={() =>
                  resolveFundingOrder(selectedFundingOrder.getId())
                }
              />,
            );
          }

          return controls;
        })()}
        filters={[
          <TextField
            variant="outlined"
            margin="dense"
            className={classes.textSearchField}
            label="Search Text Fields"
            placeholder="Start Typing..."
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterionTextField ? (
                <InputAdornment
                  position="end"
                  children={
                    <IconButton
                      size="small"
                      onClick={() => setTextSearchCriterionTextField("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterionTextField}
            onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
          />,
          <DateField
            label="From"
            id="funding-dateRangeFrom-dateField"
            className={classes.dateFilterField}
            value={
              dateTimeCriterionFrom.getDate()
                ? protobufTimestampToDayjs(
                    dateTimeCriterionFrom?.getDate() ?? new Timestamp(),
                  ).format("MM/DD/YYYY")
                : ""
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionFrom(new RangeValue());
                setDateCriteriaSet(true);
              } else {
                setDateTimeCriterionFrom(
                  dateTimeCriterionFrom
                    .setDate(dayjsToProtobufTimestamp(newValue))
                    .setInclusive(true)
                    .setIgnore(false),
                );
                setDateCriteriaSet(true);
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionFrom && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                            onClick={() =>
                              setDateTimeCriterionFrom(new RangeValue())
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
          <DateField
            label="To"
            id="funding-dateRangeTo-dateField"
            className={classes.dateFilterField}
            value={
              dateTimeCriterionTo.getDate()
                ? protobufTimestampToDayjs(
                    dateTimeCriterionTo?.getDate() ?? new Timestamp(),
                  ).format("MM/DD/YYYY")
                : ""
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionTo(new RangeValue());
                setDateCriteriaSet(true);
              } else {
                setDateTimeCriterionTo(
                  dateTimeCriterionTo
                    .setDate(dayjsToProtobufTimestamp(newValue))
                    .setInclusive(true)
                    .setIgnore(false),
                );
                setDateCriteriaSet(true);
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionTo && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                            onClick={() => {
                              setDateTimeCriterionTo(new RangeValue());
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
          <Autocomplete
            id={"fundingTable-stateFilter-autocomplete"}
            disabled={apiCallInProgress || loading}
            multiple
            options={AllFundingOrderStates}
            filterSelectedOptions
            onChange={(_, value: string[]) =>
              setFundingOrderStatusesForCriterion(
                value.map((s) => stringToFundingOrderState(s)),
              )
            }
            value={fundingOrderStatusesForCriterion.map((s) =>
              fundingOrderStateToString(s),
            )}
            renderTags={(fundingOrderStates: string[]) =>
              fundingOrderStates.map((s, idx) => (
                <Box sx={{ padding: "4px" }}>
                  <FundingOrderStateChip
                    key={idx}
                    chipProps={{
                      onDelete: () =>
                        setFundingOrderStatusesForCriterion((prev) =>
                          prev.filter(
                            (prevState) =>
                              prevState !== stringToFundingOrderState(s),
                          ),
                        ),
                      deleteIcon: (
                        <CancelIcon
                          sx={(theme) => ({
                            color: `${theme.palette.text.secondary} !important`,
                            "&:hover": {
                              color: `${theme.palette.secondary.contrastText} !important`,
                            },
                          })}
                        />
                      ),
                    }}
                    state={stringToFundingOrderState(s)}
                  />
                </Box>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id={"fundingTable-stateFilter-autocompleteTextField"}
                sx={{ width: 317 }}
                label={"State"}
                variant={"outlined"}
                margin={"dense"}
                InputLabelProps={{ shrink: true }}
                placeholder={
                  fundingOrderStatusesForCriterion.length
                    ? undefined
                    : "Select..."
                }
              />
            )}
          />,
        ]}
        expandRowComponent={{
          maxHeight: 300,
          component: (selectedRowData) => {
            const fundingOrder = selectedRowData as FundingOrder;
            return (
              <TransactionTable
                height={400}
                title={`Bank Transaction Ref. ${fundingOrder.getBankreference()}`}
                constantCriteria={{
                  "metaData.fundingOrderID": TextExactCriterion(
                    fundingOrder.getId(),
                  ),
                }}
              />
            );
          },
        }}
      />
      {displayDialog && (
        <FundingOrderDialog
          onClose={() => {
            setDisplayDialog(false);
            setQueryChanged(true);
            setReadRequest(readRequest.setQuery(initialQuery));
            handleApproveFunding(false);
          }}
          open={displayDialog}
        />
      )}
      {showEditFundingOrderDialog && (
        <EditFundingDialog
          open={showEditFundingOrderDialog}
          onClose={() => {
            setShowEditFundingOrderDialog(false);
            setQueryChanged(true);
            setReadRequest(readRequest.setQuery(initialQuery));
            handleApproveFunding(false);
          }}
          fundingOrder={selectedFundingOrder}
          confirmApproval={confirmApproval}
          handleApproveFunding={handleApproveFunding}
        />
      )}
      {confirmApproval && (
        <ConfirmApproval
          handleApprovalButton={settleFundingOrder}
          loading={loading || apiCallInProgress}
          confirmApproval={confirmApproval}
          fundingOrder={selectedFundingOrder}
          classes={classes}
          onClose={() => {
            setShowEditFundingOrderDialog(false);
            setQueryChanged(true);
            setReadRequest(readRequest.setQuery(initialQuery));
            handleApproveFunding(false);
          }}
        />
      )}
    </>
  );
}
