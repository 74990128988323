import { Client } from "./Client";
import { Horizon } from "stellar-sdk";
import EffectRecord = Horizon.ServerApi.EffectRecord;
import CollectionPage = Horizon.ServerApi.CollectionPage;

export type SubscribeRequest = {
  ledgerID: string;
  callback: (effect: EffectRecord) => void;
  cursor?: string;
};

export type SubscribeResponse = {
  unsubscribe: () => void;
};
export type NewStellarAccountEffectsSubscriber = {
  client: Client;
};

export class StellarAccountEffectsSubscriber {
  private readonly stellarClient: Client;

  constructor(props: NewStellarAccountEffectsSubscriber) {
    this.stellarClient = props.client;
  }

  /**
   * Subscribes to account effects for a specific ledgerID.
   *
   * @param request - The subscription request containing the ledger ID, callback, and optional cursor.
   * @returns An object with an `unsubscribe` method to stop the subscription.
   */
  Subscribe(request: SubscribeRequest): SubscribeResponse {
    const { ledgerID, callback, cursor } = request;

    // Handles any errors that occur during the streaming process. We currently just log errors.
    const handleError = (event: MessageEvent) => {
      console.error("Account effects stream error:", event);
    };

    // Handles the effects received from the stream, invoking the callback for each record
    const handleEffect = (message: CollectionPage<EffectRecord>) => {
      if (message.records) {
        // Iterate over each effect record and call the provided callback
        message.records.forEach((record) => {
          callback(record);
        });
      } else {
        // If no records, treat the message as a single EffectRecord
        callback(message as unknown as EffectRecord);
      }
    };

    // Starts streaming account effects from the specified ledger
    // The cursor indicates the point from which to start streaming (defaults to "now" if not provided)
    const stopStream = this.stellarClient.streamEffectsForAccount(
      ledgerID,
      handleEffect,
      handleError,
      cursor ?? "now",
    );

    // Return an object with an `unsubscribe` method to stop the stream
    return {
      unsubscribe: stopStream,
    };
  }
}
