import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import { Token, NewTokenProps } from "./Token";
import BigNumber from "bignumber.js";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";

export type NewAmountArgs = {
  value: BigNumber;
  token: NewTokenProps;
};

export class Amount {
  public value: BigNumber = new BigNumber("0");

  public token: Token = new Token();

  static fromFutureAmount(amount?: FutureAmount): Amount {
    if (!amount) {
      return new Amount();
    }
    return Token.fromFutureToken(amount.getToken()).newAmountOf(
      decimalToBigNumber(amount.getValue() ?? new Decimal()),
    );
  }

  constructor(amount?: NewAmountArgs) {
    if (!amount) {
      return;
    }
    this.value = new BigNumber(amount.value);
    this.token = new Token(amount.token);
  }

  setValue(value: string | BigNumber): Amount {
    return this.token.newAmountOf(value);
  }

  /**
     checks to see if a token is set;
     if the token is not set then the amount is invalid.
     **/
  isUndefined(): boolean {
    return this.token.isUndefined();
  }

  toString(): string {
    return `${this.token.code} ${this.value.toFormat()}`;
  }
}
