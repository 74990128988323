import { useCurrentAPICall, useIsMounted } from "hooks";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DirectOrderSubmitter,
  IssuerSubmitDirectBuyOrderRequest,
} from "james/market/DirectOrderSubmitter";
import {
  DirectOrderFeeCalculator,
  CalculateDirectOrderFeeResponse,
} from "james/remuneration/DirectOrderFeeCalculator";
import {
  DirectOrder,
  DirectOrderState,
  DirectOrderType,
} from "james/market/DirectOrder";
import { TouchedFields, ValidationResult } from "common/validation";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { TextNumField } from "components/FormFields";
import { Amount } from "components/Ledger/Amount";
import cx from "classnames";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { Balance } from "james/stellar/Account";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { formatTextNum } from "utilities/number";
import { Header } from "views/Marketplace/components/PlaceOrderDialog/components/Header";
import {
  Model as PotDirectOrderParticipantViewModel,
  usePotDirectOrderParticipantViewReaderRead,
} from "james/views/potDirectOrderParticipantView";
import {
  TextNEExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { NumFieldHlpTxt, TextFieldHlpTxt } from "validationHelperText";
import { useNotificationContext } from "context/Notification";
import { GroupNotificationChannel } from "james/group";
import {
  MarketDirectOrderViewModelChangedNotification,
  MarketDirectOrderViewModelChangedNotificationTypeName,
  MarketDirectOrderViewNotificationChannelName,
} from "james/views/marketDirectOrderView";
import { Notification } from "james/notification/Notification";
import { QuoteParameter } from "james/market/Mechanism";
import { IssuerDirectOrderCardProps } from "./IssuerDirectOrderCard";
import BigNumber from "bignumber.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import range from "lodash/range";
import LogRocket from "logrocket";
import { AssetEvent } from "const/logRocket";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { userTypingAPICallDebounceIntervalMS } from "common/debouncing";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
const PREFIX = "BuyCard";

const classes = {
  sectionWithPadding: `${PREFIX}-sectionWithPadding`,
  sectionWithColumns2Gap: `${PREFIX}-sectionWithColumns2Gap`,
  textNumFieldCode: `${PREFIX}-textNumFieldCode`,
  balanceLayout: `${PREFIX}-balanceLayout`,
  balanceAvailableText: `${PREFIX}-balanceAvailableText`,
  errorHelperTextLayout: `${PREFIX}-errorHelperTextLayout`,
  tradeFeeWhyTheseFeesLink: `${PREFIX}-tradeFeeWhyTheseFeesLink`,
  orderBreakdownControlRow: `${PREFIX}-orderBreakdownControlRow`,
  orderBreakDownLayout: `${PREFIX}-orderBreakDownLayout`,
  secondaryText: `${PREFIX}-secondaryText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Dialog)(({ theme }) => ({
  [`& .${classes.sectionWithPadding}`]: {
    width: "100%",
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 6),
    },
  },

  [`& .${classes.sectionWithColumns2Gap}`]: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${classes.textNumFieldCode}`]: {
    marginRight: 8,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.balanceLayout}`]: {
    paddingLeft: theme.spacing(1.5),
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.balanceAvailableText}`]: {
    color: theme.palette.text.secondary,
  },

  [`& .${classes.errorHelperTextLayout}`]: {
    maxWidth: 300,
    paddingLeft: theme.spacing(1.5),
  },

  [`& .${classes.tradeFeeWhyTheseFeesLink}`]: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.orderBreakdownControlRow}`]: {
    padding: theme.spacing(3, 0),
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.orderBreakDownLayout}`]: {
    display: "grid",
    paddingBottom: theme.spacing(3),
    gridTemplateColumns: "auto 1fr",
  },

  [`& .${classes.secondaryText}`]: {
    color: theme.palette.text.secondary,
  },
}));

enum LastFieldChanged {
  Neither,
  RequestAmountIncl,
  EnteredTokens,
}

function validateIssuerSubmitDirectBuyOrderRequest(
  request: IssuerSubmitDirectBuyOrderRequest,
  enteredTokens: BigNumber,
  assetValuationTokenBalance: Balance,
  valuationTokenViewModel: LedgerTokenViewModel,
  assetIssuanceTokenViewModel: LedgerTokenViewModel,
  touchedFields: TouchedFields,
  ignoreTouchedFields: boolean,
  marketMechanismQuoteParameter: QuoteParameter,
  assetFractionalisationAllowed: boolean,
): ValidationResult {
  // prepare a validation result
  const validationResult: ValidationResult = {
    // assumed to be true -
    // any error must set to false regardless of touched field state
    valid: true,
    // field validations
    fieldValidations: {},
  };

  // if counterparty not set
  if (request.counterpartyGroupID === "") {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.counterpartyGroupID) {
      // then an error message should be shown on it
      validationResult.fieldValidations.counterpartyGroupID =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  // if price is not set
  if (request.price.value.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.price) {
      // then an error message should be shown on it
      validationResult.fieldValidations.price =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  }

  // if tokens not set
  if (enteredTokens.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.enteredTokens) {
      // then an error message should be shown on it
      validationResult.fieldValidations.enteredTokens =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  } else if (
    // otherwise if resultant token amount is out of
    // the deal size bounds as set on the listing
    enteredTokens.gt(marketMechanismQuoteParameter.maximumDealSize.value) ||
    enteredTokens.lt(marketMechanismQuoteParameter.minimumDealSize.value)
  ) {
    // then validation has failed
    validationResult.valid = false;

    // then REGARDLESS of touched field status an error message should be shown on it
    validationResult.fieldValidations.enteredTokens = `Order size out of bounds. Min ${formatTextNum(
      marketMechanismQuoteParameter.minimumDealSize.value,
      {
        noDecimalPlaces: 7,
        addDecimalPadding: false,
      },
    )} Max ${formatTextNum(
      marketMechanismQuoteParameter.maximumDealSize.value,
      {
        noDecimalPlaces: 7,
        addDecimalPadding: false,
      },
    )} ${assetIssuanceTokenViewModel.token.code}`;
  }

  // if entered amount is not set
  if (request.amountIncl.value.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.amountIncl) {
      // then an error message should be shown on it
      validationResult.fieldValidations.amountIncl =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  } else if (
    assetValuationTokenBalance.amount.value.lt(request.amountIncl.value)
  ) {
    // if valuation token balance is not sufficient
    // then validation has failed
    validationResult.valid = false;

    // then an error message should be shown on it REGARDLESS of touched
    // field state
    validationResult.fieldValidations.amountIncl = `Insufficient Balance: ${
      valuationTokenViewModel.token.code
    } ${formatTextNum(assetValuationTokenBalance.amount.value, {
      noDecimalPlaces: 2,
      addDecimalPadding: true,
    })}`;
  }

  // apply asset fractionalisation allowed check
  if (!(assetFractionalisationAllowed || enteredTokens.isInteger())) {
    // if the check fails then validation has failed
    validationResult.valid = false;

    // and if the tokensField has been touched
    if (ignoreTouchedFields || touchedFields.enteredTokens) {
      // then an error message should be shwon on the tokens text num field
      validationResult.fieldValidations.enteredTokens =
        "Must be a whole number";
    }
  }

  return validationResult;
}

export function BuyCard(props: IssuerDirectOrderCardProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const isMounted = useIsMounted();

  const { authContext, loginClaims } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { registerNotificationCallback } = useNotificationContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    loading: loadingPotentialCounterparties,
    readResponse: { models: potentialCounterParties },
    readRequest: potentialCounterpartiesReadRequest,
    setReadRequest: setPotentialCounterpartiesReadRequest,
  } = usePotDirectOrderParticipantViewReaderRead({
    context: authContext,
    criteria: { id: TextNEExactCriterion(loginClaims.clientID) },
  });

  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [showOrderBreakdown, setShowOrderBreakdown] = useState(false);
  const toggleShowOrderBreakDown = () => {
    // open order breakdown
    setShowOrderBreakdown(!showOrderBreakdown);

    // and scroll to the bottom in 500ms
    range(10, 500, 10).forEach((v) =>
      setTimeout(() => {
        if (dialogContentRef.current) {
          dialogContentRef.current.scrollTop =
            dialogContentRef.current.scrollHeight;
        }
      }, v),
    );
  };
  // ---- request ----
  const [issuerDirectBuyOrderRequest, setIssuerDirectBuyOrderRequest] =
    useState<IssuerSubmitDirectBuyOrderRequest>({
      context: authContext,
      counterpartyGroupID: "",
      buyToken: props.listing.token,
      amountIncl:
        props.marketMechanismQuoteParameter.quoteToken.newAmountOf("0"),
      price: props.indicativePrice.buyPrice.setValue(
        props.indicativePrice.sellPrice.value,
      ),
      tokens: props.listing.token.newAmountOf("0"),
    });
  const [useRequestForCalc, setUseRequestForCalc] = useState(false);
  const [enteredTokens, setEnteredTokens] = useState(new BigNumber("0"));
  const [useTokensForCalc, setUseTokensForCalc] = useState(false);

  // ---- fee calculation (updated each time request changes) ----
  const [lastFieldChanged, setLastFieldChanged] = useState(
    LastFieldChanged.Neither,
  );
  const [calculateDirectOrderFeeResponse, setCalculateDirectOrderFeeResponse] =
    useState<CalculateDirectOrderFeeResponse>({
      feeAmount: new BigNumber("0"),
      vatAmount: new BigNumber("0"),
      amountIncl: new BigNumber("0"),
      amountExcl: new BigNumber("0"),
      tokens: new BigNumber("0"),
    });
  const [feeCalculationInProgress, setFeeCalculationInProgress] =
    useState(false);
  const feeCalculationTimeoutRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const [
    isCurrentCalculateDirectOrderFeeAPICall,
    initCalculateDirectOrderFeeAPICall,
  ] = useCurrentAPICall();
  useEffect(() => {
    if (!isMounted()) {
      return;
    }

    // only proceed if either request or tokens should be used for calc
    if (!(useRequestForCalc || useTokensForCalc)) {
      return;
    }

    if (useRequestForCalc) {
      setUseRequestForCalc(false);

      // only perform fee calculation if a non-zero amountIncl and price is entered
      if (
        issuerDirectBuyOrderRequest.amountIncl.value.isZero() ||
        issuerDirectBuyOrderRequest.price.value.isZero()
      ) {
        setCalculateDirectOrderFeeResponse({
          feeAmount: new BigNumber("0"),
          vatAmount: new BigNumber("0"),
          amountIncl: new BigNumber("0"),
          amountExcl: new BigNumber("0"),
          tokens: new BigNumber("0"),
        });
        return;
      }
    }

    if (useTokensForCalc) {
      setUseTokensForCalc(false);

      // only perform fee calculation if a non-zero value of tokens and price is entered
      if (
        enteredTokens.isZero() ||
        issuerDirectBuyOrderRequest.price.value.isZero()
      ) {
        setCalculateDirectOrderFeeResponse({
          feeAmount: new BigNumber("0"),
          vatAmount: new BigNumber("0"),
          amountIncl: new BigNumber("0"),
          amountExcl: new BigNumber("0"),
          tokens: new BigNumber("0"),
        });
        return;
      }
    }

    // indicate that fee calculation is in progress
    setFeeCalculationInProgress(true);

    // initialise API call
    const { apiCallID, abortController } = initCalculateDirectOrderFeeAPICall();

    // set up new fee calculation to take place after debounce interval
    clearTimeout(feeCalculationTimeoutRef.current);
    feeCalculationTimeoutRef.current = setTimeout(async () => {
      try {
        // if request should be used for calc...
        if (useRequestForCalc) {
          // then use
          const result = await DirectOrderFeeCalculator.CalculateDirectOrderFee(
            {
              context: authContext,
              directOrderType: DirectOrderType.Buy,
              price: issuerDirectBuyOrderRequest.price.value, // price from request
              amountIncl: issuerDirectBuyOrderRequest.amountIncl.value, // amountIncl from request
            },
            { signal: abortController.signal },
          );
          if (
            isMounted() &&
            isCurrentCalculateDirectOrderFeeAPICall(apiCallID)
          ) {
            // update from result
            setIssuerDirectBuyOrderRequest({
              ...issuerDirectBuyOrderRequest,
              amountIncl: issuerDirectBuyOrderRequest.amountIncl.setValue(
                result.amountIncl,
              ),
              price: issuerDirectBuyOrderRequest.price,
            });
            setEnteredTokens(
              props.listing.token.newAmountOf(result.tokens).value,
            );
            setCalculateDirectOrderFeeResponse(result);
          } else {
            return;
          }
        }

        // if tokens should be used for calc...
        if (useTokensForCalc) {
          // then use
          const result = await DirectOrderFeeCalculator.CalculateDirectOrderFee(
            {
              context: authContext,
              directOrderType: DirectOrderType.Buy,
              price: issuerDirectBuyOrderRequest.price.value, // only price from request
              tokens: enteredTokens, // tokens as entered
            },
            { signal: abortController.signal },
          );
          if (
            isMounted() &&
            isCurrentCalculateDirectOrderFeeAPICall(apiCallID)
          ) {
            // update from result
            setIssuerDirectBuyOrderRequest({
              ...issuerDirectBuyOrderRequest,
              amountIncl: issuerDirectBuyOrderRequest.amountIncl.setValue(
                result.amountIncl,
              ),
              price: issuerDirectBuyOrderRequest.price,
              tokens:
                props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  result.tokens,
                ),
            });
            setEnteredTokens(
              props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                result.tokens,
              ).value,
            );
            setCalculateDirectOrderFeeResponse(result);
          } else {
            return;
          }
        }
      } catch (e) {
        if (
          errorContextErrorTranslator.translateError(e).code ===
          JSONRPCCallAbortedError.ErrorCode
        ) {
          return;
        }
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error calculating direct order fee: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setFeeCalculationInProgress(false);
    }, userTypingAPICallDebounceIntervalMS);
  }, [
    useTokensForCalc,
    useRequestForCalc,
    enteredTokens,
    isMounted,
    authContext,
    issuerDirectBuyOrderRequest,
    props.marketMechanismQuoteParameter,
    props.marketMechanismQuoteParameter.quoteToken,
  ]);

  // ----- Request update and validation -----
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [requestValidationResult, setRequestValidationResult] =
    useState<ValidationResult>({
      valid: false,
      fieldValidations: {},
    });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  type IssuerSubmitDirectBuyOrderRequestField =
    keyof IssuerSubmitDirectBuyOrderRequest;
  type IssuerSubmitDirectBuyOrderRequestValue<
    T extends IssuerSubmitDirectBuyOrderRequestField,
  > = IssuerSubmitDirectBuyOrderRequest[T];
  const handleUpdateRequest =
    (field: string, fieldsAffected?: string[]) =>
    <T extends IssuerSubmitDirectBuyOrderRequestField>(
      newValue: IssuerSubmitDirectBuyOrderRequestValue<T>,
    ) => {
      // prepare updated request
      const updatedRequest = {
        ...issuerDirectBuyOrderRequest,
        [field]: newValue,
      };

      // prepare updated touched fields
      const updatedTouchedFields = {
        ...touchedFields,
        [field]: true,
      };
      if (fieldsAffected) {
        fieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      // determine what field should be used next for calculation
      switch (field) {
        case "counterparty":
          // do not use either request nor tokens for calc
          // regardless of which was the last field that was changed
          setUseTokensForCalc(false);
          setUseRequestForCalc(false);
          break;

        case "price":
          // depending on last field changed,
          // set what should be used for calc
          switch (lastFieldChanged) {
            case LastFieldChanged.EnteredTokens:
              setUseTokensForCalc(true);
              break;

            case LastFieldChanged.RequestAmountIncl:
            case LastFieldChanged.Neither:
            default:
              setUseRequestForCalc(true);
          }
          break;

        case "amountIncl":
          // use request for calc
          setUseRequestForCalc(true);
          // and update the field that was changed
          setLastFieldChanged(LastFieldChanged.RequestAmountIncl);
          break;
      }

      setTouchedFields(updatedTouchedFields);
      setIssuerDirectBuyOrderRequest(updatedRequest);
    };

  // perform request validation as required
  useEffect(() => {
    // clear any pending validation
    clearTimeout(validationTimeoutRef.current);

    // defer validation to take place in 200ms
    setValidationInProgress(true);
    clearTimeout(validationTimeoutRef.current);
    validationTimeoutRef.current = setTimeout(() => {
      setRequestValidationResult(
        validateIssuerSubmitDirectBuyOrderRequest(
          issuerDirectBuyOrderRequest,
          enteredTokens,
          props.assetValuationTokenBalance,
          props.assetValuationTokenViewModel,
          props.assetIssuanceTokenViewModel,
          touchedFields,
          false,
          props.marketMechanismQuoteParameter,
          props.marketListingViewModel.assetFractionalisationAllowed,
        ),
      );
      setValidationInProgress(false);
    }, 200);
  }, [
    issuerDirectBuyOrderRequest,
    props.listing,
    enteredTokens,
    props.assetValuationTokenBalance,
    props.assetValuationTokenViewModel,
    props.assetIssuanceTokenViewModel,
    touchedFields,
  ]);

  // order submission
  const [orderSubmissionInProgress, setOrderSubmissionInProgress] =
    useState(false);
  const handleSubmitOrder = async () => {
    setOrderSubmissionInProgress(true);
    let directOrder: DirectOrder;
    try {
      // submit direct order
      directOrder = (
        await DirectOrderSubmitter.IssuerSubmitDirectBuyOrder(
          issuerDirectBuyOrderRequest,
        )
      ).directOrder;

      // notify that submission is in progress
      enqueueSnackbar(`Order #${directOrder.number} is being submitted`, {
        variant: "info",
      });

      LogRocket.track(AssetEvent.placeDirectOrder, {
        assetName: props.marketListingViewModel.assetName,
        assetShortName: props.marketListingViewModel.assetShortName,
        assetType: props.marketListingViewModel.assetType,
        revenue: calculateDirectOrderFeeResponse.feeAmount.toNumber(),
      });

      // close the dialog
      props.closeDialog();
    } catch (e) {
      console.error(`submitting order`, e);
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Submitting Order: ${err.message}`, {
        variant: "warning",
      });
      setOrderSubmissionInProgress(false);
      return;
    }

    try {
      // register callback to fire once the order has reached awaiting confirmation
      const deregister = await registerNotificationCallback(
        new GroupNotificationChannel({
          groupID: directOrder.initiatingPartyGroupID,
          name: MarketDirectOrderViewNotificationChannelName,
          private: true,
        }),
        [MarketDirectOrderViewModelChangedNotificationTypeName],
        (n: Notification) => {
          if (
            n instanceof MarketDirectOrderViewModelChangedNotification &&
            n.model.directOrderID === directOrder.id
          ) {
            // notify based on state
            switch (n.model.state) {
              case DirectOrderState.Pending:
                // Do nothing during transient states
                // Return so that deregister is not called.
                return;

              case DirectOrderState.AwaitingConfirmation:
                enqueueSnackbar(
                  `Order #${n.model.number} is awaiting confirmation`,
                  { variant: "success" },
                );
                break;

              case DirectOrderState.Failed:
                enqueueSnackbar(`Order #${n.model.number} has failed`, {
                  variant: "error",
                });
                break;

              case DirectOrderState.UnderInvestigation:
                enqueueSnackbar(
                  `Something has gone wrong with Order #${n.model.number} - it's status is being investigated`,
                  { variant: "warning" },
                );
                break;
            }
            deregister();
          }
        },
      );
    } catch (e) {
      console.error("error registring for order transaction notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Notifications on Order - Please Check the Orders Table and Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  const handleMaxButtonClick = useCallback(async () => {
    if (props.assetValuationTokenBalance.amount.value.isZero()) {
      return;
    }
    if (props.marketListingViewModel.assetFractionalisationAllowed) {
      setLastFieldChanged(LastFieldChanged.RequestAmountIncl);
      handleUpdateRequest("amountIncl")(
        issuerDirectBuyOrderRequest.amountIncl.setValue(
          props.assetValuationTokenBalance.availableBalance().value,
        ),
      );
      return;
    }
    setFeeCalculationInProgress(true);
    try {
      const { abortController } = initCalculateDirectOrderFeeAPICall();
      const result = await DirectOrderFeeCalculator.CalculateDirectOrderFee(
        {
          context: authContext,
          directOrderType: DirectOrderType.Buy,
          price: props.indicativePrice.buyPrice.value,
          amountIncl: props.assetValuationTokenBalance.availableBalance().value,
          tokens: undefined,
        },
        { signal: abortController.signal },
      );
      setTouchedFields((prevValue) => ({
        ...prevValue,
        tokens: true,
        amountIncl: true,
      }));
      setLastFieldChanged(LastFieldChanged.EnteredTokens);
      setEnteredTokens(result.tokens.integerValue(BigNumber.ROUND_FLOOR));
      setUseTokensForCalc(true);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      if (
        errorContextErrorTranslator.translateError(e).code ===
        JSONRPCCallAbortedError.ErrorCode
      ) {
        return;
      }
      console.error(
        `error calculating direct order fee: ${
          err.message ? err.message : err.toString()
        }`,
      );
    }
    setFeeCalculationInProgress(false);
  }, [
    props.assetValuationTokenBalance,
    props.indicativePrice.buyPrice.value,
    authContext,
    props.marketListingViewModel.assetFractionalisationAllowed,
  ]);

  const placeOrderButton = (
    <Tooltip
      placement={isMobile ? "top" : "bottom"}
      arrow
      title={(() => {
        switch (true) {
          case feeCalculationInProgress: {
            return "Calculation in Progress";
          }
          case validationInProgress: {
            return "Validation in Progress";
          }
          case !requestValidationResult.valid: {
            const specialMsg = requestValidationResult.fieldValidations
              .enteredTokens
              ? requestValidationResult.fieldValidations.enteredTokens.includes(
                  "Order size out of bounds.",
                )
                ? requestValidationResult.fieldValidations.enteredTokens
                : ""
              : "";
            return (
              specialMsg ||
              "Please ensure that all fields have been completed correctly"
            );
          }
          case !props.userIsSignatoryOnTradingAcc: {
            return "You are not a Signatory on the Trading Account";
          }
        }
        return "";
      })()}
    >
      <span>
        <Button
          sx={{
            height: {
              xs: "48px",
              sm: "33px",
            },
          }}
          data-link-info={JSON.stringify({
            content_interaction_id: "transact-cta",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "place order",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.TransactionComplete,
          } as DataLinkInfoType)}
          fullWidth
          color="primary"
          variant="contained"
          children="place order"
          disabled={
            feeCalculationInProgress ||
            validationInProgress ||
            !requestValidationResult.valid ||
            orderSubmissionInProgress ||
            !props.userIsSignatoryOnTradingAcc
          }
          onClick={handleSubmitOrder}
        />
      </span>
    </Tooltip>
  );

  const decimalPlaces =
    props.listing.token.newAmountOf(calculateDirectOrderFeeResponse.tokens)
      .value > props.listing.token.newAmountOf("1000000").value
      ? 2
      : 7;

  return (
    <Root open fullScreen={isMobile}>
      <Header
        {...{
          disableClose: orderSubmissionInProgress,
          showLoading: orderSubmissionInProgress,
          ...props,
        }}
      />
      <DialogContent
        ref={dialogContentRef}
        sx={{
          backgroundColor: theme.palette.custom.midnight,
          width: { sm: 420 },
          display: "flex",
          flexDirection: "column",
          alignItems: { sm: "center" },
          padding: "unset !important",
        }}
        className={isMobile ? undefined : "meshScroll"}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.custom.cardInner,
            display: "flex",
            flexDirection: "column",
            gap: {
              sm: theme.spacing(3),
              xs: theme.spacing(3),
            },
            padding: {
              sm: theme.spacing(3, 6),
              xs: theme.spacing(3, 3),
            },
          }}
        >
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="h4"
            children="Who would you like to buy the instrument from?"
          />
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            disabled={orderSubmissionInProgress}
            getOptionLabel={(option: PotDirectOrderParticipantViewModel) =>
              option.name
            }
            options={potentialCounterParties}
            loading={loadingPotentialCounterparties}
            onChange={(
              _,
              selectedModel: PotDirectOrderParticipantViewModel | null,
            ) =>
              handleUpdateRequest("counterpartyGroupID")(
                selectedModel ? selectedModel.groupID : "",
              )
            }
            value={(() => {
              const selectedModel = potentialCounterParties.find(
                (cp) =>
                  cp.groupID ===
                  issuerDirectBuyOrderRequest.counterpartyGroupID,
              );
              return selectedModel || null;
            })()}
            onClose={() =>
              setPotentialCounterpartiesReadRequest({
                ...potentialCounterpartiesReadRequest,
                criteria: {
                  id: TextNEExactCriterion(loginClaims.clientID),
                },
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Counterparty"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setPotentialCounterpartiesReadRequest({
                      ...potentialCounterpartiesReadRequest,
                      criteria: {
                        id: TextNEExactCriterion(loginClaims.clientID),
                      },
                    });
                  } else {
                    setPotentialCounterpartiesReadRequest({
                      ...potentialCounterpartiesReadRequest,
                      criteria: {
                        ...potentialCounterpartiesReadRequest.criteria,
                        name: TextSubstringCriterion(e.target.value),
                      },
                    });
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Select/Start Typing...",
                  endAdornment: (
                    <>
                      {loadingPotentialCounterparties ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                error={
                  !!requestValidationResult.fieldValidations.counterpartyGroupID
                }
                helperText={
                  requestValidationResult.fieldValidations.counterpartyGroupID
                }
              />
            )}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: {
              sm: theme.spacing(3),
              xs: theme.spacing(1.5),
            },
            padding: {
              sm: theme.spacing(3, 6),
              xs: theme.spacing(3, 3),
            },
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography
            variant="h6"
            children="How much of the instrument would you like to buy?"
          />
          <TextNumField
            id="marketDirectOrderIssuerBuyCard-tokens-textNumField"
            disallowNegative
            label="Tokens"
            noDecimalPlaces={7}
            fullWidth
            disabled={orderSubmissionInProgress}
            value={enteredTokens}
            onChange={(e) => {
              setLastFieldChanged(LastFieldChanged.EnteredTokens);
              setTouchedFields({
                ...touchedFields,
                enteredTokens: true,
              });
              setEnteredTokens(new BigNumber(e.target.value));
              setUseTokensForCalc(true);
            }}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={`Issued by ${props.assetIssuanceTokenViewModel.issuer}`}
                  placement="top"
                >
                  <Typography
                    id={
                      "marketDirectOrderIssuerBuyCard-tokensTextNumField-unitsEndAdornment"
                    }
                    variant="body1"
                    className={classes.textNumFieldCode}
                    children={props.assetIssuanceTokenViewModel.token.code}
                  />
                </Tooltip>
              ),
            }}
            error={
              requestValidationResult.fieldValidations.enteredTokens
                ? !requestValidationResult.fieldValidations.enteredTokens.includes(
                    "Order size out of bounds.",
                  )
                : false
            }
            helperText={
              requestValidationResult.fieldValidations.enteredTokens
                ? requestValidationResult.fieldValidations.enteredTokens.includes(
                    "Order size out of bounds.",
                  )
                  ? undefined
                  : requestValidationResult.fieldValidations.enteredTokens
                : undefined
            }
          />
          <TextNumField
            id="marketDirectOrderIssuerBuyCard-price-textNumField"
            disallowNegative
            label="Price"
            noDecimalPlaces={2}
            fullWidth
            disabled={orderSubmissionInProgress}
            value={issuerDirectBuyOrderRequest.price.value}
            onChange={(e) =>
              handleUpdateRequest("price")(
                issuerDirectBuyOrderRequest.price.setValue(e.target.value),
              )
            }
            InputProps={{
              startAdornment: (
                <Tooltip
                  title={`Issued by ${props.assetValuationTokenViewModel.issuer}`}
                  placement="top"
                >
                  <Typography
                    id={
                      "marketDirectOrderIssuerBuyCard-priceTextNumField-unitsStartAdornment"
                    }
                    variant="body1"
                    className={classes.textNumFieldCode}
                    children={props.assetValuationTokenViewModel.token.code}
                  />
                </Tooltip>
              ),
            }}
            error={!!requestValidationResult.fieldValidations.price}
            helperText={requestValidationResult.fieldValidations.price}
          />
          <div>
            <TextNumField
              id="marketDirectOrderIssuerBuyCard-amountIncl-textNumField"
              disallowNegative
              label="Amount"
              noDecimalPlaces={2}
              fullWidth
              disabled={orderSubmissionInProgress}
              value={issuerDirectBuyOrderRequest.amountIncl.value}
              onChange={(e) =>
                handleUpdateRequest("amountIncl")(
                  issuerDirectBuyOrderRequest.amountIncl.setValue(
                    e.target.value,
                  ),
                )
              }
              InputProps={{
                startAdornment: (
                  <Tooltip
                    title={`Issued by ${props.assetValuationTokenViewModel.issuer}`}
                    placement="top"
                  >
                    <Typography
                      id={
                        "marketDirectOrderIssuerBuyCard-amountTextNumField-unitsStartAdornment"
                      }
                      variant="body1"
                      className={classes.textNumFieldCode}
                      children={props.assetValuationTokenViewModel.token.code}
                    />
                  </Tooltip>
                ),
                endAdornment: (
                  <InputAdornment
                    position={"end"}
                    sx={{ alignItems: "center" }}
                  >
                    <Button
                      id={
                        "marketDirectOrderIssuerDirectOrder-buyCard-paySetMax-button"
                      }
                      sx={{
                        width: "39px !important",
                        minWidth: 0,
                        padding: theme.spacing(0, 0.5),
                        marginBottom: "-3px",
                      }}
                      variant={"text"}
                      color={"secondary"}
                      children={"max"}
                      onClick={handleMaxButtonClick}
                    />
                  </InputAdornment>
                ),
              }}
              error={!!requestValidationResult.fieldValidations.amountIncl}
            />
            {!requestValidationResult.fieldValidations.amountIncl ? (
              // if there are no field validations to show then available balance
              <div className={classes.balanceLayout}>
                <Typography
                  className={classes.balanceAvailableText}
                  variant="caption"
                  children="Available:"
                />
                <Amount
                  codeTypographyProps={{
                    variant: "caption",
                    color: "textSecondary",
                  }}
                  valueTypographyProps={{
                    variant: "caption",
                    color: "textSecondary",
                  }}
                  formatTextNumOpts={{
                    noDecimalPlaces: 2,
                    addDecimalPadding: true,
                  }}
                  amount={props.assetValuationTokenBalance.availableBalance()}
                />
              </div>
            ) : (
              // otherwise show error text
              <Typography
                className={classes.errorHelperTextLayout}
                variant="body2"
                color="error"
                children={requestValidationResult.fieldValidations.amountIncl}
              />
            )}
          </div>

          <div className={classes.sectionWithColumns2Gap}>
            <Typography
              variant="caption"
              className={classes.secondaryText}
              children="Total Fee:"
            />
            <Amount
              codeTypographyProps={{
                variant: "caption",
                color: "textSecondary",
                className: cx({
                  [classes.secondaryText]: feeCalculationInProgress,
                }),
              }}
              valueTypographyProps={{
                variant: "caption",
                color: "textSecondary",
                className: cx({
                  [classes.secondaryText]: feeCalculationInProgress,
                }),
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 2,
                addDecimalPadding: true,
              }}
              amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                calculateDirectOrderFeeResponse.feeAmount.plus(
                  calculateDirectOrderFeeResponse.vatAmount,
                ),
              )}
            />
            <Typography
              variant="caption"
              color="secondary"
              className={classes.tradeFeeWhyTheseFeesLink}
              onClick={() => window.open("https://mesh.trade/fees", "_blank")}
              children="Why these fees?"
            />
          </div>
        </Box>

        <div className={cx(classes.sectionWithPadding)}>
          <div className={classes.orderBreakdownControlRow}>
            <IconButton size="small" onClick={toggleShowOrderBreakDown}>
              {showOrderBreakdown ? (
                <ExpandLessIcon color="primary" />
              ) : (
                <ExpandMoreIcon color="primary" />
              )}
            </IconButton>
            <Typography variant="h6" children="Order Breakdown" />
          </div>
          <Collapse in={showOrderBreakdown}>
            <div className={classes.orderBreakDownLayout}>
              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="Pay Stablecoin:"
              />
              <Amount
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.amountIncl,
                )}
              />

              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="Trade Fee:"
              />
              <Amount
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.feeAmount,
                )}
              />

              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="VAT on Fee:"
              />
              <Amount
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.vatAmount,
                )}
              />

              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="Receive Tokens:"
              />
              <Amount
                reverse
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: decimalPlaces,
                  addDecimalPadding: false,
                }}
                amount={props.listing.token.newAmountOf(
                  calculateDirectOrderFeeResponse.tokens,
                )}
              />
            </div>
          </Collapse>
          {!isMobile && (
            <Box
              sx={{
                width: "100%",
                paddingBottom: 5,
              }}
            >
              {placeOrderButton}
            </Box>
          )}
        </div>
        {isMobile && (
          <Box
            sx={{
              marginTop: "auto",
              position: "sticky",
              bottom: 0,
              backgroundColor: theme.palette.custom.midnight,
              padding: theme.spacing(3, 3, 4, 3),
              height: "104px",
              width: "100%",
              boxShadow: 24,
            }}
            data-link-info={JSON.stringify({
              content_interaction_id: "transact-cta",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "place order",
              content_interaction_type: InteractionType.Button,
              content_interaction_driver: InteractionDriver.TransactionComplete,
            } as DataLinkInfoType)}
          >
            {placeOrderButton}
          </Box>
        )}
      </DialogContent>
    </Root>
  );
}
