import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Router } from "routes";
import { RouteType } from "routes/Route";
import { NewInstrumentDialog } from "./NewInstrumentDialog";
import { Table } from "./Table";
import { PlaceDigitalInstrumentDialog } from "./PlaceDigitalInstrumentDialog";
import { PlaceInstrumentStablecoinDialog } from "./PlaceInstrumentStablecoinDialog";
import { DigitalETFDialog } from "./DigitalETFDialog";
import { ETFDialog } from "./ETFDialog";
import { UnitTrustDialog } from "./UnitTrustDialog";
import { DigitalETNDialog } from "./DigitalETNDialog";
import { ETNDialog } from "./ETNDialog";
import { useApplicationContext } from "context/Application/Application";
import { SecuritiesRegister } from "components/Ledger/SecuritiesRegister";

const PREFIX = "Instruments";

const classes = {
  root: `${PREFIX}-root`,
  rootContent: `${PREFIX}-rootContent`,
  tabBar: `${PREFIX}-tabBar`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.rootContent}`]: {
    padding: theme.spacing(0, 2, 2, 2),
  },

  [`& .${classes.tabBar}`]: {
    padding: theme.spacing(1, 2),
    minHeight: 48,
    display: "grid",
    gridTemplateColumns: "1fr auto",
  },
}));

export enum InstrumentsViewPaths {
  Table = "/builder/instruments",
  NewInstrument = "/builder/instruments/new",
  DigitalETF = "/builder/instruments/etf",
  RightsToETF = "/builder/instruments/rightsToETF",
  DigitalETN = "/builder/instruments/etn",
  RightsToETN = "/builder/instruments/rightsToETN",
  RightsToUnitTrust = "/builder/instruments/rightsToUnitTrust",

  PlaceDigitalInstrument = "/builder/instruments/place/instrument",
  PlaceInstrumentStablecoin = "/builder/instruments/place/rights-to-instrument",
  SecuritiesRegister = "/builder/instruments/securities-register",
}

const routes: RouteType[] = [
  {
    name: "Instruments",
    id: "",
    path: InstrumentsViewPaths.Table,
    component: <Table />,
  },
  {
    name: "New Instrument",
    id: "",
    path: InstrumentsViewPaths.NewInstrument,
    component: <NewInstrumentDialog />,
  },

  //
  // Digital ETF
  //
  {
    name: "Digital ETF",
    id: "",
    path: InstrumentsViewPaths.DigitalETF,
    component: <DigitalETFDialog />,
  },

  //
  // Rights to ETF
  //
  {
    name: "Rights to ETF",
    id: "",
    path: InstrumentsViewPaths.RightsToETF,
    component: <ETFDialog />,
  },

  //
  // Digital ETN
  //
  {
    name: "Digital ETN",
    id: "",
    path: InstrumentsViewPaths.DigitalETN,
    component: <DigitalETNDialog />,
  },

  //
  // Rights to ETN
  //
  {
    name: "Rights to ETN",
    id: "",
    path: InstrumentsViewPaths.RightsToETN,
    component: <ETNDialog />,
  },

  //
  // Rights to UnitTrust
  //
  {
    name: "Rights to Unit Trust",
    id: "",
    path: InstrumentsViewPaths.RightsToUnitTrust,
    component: <UnitTrustDialog />,
  },

  //
  // Placement
  //
  {
    name: "Place Digital Instrument",
    id: "",
    path: InstrumentsViewPaths.PlaceDigitalInstrument,
    component: <PlaceDigitalInstrumentDialog />,
  },
  {
    name: "Place Instrument Stablecoin",
    id: "",
    path: InstrumentsViewPaths.PlaceInstrumentStablecoin,
    component: <PlaceInstrumentStablecoinDialog />,
  },
  //
  // Securities Resgister
  //
  {
    name: "Securities Register",
    id: "",
    path: InstrumentsViewPaths.SecuritiesRegister,
    component: <SecuritiesRegister />,
  },
];

export const Instruments = () => {
  const { viewConfiguration } = useApplicationContext();

  const navigate = useNavigate();

  // access necessary view permissions
  const instrumentsViewConfig = viewConfiguration.Instruments
    ? viewConfiguration.Instruments
    : {};
  const instrumentActionsViewConfig = instrumentsViewConfig.InstrumentActions
    ? instrumentsViewConfig.InstrumentActions
    : {};

  return (
    <Root className={classes.root}>
      <Paper square className={classes.tabBar}>
        <div />
        {instrumentActionsViewConfig.Create && (
          <Button
            variant="contained"
            color="primary"
            id="instrumentsView-buildNew-button"
            onClick={() => navigate(InstrumentsViewPaths.NewInstrument)}
            children="build new"
          />
        )}
      </Paper>
      <div className={classes.rootContent}>
        <Router baseURL={"/builder/instruments"} routes={routes} />
      </div>
    </Root>
  );
};
