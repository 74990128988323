import { Asset } from "stellar-base";
import { Horizon } from "stellar-sdk";
import EffectRecord = Horizon.ServerApi.EffectRecord;
import CollectionPage = Horizon.ServerApi.CollectionPage;

export interface TradeAggregationRecord
  extends Horizon.HorizonApi.BaseResponse {
  timestamp: number | string;
  trade_count: number | string;
  base_volume: string;
  counter_volume: string;
  avg: string;
  high: string;
  low: string;
  open: string;
  close: string;
}

export type PaginationOpts = {
  cursor?: string;
  limit?: number;
  order?: "asc" | "desc";
};

export interface Client {
  readonly network: string;

  strictSendPaths(
    sourceAsset: Asset,
    sourceAmount: string,
    destination: string | Asset[],
  ): Promise<
    Horizon.ServerApi.CollectionPage<Horizon.ServerApi.PaymentPathRecord>
  >;

  strictReceivePaths(
    source: string | Asset[],
    destinationAsset: Asset,
    destinationAmount: string,
  ): Promise<
    Horizon.ServerApi.CollectionPage<Horizon.ServerApi.PaymentPathRecord>
  >;

  tradeAggregations(
    base: Asset,
    counter: Asset,
    start_time: number,
    end_time: number,
    resolution: number,
    offset: number,
    paginationOpts?: PaginationOpts,
  ): Promise<Horizon.ServerApi.CollectionPage<TradeAggregationRecord>>;

  accountRecord(id: string): Promise<Horizon.ServerApi.AccountRecord>;

  liquidityPoolRecord(
    id: string,
  ): Promise<Horizon.ServerApi.LiquidityPoolRecord>;

  operationsForAccount(
    id: string,
    paginationOpts?: PaginationOpts,
  ): Promise<
    Horizon.ServerApi.CollectionPage<Horizon.ServerApi.OperationRecord>
  >;

  streamEffectsForAccount(
    id: string,
    onMessage: (message: CollectionPage<EffectRecord>) => void,
    onError: (event: MessageEvent) => void,
    cursor: string,
  ): () => void;
}
