import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { TransactionBatch } from "./TransactionBatch";
import { useEffect, useRef, useState } from "react";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";

export interface RetrieveTransactionBatchRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveTransactionBatchResponse {
  transactionBatch: TransactionBatch;
}

export interface SearchTransactionBatchesRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchTransactionBatchesResponse {
  records: TransactionBatch[];
  total: number;
}

export const TransactionBatchRepository = {
  serviceProvider: "ledger-TransactionBatchRepository",
  async RetrieveTransactionBatch(
    request: RetrieveTransactionBatchRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RetrieveTransactionBatchResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${TransactionBatchRepository.serviceProvider}.RetrieveTransactionBatch`,
        request,
      },
      opts,
    );
    return {
      transactionBatch: new TransactionBatch(response.transactionBatch),
    };
  },
  async SearchTransactionBatches(
    request: SearchTransactionBatchesRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SearchTransactionBatchesResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${TransactionBatchRepository.serviceProvider}.SearchTransactionBatches`,
        request,
      },
      opts,
    );
    return {
      records: response.records.map(
        (c: TransactionBatch) => new TransactionBatch(c),
      ),
      total: response.total,
    };
  },
};

export function useSearchTransactionBatches(
  initialSearchTransactionBatchRequest?: SearchTransactionBatchesRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [searchTransactionBatchesRequest, setSearchTransactionBatchesRequest] =
    useState<SearchTransactionBatchesRequest>(
      initialSearchTransactionBatchRequest || {
        context: authContext,
        query: new Query(),
        criteria: {},
      },
    );
  const [
    searchTransactionBatchesResponse,
    setSearchTransactionBatchesResponse,
  ] = useState<SearchTransactionBatchesResponse>({
    total: 0,
    records: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setSearchTransactionBatchesResponse(
          await TransactionBatchRepository.SearchTransactionBatches(
            searchTransactionBatchesRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for transaction batches: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for transaction batches: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchTransactionBatchesRequest, shouldNotExecute]);

  return {
    searchTransactionBatchesRequest,
    setSearchTransactionBatchesRequest,
    searchTransactionBatchesResponse,
    loading,
    error,
  };
}
