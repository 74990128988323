import camelCase from "lodash/camelCase";
import debounce from "lodash/debounce";
import { styled } from "@mui/material/styles";
import React, { ChangeEvent } from "react";

import { TextField, Typography } from "@mui/material";
import { ConnectedIndividual } from "james/legal";
import { Address } from "james/location";
import { countries } from "james/country";
import { CountryDropdown } from "components/FormFields/CountryDropdown";

const PREFIX = "RegisteredAddress";

const classes = {
  titleLayout: `${PREFIX}-titleLayout`,
  equalColumns2Gap: `${PREFIX}-equalColumns2Gap`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.titleLayout}`]: {
    height: 35,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },

  [`& .${classes.equalColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(2),
  },
}));

interface RegisteredAddressProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeValue: (name: string) => (value: any) => void;
  regAddress: Address | undefined;
  FormFieldValid: { [key: string]: string | undefined };
  setFormFieldValid: (value: { [key: string]: string | undefined }) => void;
}

export const RegisteredAddress: React.FC<RegisteredAddressProps> = (
  props: RegisteredAddressProps,
) => {
  const { regAddress, onChangeValue, FormFieldValid, setFormFieldValid } =
    props;

  const InlineFormValidation = (field: string) =>
    debounce(() => {
      const InvalidFormField = {
        ...FormFieldValid,
        [field]: undefined,
      };
      setFormFieldValid(InvalidFormField);
    }, 600);

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    updateState(name, event.target.value);
  };

  const autoCompleteOnUpdate =
    (name: string) =>
    (
      __: React.SyntheticEvent<Element, Event>,
      countryOption: { value: string; label: string } | null,
    ) => {
      updateState(name, countryOption ? countryOption.value : "");
    };

  const updateState = (name: string, value: string) => {
    const keys = name.split(".") as (keyof ConnectedIndividual)[];
    const newAddress = new Address({
      ...regAddress,
      [keys[1]]: value,
    } as Address);
    onChangeValue("registeredAddress")(newAddress);
    InlineFormValidation(camelCase(name))();
  };

  return (
    <Root>
      <div className={classes.titleLayout}>
        <Typography variant="h6" children="Registered Address" />
      </div>
      {regAddress && (
        <>
          <CountryDropdown
            id="contactDetails-registeredAddress-country"
            isOptionEqualToValue={(option, value) => {
              if (value.value === "") {
                return true;
              }
              return option === value;
            }}
            onChange={autoCompleteOnUpdate("registeredAddress.countryCode")}
            value={
              regAddress.countryCode === ""
                ? {
                    value: "",
                    label: "",
                  }
                : {
                    value: regAddress.countryCode,
                    label: countries.filter(
                      (c) => c.value === new Address(regAddress).countryCode,
                    )[0].label,
                  }
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Country"
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Select...",
                }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                error={Boolean(FormFieldValid.registeredAddressCountryCode)}
                fullWidth
                helperText={FormFieldValid.registeredAddressCountryCode}
              />
            )}
          />
          <TextField
            id="contactDetails-registeredAddress-line1"
            margin="dense"
            variant="outlined"
            label="Address 1"
            fullWidth
            onChange={onChange("registeredAddress.addressLine1")}
            value={regAddress.addressLine1}
            error={Boolean(FormFieldValid.registeredAddressAddressLine1)}
            helperText={FormFieldValid.registeredAddressAddressLine1}
          />
          <TextField
            margin="dense"
            variant="outlined"
            label="Address 2"
            id="contactDetails-registeredAddress-line2"
            fullWidth
            placeholder="Optional"
            onChange={onChange("registeredAddress.addressLine2")}
            value={regAddress.addressLine2}
            helperText={FormFieldValid.registeredAddressAddressLine2}
          />
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="contactDetails-registeredAddress-city"
              margin="dense"
              variant="outlined"
              label="City"
              fullWidth
              onChange={onChange("registeredAddress.city")}
              value={regAddress.city}
              error={Boolean(FormFieldValid.registeredAddressCity)}
              helperText={FormFieldValid.registeredAddressCity}
            />
            <TextField
              id="contactDetails-registeredAddress-suburb"
              margin="dense"
              variant="outlined"
              label="Suburb"
              fullWidth
              placeholder="Optional"
              onChange={onChange("registeredAddress.suburb")}
              value={regAddress.suburb}
              helperText={FormFieldValid.registeredAddressSuburb}
            />
          </div>
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="contactDetails-registeredAddress-province"
              margin="dense"
              variant="outlined"
              label="Province"
              fullWidth
              onChange={onChange("registeredAddress.province")}
              value={regAddress.province}
              placeholder="Optional"
              helperText={FormFieldValid.registeredAddressProvince}
            />
            <TextField
              id="contactDetails-registeredAddress-postalCode"
              margin="dense"
              variant="outlined"
              label="Postal Code"
              fullWidth
              placeholder="Optional"
              onChange={onChange("registeredAddress.postalCode")}
              value={regAddress.postalCode}
              helperText={FormFieldValid.registeredAddressPostalCode}
            />
          </div>
        </>
      )}
    </Root>
  );
};
