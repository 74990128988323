import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { FeesAndAccountStatsCard } from "views/Dashboard/FeesAndAccountStatsCard";
import { useApplicationContext } from "context/Application/Application";

const PREFIX = "MarketManagementDashboard";

const classes = {
  root: `${PREFIX}-root`,
  cardContentLoading: `${PREFIX}-cardContentLoading`,
  cardContent: `${PREFIX}-cardContent`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(4),
    display: "flex",
    gap: theme.spacing(4),
    flexDirection: "column",
  },

  [`& .${classes.cardContentLoading}`]: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.cardContent}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr 1fr",
    gridTemplateRows: "repeat(3, auto)",
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(2),
  },
}));

export function MarketManagementDashboard() {
  const { loginClaims, viewConfiguration } = useApplicationContext();

  const viewPermissionPresent =
    viewConfiguration?.MarketManagement?.FeesAndAccountStats;

  return (
    <Root className={classes.root}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography color="secondary" variant="h2">
            Welcome {loginClaims.firstName},
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            Market management is a workspace to manage all Mesh&apos;s market
            related tasks.
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        {viewPermissionPresent && (
          <Grid item xs={4}>
            <FeesAndAccountStatsCard />
          </Grid>
        )}
      </Grid>
    </Root>
  );
}
