import config from "react-global-configuration";
import { Context } from "../security";
import { jsonRPCRequest } from "../../utilities/network/jsonRPCRequest";

export interface GenerateAccessTokenRequest {
  context: Context;
}

export interface RetrieveApplicantRequest {
  context: Context;
  clientID: string;
}

export interface RetrieveApplicantVerificationStepsStatusRequest {
  context: Context;
  clientID: string;
}

export interface RetrieveApplicantDocumentRequest {
  context: Context;
  clientID: string;
  imageID: number;
}

export type Status = "GREEN" | "RED";

export type DocType =
  | "ID_CARD"
  | "PASSPORT"
  | "DRIVERS"
  | "RESIDENCE_PERMIT"
  | "UTILITY_BILL"
  | "SELFIE"
  | "VIDEO_SELFIE"
  | "PROFILE_IMAGE"
  | "ID_DOC_PHOTO"
  | "AGREEMENT"
  | "CONTRACT"
  | "DRIVERS_TRANSLATION"
  | "INVESTOR_DOC"
  | "VEHICLE_REGISTRATION_CERTIFICATE"
  | "INCOME_SOURCE"
  | "PAYMENT_METHOD"
  | "BANK_CARD"
  | "COVID_VACCINATION_FORM"
  | "OTHER";

export interface ReviewResult {
  reviewAnswer: Status;
  moderationComment: string;
  clientComment: string;
  reviewRejectType: string;
  rejectLabels: Array<string>;
}

export interface ImageReviewResult {
  moderationComment: string;
  clientComment: string;
  reviewAnswer: Status;
  rejectLabels: Array<string>;
  reviewRejectType: string;
  buttonIds: Array<string>;
}

export interface RetrieveApplicantVerificationStepsStatusResponse {
  reviewResult: ReviewResult;
  idDocType: DocType;
  country: string;
  imageIds: Array<number>;
  imageReviewResults: Record<number, ImageReviewResult>;
  forbidden: boolean;
  partialCompletion: boolean;
}

export const docTypeFullName = (type: DocType) => {
  switch (type) {
    case "ID_CARD":
      return "Identity Document";
    case "SELFIE":
      return "Liveness Check";
    default:
      return "Proof of Residence";
  }
};

export interface RetrieveApplicantResponse {
  id: string;
  createdAt: string;
  key: string;
  clientID: string;
  inspectionID: string;
  externalUserID: string;
  lang: string;
  type: string;
}

export interface RetrieveApplicantDocumentResponse {
  File: string;
  MimeType: string;
}

export interface GenerateAccessTokenResponse {
  token: string;
}

export const ApplicantInspector = {
  serviceProvider: "sumsub-ApplicantInspector",
  async GenerateAccessToken(
    request: GenerateAccessTokenRequest,
  ): Promise<GenerateAccessTokenResponse> {
    config.get("meshAuthorizedURL");
    return await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${ApplicantInspector.serviceProvider}.GenerateToken`,
      request,
    });
  },
  async RetrieveApplicant(
    request: RetrieveApplicantRequest,
  ): Promise<RetrieveApplicantResponse> {
    return await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${ApplicantInspector.serviceProvider}.RetrieveApplicant`,
      request,
    });
  },
  async RetrieveApplicantVerificationStepsStatus(
    request: RetrieveApplicantVerificationStepsStatusRequest,
  ): Promise<Array<RetrieveApplicantVerificationStepsStatusResponse>> {
    return await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${ApplicantInspector.serviceProvider}.RetrieveApplicantVerificationStepsStatus`,
      request,
    });
  },
  async RetrieveApplicantDocument(
    request: RetrieveApplicantDocumentRequest,
  ): Promise<RetrieveApplicantDocumentResponse> {
    return await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${ApplicantInspector.serviceProvider}.RetrieveApplicantDocument`,
      request,
    });
  },
};
