import React from "react";

import { styled } from "@mui/material/styles";

import { TextField, Typography } from "@mui/material";
import { Address } from "james/location";
import { countries } from "james/country";
import { CountryDropdown } from "components/FormFields/CountryDropdown";
import { FormState } from "views/KYB/useFormState";

const PREFIX = "BusinessAddress";

const classes = {
  titleLayout: `${PREFIX}-titleLayout`,
  equalColumns2Gap: `${PREFIX}-equalColumns2Gap`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.titleLayout}`]: {
    height: 35,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },

  [`& .${classes.equalColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(2),
  },
}));

interface BusinessAddressProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeValue: (name: string) => (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAutoComplete: (name: string, value: any) => void;
  businessAddressSame: boolean;
  setBusinessAddressSame: (value: boolean) => void;
  FormFieldValid: { [key: string]: string | undefined };
  setFormFieldValid: (value: { [key: string]: string | undefined }) => void;
  formState: FormState;
}

export const BusinessAddress: React.FC<BusinessAddressProps> = (
  props: BusinessAddressProps,
) => {
  const {
    formState,
    onChangeValue,
    businessAddressSame,
    FormFieldValid,
    onAutoComplete,
  } = props;

  const autoCompleteOnUpdate =
    () =>
    (
      __: React.SyntheticEvent<Element, Event>,
      countryOption: { value: string; label: string } | null,
    ) => {
      updateState(countryOption ? countryOption.value : "");
    };

  const updateState = (value: string) => {
    onAutoComplete("businessAddress.countryCode", value);
  };

  return (
    <Root>
      <div className={classes.titleLayout}>
        <Typography variant="h6" children="Operating Address" />
      </div>
      {formState.company.businessAddress && (
        <>
          <CountryDropdown
            id="contactDetails-businessAddress-country"
            isOptionEqualToValue={(option, value) => {
              if (value.value === "") {
                return true;
              }
              return option === value;
            }}
            disabled={businessAddressSame}
            onChange={autoCompleteOnUpdate()}
            value={
              formState.company.businessAddress.countryCode === ""
                ? {
                    value: "",
                    label: "",
                  }
                : {
                    value: formState.company.businessAddress.countryCode,
                    label: countries.filter(
                      (c) =>
                        c.value ===
                        new Address(formState.company.businessAddress)
                          .countryCode,
                    )[0].label,
                  }
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Country"
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Select...",
                }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                error={Boolean(FormFieldValid.businessAddressCountryCode)}
                fullWidth
                helperText={FormFieldValid.businessAddressCountryCode}
              />
            )}
          />
          <TextField
            id="contactDetails-businessAddress-line1"
            margin="dense"
            disabled={businessAddressSame}
            variant="outlined"
            label="Address 1"
            fullWidth
            onChange={onChangeValue("businessAddress.addressLine1")}
            value={formState.company.businessAddress.addressLine1}
            error={Boolean(FormFieldValid.businessAddressAddressLine1)}
            helperText={FormFieldValid.businessAddressAddressLine1}
          />
          <TextField
            id="contactDetails-businessAddress-line2"
            margin="dense"
            disabled={businessAddressSame}
            variant="outlined"
            label="Address 2 (Optional)"
            fullWidth
            onChange={onChangeValue("businessAddress.addressLine2")}
            value={formState.company.businessAddress.addressLine2}
          />
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="contactDetails-businessAddress-city"
              margin="dense"
              disabled={businessAddressSame}
              variant="outlined"
              label="City"
              fullWidth
              onChange={onChangeValue("businessAddress.city")}
              value={formState.company.businessAddress.city}
              error={Boolean(FormFieldValid.businessAddressCity)}
              helperText={FormFieldValid.businessAddressCity}
            />
            <TextField
              id="contactDetails-businessAddress-suburb"
              margin="dense"
              variant="outlined"
              label="Suburb"
              disabled={businessAddressSame}
              fullWidth
              onChange={onChangeValue("businessAddress.suburb")}
              value={formState.company.businessAddress.suburb}
              error={Boolean(FormFieldValid.businessAddressSuburb)}
              helperText={FormFieldValid.businessAddressSuburb}
            />
          </div>
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="contactDetails-businessAddress-province"
              margin="dense"
              disabled={businessAddressSame}
              variant="outlined"
              label="Province"
              fullWidth
              onChange={onChangeValue("businessAddress.province")}
              value={formState.company.businessAddress.province}
              error={Boolean(FormFieldValid.businessAddressProvince)}
              helperText={FormFieldValid.businessAddressProvince}
            />
            <TextField
              id="contactDetails-businessAddress-postalCode"
              margin="dense"
              disabled={businessAddressSame}
              variant="outlined"
              label="Postal Code"
              fullWidth
              onChange={onChangeValue("businessAddress.postalCode")}
              value={formState.company.businessAddress.postalCode}
              error={Boolean(FormFieldValid.businessAddressPostalCode)}
              helperText={FormFieldValid.businessAddressPostalCode}
            />
          </div>
        </>
      )}
    </Root>
  );
};
